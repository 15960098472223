@use "./src/shared/assets/scss/variables/index.scss" as *;
.password-change {
  display: flex;
  flex-direction: column;
  grid-gap: $indent-sm;
  margin-bottom: 64px;
  max-width: 328px;

  &__title {
    margin-bottom: 20px;
  }

  &__input {
    min-height: 48px;
  }

  &__btn {
    width: 100%;
  }

  @media screen and (max-width: 1650px) {
    max-width: 320px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    align-self: center;
  }
}
