@use "./src/shared/assets/scss/variables/index.scss" as *;
.supplementary-agreements-table-container {
  &__pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 6px;
    min-height: 62px;
    justify-content: space-between;
  }

  @media screen and (max-width: 768px) {
    &__count-records {
      display: none;
    }
  }
}
