@use "./src/shared/assets/scss/variables/index.scss" as *;
.profile-settings {
  flex-basis: 100%;
  background-color: var(--common2);
  display: flex;
  flex-direction: row;
  padding: 0 40px;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  font-size: $md-text;
  font-weight: 500;
  overflow-y: hidden;

  &__left {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 320px;
    overflow-y: auto;
    padding: $indent-xxxl 46px $indent-xxxl 0;
    border-right: 1px solid var(--grey20);

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }

  @media screen and (max-width: 1650px) {
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    grid-gap: 36px;

    &__left {
      overflow-y: visible;
      border-right: none;

      &::after {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-basis: 100%;
    padding: 30px $indent-md;

    &__left {
      min-width: auto;
      padding: $indent-xxxl 0;
    }
  }
}
