@use "./src/shared/assets/scss/variables/index.scss" as *;
.current-ticket {
  display: flex;
  flex-direction: column;
  min-width: 450px;
  background-color: var(--common2);
  border-radius: $border-radius;

  &::-webkit-scrollbar {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__header {
    padding-top: $indent-xxs;
    padding-bottom: $indent-xxs;
    border-radius: 0;
    border-bottom: 1px solid var(--grey20);
  }

  &__body {
    border-bottom: 1px solid var(--grey20);
    border-radius: 0;
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__ticket-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-item {
      margin-bottom: $indent-xs;

      &:first-child {
        margin-right: 48px;
      }
    }

    &_vl {
      height: 25px;
      margin: 0 $indent-xxxl;
      border: 1px solid var(--grey30);
    }

    &_new {
      background-color: var(--orange110);
    }

    &_in-process {
      background-color: var(--blue120);
    }

    &_closed {
      background-color: var(--green100);
      color: var(--common2);
    }

    &_waiting-to-close {
      background-color: var(--green50);
    }

    &_waiting-info {
      background-color: var(--cyan110);
    }

    &_reopen {
      background-color: var(--orange90);
    }
  }

  &__footer {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    padding-bottom: 60px;
  }

  &__subtitle {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: $indent-lg 0;
  }

  &__description {
    word-wrap: break-word;
    word-break: break-all;
    font-weight: normal;
    font-size: $sm-text;
    line-height: 23px;
    margin-bottom: $indent-xxxl;
    color: var(--grey120);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &__solution {
    word-wrap: break-word;
    word-break: break-all;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  &_empty {
    display: flex;
    min-width: 450px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-basis: 26%;

    &__message {
      text-align: center;
      margin-top: 34px;
      color: var(--grey120);
    }
  }

  &__empty-icon {
    & path {
      stroke: var(--grey20);
    }
  }

  &__icon {
    color: var(--common2);
  }

  @media screen and (max-width: 1500px) {
    &__ticket-info {
      display: block;

      &_item:first-child {
        margin-bottom: 15px;
      }

      &_vl {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    min-width: 320px;

    &_empty {
      min-width: 320px;
    }
  }

  @media screen and (max-width: 1200px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: baseline;

    &__header {
      flex-basis: 100%;
      height: fit-content;
    }

    &__body {
      flex-basis: 54%;
      display: flex;
      border-bottom: none;
      border-bottom-left-radius: $border-radius;
      padding-right: 0;
    }

    &__footer {
      flex-basis: 46%;
      padding-bottom: $indent-xl;
      border-bottom-left-radius: 0;
    }

    &_empty {
      flex-basis: 35%;
      max-height: 272px;
    }

    &__info-wrapper {
      flex-basis: 50%;
      padding-right: $indent-md;
    }

    &__ticket-info {
      flex-basis: 50%;

      &-item {
        margin-bottom: $indent-md;

        &-text {
          white-space: normal;
        }

        &:first-child {
          margin-right: 0;
        }
      }
    }

    &__subtitle {
      font-size: $sm-text;
      margin-top: $indent-md;
      margin-bottom: $indent-sm;
    }

    &__description {
      line-height: 16px;
      font-size: $xs-text;
      margin-bottom: 0;
      -webkit-line-clamp: 2;
    }
  }

  @media screen and (max-width: 768px) {
    &_empty {
      display: none;
    }
  }
}

[data-theme|='dark'] {
  .current-ticket__icon {
    color: var(--grey200);
  }
}
