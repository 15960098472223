@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integration {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: $indent-lg;

  &__title {
    flex-basis: 50%;
    color: var(--grey120);

    &:nth-child(2) {
      margin-left: 40px;
    }

    &-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 $indent-lg;
    }
  }

  &__element {
    &_mb {
      margin-bottom: $indent-lg;
    }
  }

  &__map {
    &-wrapper {
      height: 224px;
      overflow-y: auto;

      &_empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-block {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-title {
      color: var(--grey200);
      margin-top: $indent-md;
      margin-bottom: $indent-md;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: $indent-md;
      }
    }

    &-select-wrapper {
      display: flex;
      align-items: center;
    }

    &-icon {
      margin-right: $indent-lg;
      fill: var(--grey100);
      stroke: var(--grey100);
    }

    &-select {
      width: 239px;
    }

    &-priority {
      font-size: $sm-text;
    }
  }

  &__select {
    &-type {
      width: 215px;
    }
  }

  &__select-connection-block {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-title {
      color: var(--grey200);
    }
  }

  @media screen and (max-width: 576px) {
    &__map {
      &-select {
        width: 20%;
        min-width: 149px;
      }

      &-status {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 230px;
        margin-right: $indent-lg;
      }

      &-priority {
        min-width: auto;
        margin-right: $indent-lg;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media screen and (max-width: 360px) {
    &__map {
      &-select {
        min-width: 110px;
      }

      &-status {
        max-width: 149px;
      }

      &-priority {
        max-width: 149px;
      }
    }
  }
}
