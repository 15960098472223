@use "./src/shared/assets/scss/variables/index.scss" as *;
.storage-table-container {
  height: 100%;

  &__button {
    margin-top: $indent-md;
  }

  &__icon {
    color: var(--grey80);
  }
}
