@use "./src/shared/assets/scss/variables/index.scss" as *;
.user-assistance-article {
  padding: $indent-sm;
  display: flex;
  background-color: var(--common2);
  border-radius: $border-radius;
  transition: opacity $transition;
  justify-content: space-between;

  &_no-add-mode {
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  &__icon {
    color: var(--common2);
    width: 16px;
    height: 16px;

    & path {
      stroke-width: 1.6;
    }

    &-wrapper {
      min-width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius-sm;
      background-color: var(--blue120);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    &-wrapper {
      grid-gap: $indent-sm;
      display: flex;
      max-width: 80%;
    }
  }

  &__title {
    margin-bottom: $indent-xxs;
    color: var(--grey200);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__text {
    color: var(--grey120);
    font-size: $sm-text;
    display: -webkit-box;
    line-height: 23px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__popover {
    & svg {
      color: var(--grey80);
    }

    &-button {
      & svg {
        color: var(--red80);
      }
    }
  }

  &__checkbox {
    margin-top: $indent-xxs;
  }
}

[data-theme|='dark'] {
  .user-assistance-article {
    &__icon {
      color: var(--grey200);
    }
  }
}
