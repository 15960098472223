@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integrations-fields-integrations {
  &__select {
    width: 42%;
  }

  &__text {
    color: var(--grey200);
  }

  @media screen and (max-width: 1350px) {
    &__select {
      min-width: 110px;
    }
  }

  @media screen and (max-width: 360px) {
    &__select {
      min-width: 90px;
    }
  }
}
