@use "./src/shared/assets/scss/variables/index.scss" as *;
.systems-with-sla {
  display: flex;
  width: 100%;
  height: 410px;
  flex-direction: row;
  grid-gap: 2px;
  transition: height $transition ease-in-out;

  &_close {
    height: 58px;
    overflow: hidden;
    transition: height $transition ease-in-out;
  }

  &__systems {
    display: flex;
    flex-direction: column;
    opacity: 1;
    min-width: 414px;

    &-content {
      height: 100%;
      display: flex;
      border-bottom-left-radius: $border-radius;
      background-color: var(--common2);
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
      padding: 0 18px;
    }

    &-pagination {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 6px;
      min-height: 62px;
    }
  }

  &__header {
    border-radius: $border-radius 0 0 0;

    &_close {
      border-bottom-left-radius: $border-radius;
    }
  }

  &__sla {
    overflow: hidden;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
    flex-grow: 1;
    padding: 0;
    height: 100%;

    &-table {
      display: flex;
      height: calc(100% - 58px);
    }

    &-header {
      &_close {
        box-shadow: none;
        transition: box-shadow $transition ease-in-out;
      }
    }
  }

  @media screen and (max-width: 1500px) {
    flex-direction: column;
    height: 740px;
    grid-gap: 0;
    transition: height $transition ease-in-out;

    &_close {
      height: 58px;
      overflow: hidden;
      transition: height $transition ease-in-out;
    }

    &__header {
      border-radius: $border-radius $border-radius 0 0;

      &_close {
        border-radius: $border-radius;
      }
    }

    &__systems {
      max-height: 50%;

      &-content {
        border-radius: 0;
      }

      &_close {
        max-height: none;
      }
    }

    &__sla {
      border-radius: 0 0 $border-radius $border-radius;

      &-header {
        &_close {
          max-height: 0;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__systems {
      min-width: auto;
    }

    &__header {
      border-radius: 0;

      &_close {
        border-radius: 0;
      }
    }

    &__count-records {
      display: none;
    }
  }
}
