@use "./src/shared/assets/scss/variables/index.scss" as *;
.button {
  transition: background-color $transition, border $transition, border-color $transition;
  position: relative;
  border-radius: $border-radius-sm;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;

  &_xs {
    font-size: $sm-text;
    line-height: 18px;
    height: 32px;
    padding: 0 $indent-xs;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &_s {
    font-size: $sm-text;
    line-height: 18px;
    height: 40px;
    padding: 0 $indent-sm;

    & svg {
      width: 24px;
      height: 24px;
    }
  }

  &_m {
    font-size: $md-text;
    line-height: 20px;
    height: 48px;
    padding: 0 $indent-md;

    & svg {
      width: 24px;
      height: 24px;
    }
  }

  &_l {
    font-size: $lg-text;
    line-height: 24px;
    height: 56px;
    padding: 0 $indent-lg;

    & svg {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &_default {
    background-color: var(--blue120);
    color: var(--common2);

    &:hover {
      background-color: var(--blue100);
    }

    &:disabled {
      background-color: var(--grey50);
      cursor: not-allowed;
    }
  }

  &_outline {
    color: var(--blue120);
    background-color: transparent;
    border: 1px solid var(--grey30);

    &:hover {
      border-color: var(--blue120);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
      border-color: var(--grey40);
      color: var(--grey80);
    }
  }

  &_flat {
    border: none;
    color: var(--blue120);
    background-color: transparent;

    &:hover {
      background-color: var(--blue10);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--grey80);
      background-color: transparent;
    }
  }

  & svg {
    vertical-align: middle;
    margin-right: $indent-xs;
  }

  &:focus {
    outline: 2px solid var(--blue180);
  }

  &_with-extra-action {
    border-radius: $border-radius-sm 0 0 $border-radius-sm;
  }
}

.wrapper {
  display: flex;
  grid-gap: 1px;

  &__icon-button {
    width: 44px;
    height: 40px;
    border-radius: 0 $border-radius-sm $border-radius-sm 0;
  }
}

[data-theme|='dark'] {
  .button {
    &_default:not(:disabled) {
      color: var(--grey200);

      & svg {
        color: var(--grey200);
      }
    }

    &_flat:not(:disabled) {
      color: #3392ff;
    }
  }
}
