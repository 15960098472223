@use "./src/shared/assets/scss/variables/index.scss" as *;
.ripple {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;

  &__item {
    position: absolute;
    transform: scale(0);
    border-radius: 100%;
    opacity: 0.25;
    animation-name: ripple;
    animation-duration: 800ms;

    &_default {
      background-color: var(--common2);
    }

    &_outline {
      background-color: var(--blue120);
    }

    &_grey {
      background-color: var(--grey120);
    }

    &_flat {
      background-color: var(--blue120);
    }
  }

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
}
