@use "./src/shared/assets/scss/variables/index.scss" as *;
.radio-item {
  user-select: none;
  height: 24px;
  display: flex;
  align-items: center;
  grid-gap: $indent-sm;

  $this: &;

  &:hover {
    cursor: pointer;
  }

  &_disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  &__input {
    display: none;
    padding: 2px;

    &:checked {
      & ~ #{$this}__icon {
        fill: var(--blue120);
        border-color: var(--blue120);
        padding: 2px;
        background-color: transparent;
      }

      &:disabled + #{$this}__icon {
        fill: var(--grey50);
      }

      &:not(:disabled):hover {
        & ~ #{$this}__icon {
          border-color: var(--blue100);
          fill: var(--blue100);
        }
      }
    }

    &:not(:disabled):hover {
      & ~ #{$this}__icon {
        border-color: var(--grey60);
      }
    }

    &:disabled {
      & ~ #{$this} {
        &__icon {
          border-color: var(--grey50);
        }

        &__title {
          color: var(--grey80);
        }
      }
    }
  }

  &__title {
    color: var(--grey200);
    white-space: nowrap;
  }

  &__icon {
    width: 16px;
    height: 16px;
    border-width: 2px;
    border-style: solid;
    background-color: var(--common2);
    color: var(--common2);
    border-radius: 50%;
    border-color: var(--grey80);
    transition: background-color $transition, fill $transition, padding $transition, border-color $transition;
  }
}
