@use "./src/shared/assets/scss/variables/index.scss" as *;
.saved-patterns-sla {
  &__patterns {
    margin: $indent-sm 0;
    padding: 0 $indent-xl;
    border-bottom: 2px solid var(--grey10);
  }

  &__auto-closing {
    padding: 10px $indent-xl;
    border-top: 2px solid var(--grey10);
  }

  &__input {
    padding: 0 $indent-xl;
  }
}
