@use "./src/shared/assets/scss/variables/index.scss" as *;
.desktop {
  display: flex;
  flex-direction: column;
  grid-gap: $indent-sm;

  &__top-container {
    display: flex;
    grid-gap: $indent-sm;
  }

  &__bottom-container {
    display: flex;
    grid-gap: $indent-sm;
  }

  &__tickets {
    &-dashboard {
      flex-basis: 65%;
    }

    &-table {
      flex-basis: 35%;
      height: 382px;
      min-width: 300px;
    }
  }

  &__title {
    margin-right: $indent-sm;
    font-weight: 500;
    font-size: $md-text;
    color: var(--grey200);

    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__pie-chart {
    flex-basis: 50%;
  }

  @media screen and (max-width: 1600px) {
    &__tickets {
      &-dashboard {
        flex-basis: 60%;
      }

      &-table {
        flex-basis: 40%;
      }
    }
  }

  @media screen and (max-width: 1350px) {
    &__top-container {
      flex-direction: column;
    }

    &__bottom-container {
      flex-direction: column;
    }

    &__tickets {
      &-dashboard {
        flex-basis: auto;
      }

      &-table {
        flex-basis: auto;
      }
    }

    &__pie-chart {
      flex-basis: auto;
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    grid-gap: 0;

    &__top-container {
      grid-gap: 0;
    }

    &__bottom-container {
      grid-gap: 0;
    }

    &__title {
      font-size: $sm-text;
    }

    &__tickets {
      &-table {
        height: 360px;
      }
    }
  }
}
