@use "./src/shared/assets/scss/variables/index.scss" as *;
.contract-form {
  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-header {
      padding-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: inset 0 -2px 0 0 var(--grey20);

      &-tab {
        width: fit-content;
        padding-bottom: 15px;
        box-shadow: inset 0 -2px 0 0 var(--blue120);
      }
    }

    &-content {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__input {
    margin-bottom: $indent-md;

    &_one-of-three {
      flex-basis: 32%;
    }
  }

  &__textarea {
    flex-basis: 66%;
  }

  &__radio {
    display: flex;
    flex-direction: column;
    flex-basis: 32%;
    row-gap: $indent-md;
  }

  &__radio-group {
    row-gap: $indent-xs;
  }

  &__icon {
    color: var(--blue120);
    width: 24px;
    height: 24px;
  }

  &__tooltip {
    max-width: 280px;
  }

  &__accordion {
    margin-top: $indent-md;

    &-container {
      margin-top: $indent-md;
      display: flex;
      gap: $indent-md;
      align-items: center;
      min-height: 48px;
    }

    &-switch-label {
      font-size: $md-text;
      font-weight: 500;
    }

    &-input {
      width: 368px;
    }
  }

  @media screen and (max-width: 768px) {
    border-bottom: 2px solid var(--grey20);

    &__form {
      &-header {
        display: none;
      }

      &-content {
        border-radius: 0;
      }
    }

    &__input {
      &_one-of-three {
        flex-basis: 100%;
      }
    }

    &__radio {
      margin-top: $indent-md;
    }

    &__accordion {
      margin-top: $indent-xl;

      &-content {
        flex-direction: column;
        align-items: flex-start;
      }

      &-input {
        width: 100%;
      }
    }
  }
}
