@use "./src/shared/assets/scss/variables/index.scss" as *;
.status-copy-model {
  &__form {
    display: flex;
    flex-direction: column;
    row-gap: $indent-md;
  }

  &__button {
    width: 100px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    padding: $indent-lg;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: none;
    min-width: 0;
    z-index: $z-index-drawer;

    &__buttons {
      display: flex;
      flex-direction: column-reverse;
      grid-gap: $indent-xxs;
      width: 100%;
    }

    &__button {
      width: 100%;
    }

    &__footer {
      flex-direction: column-reverse;
      align-items: flex-start;
      grid-gap: $indent-lg;
    }
  }
}
