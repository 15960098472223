@use "./src/shared/assets/scss/variables/index.scss" as *;
.tables-container {
  display: flex;
  flex-direction: row;
  grid-gap: $indent-sm;
  //min-height: 820px;
  //max-height: 820px;

  &__main-table {
    width: 65%;
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;
  }

  &__wrapper-sub-table {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;
  }

  &__sub-table {
    width: 35%;
    border-radius: $border-radius;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }

  @media screen and (max-width: 1700px) {
    &__main-table {
      width: 60%;
    }

    &__sub-table {
      width: 40%;
    }
  }

  @media screen and (max-width: 1500px) {
    &__main-table {
      width: 55%;
    }

    &__sub-table {
      width: 45%;
    }
  }

  @media screen and (max-width: 1200px) {
    grid-gap: 0;

    &__main-table {
      width: 100%;
    }

    &__sub-table {
      width: 100%;
      margin-top: $indent-sm;
    }
  }

  @media screen and (max-width: 768px) {
    &__sub-table {
      width: 100%;
      margin-top: 0;
      border-radius: 0;
    }

    &__wrapper-sub-table {
      grid-gap: 0;
    }
  }
}
