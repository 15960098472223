@use "./src/shared/assets/scss/variables/index.scss" as *;
.notifications-settings {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  grid-gap: $indent-sm;
  min-width: 400px;
  max-width: 748px;
  overflow-y: auto;
  padding: $indent-xxxl 0 $indent-xxxl 40px;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &__double-switch {
    display: flex;
    flex-direction: row;
    grid-gap: 63px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $indent-md $indent-sm $indent-sm 0;
  }

  &__type {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--grey100);
    grid-gap: $indent-sm;

    &-block {
      display: flex;
      flex-direction: row;
      grid-gap: 63px;
    }

    &-icon {
      width: 15px;
      height: 15px;

      &-wrapper {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--grey10);
        color: var(--grey100);
        border-radius: $border-radius-sm;
      }
    }
  }

  &__accordion {
    &-content {
      width: 100%;
    }

    &-item {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px 17px 5px 73px;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      grid-gap: $indent-sm;
    }
  }

  &__groups-form {
    margin-top: $indent-xl;
  }

  @media screen and (max-width: 1650px) {
    overflow-y: visible;
    padding: 0 0 $indent-xxxl 0;

    &__type {
      &-block {
        grid-gap: 28px;
      }
    }

    &__double-switch {
      grid-gap: 28px;
    }
  }

  @media screen and (max-width: 768px) {
    min-width: auto;

    &__accordion {
      &-item {
        padding: 5px 17px 5px 35px;
      }
    }

    &__type {
      &-block {
        grid-gap: 10px;
      }
    }

    &__double-switch {
      grid-gap: 10px;
    }
  }
}
