@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-create-category-drop {
  &__icon {
    width: 20px;
    height: 20px;
    color: var(--blue120);

    &-red {
      color: var(--red100);
    }
  }
}
