@use "./src/shared/assets/scss/variables/index.scss" as *;
.input-with-list {
  position: relative;
  width: 100%;

  &__no-result {
    color: var(--grey60);

    &:hover {
      cursor: default;
      background-color: transparent;
    }
  }

  &__input {
    flex-basis: 100%;
  }

  &__wrapper {
    width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
}
