@use "./src/shared/assets/scss/variables/index.scss" as *;
.work-groups-table-container {
  &_full {
    height: 541px;
  }

  &_from-organizations {
    height: 442px;
  }

  &_from-users {
    height: 365px;
  }

  &__icon-delete {
    color: var(--red100);
  }

  @media screen and (max-width: 768px) {
    &_full {
      height: 354px;
    }

    &_from-organizations {
      height: 354px;
    }

    &_from-users {
      height: 354px;
    }
  }
}
