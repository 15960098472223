@use "./src/shared/assets/scss/variables/index.scss" as *;
.dropdown {
  position: relative;

  &__items {
    display: grid;
    grid-gap: $indent-md $indent-xs;
    grid-template-columns: repeat(3, 1fr);

    &-wrapper {
      background: var(--common2);
      border-radius: $border-radius-sm;
      box-shadow: 0 4px 15px rgba(33, 39, 44, 0.1);
      display: none;
      left: 50%;
      padding: $indent-lg $indent-xl;
      position: absolute;
      transform: translate(calc(-50% - #{$indent-xs}));
      width: 304px;
      z-index: $z-index-dropdown;

      &_show {
        display: block;
        background-color: var(--common2);
        width: 304px;
      }
    }

    &-controls {
      margin-bottom: $indent-xl;
    }

    &-controlsee {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &-typography {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--grey200);
        margin-right: 52px;
        font-weight: 500;
        pointer-events: none;
      }

      &-arrow {
        &_left {
          display: flex;
          justify-content: flex-end;
          margin-right: 52px;
          color: var(--grey60);
        }

        &_right {
          transform: rotateY(180deg);
          color: var(--grey60);
        }
      }
    }
  }

  &__selected-item {
    font-size: $sm-text;
    align-items: center;
    cursor: pointer;
    display: flex;
    color: var(--grey200);
    font-weight: 500;
    justify-content: center;

    &_month {
      width: 80px;
    }

    &-icon {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid var(--grey200);
      cursor: pointer;
      height: 0;
      width: 0;

      &_rotated {
        transform: rotateX(180deg);
      }

      &-wrapper {
        align-items: center;
        display: flex;
        height: $indent-lg;
        background-color: var(--common2);
        justify-content: center;
        width: $indent-lg;
      }
    }
  }

  &__button {
    cursor: pointer;
    line-height: 0;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &__item {
    $item: &;

    align-items: center;
    border-radius: 16px;
    display: flex;
    color: var(--grey60);
    font-weight: 500;
    height: $indent-xxxl;
    justify-content: center;
    min-width: 64px;

    &-typography {
      color: var(--grey200);
    }

    &_selected {
      background: var(--blue120);

      #{$item}-typography {
        color: var(--common2);
      }
    }

    &:not(#{$item}_selected):hover {
      background: var(--grey10);
    }
  }

  @media screen and (max-width: 768px) {
    &__items {
      display: block;
      grid-gap: $indent-md $indent-xs;
      grid-template-columns: repeat(3, 1fr);
      overflow-y: scroll;
      height: -webkit-fill-available;

      &::-webkit-scrollbar {
        display: none;
      }

      &-wrapper {
        padding: $indent-lg 80px;
        height: 258px;

        &_show {
          transform: none;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          animation-name: slide-in;
          animation-duration: 0.5s;
        }
      }
    }

    &__item {
      width: 100%;
    }

    @keyframes slide-in {
      from {
        transform: translateY(170px);
      }

      to {
        transform: translateY(0);
      }
    }
  }
}
