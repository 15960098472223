@use "./src/shared/assets/scss/variables/index.scss" as *;
.tickets-drop-content {
  &__body {
    width: 100%;

    &-select {
      margin-bottom: $indent-sm;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: var(--blue120);
  }

  &__line {
    margin: $indent-xs 0;
  }

  @media screen and (max-width: 768px) {
    &__export {
      &-title {
        font-size: $sm-text;
        line-height: 18px;
      }
    }

    &__delete {
      &-title {
        font-size: $sm-text;
        line-height: 18px;
      }
    }
  }
}
