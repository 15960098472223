@use "./src/shared/assets/scss/variables/index.scss" as *;
.status-drop-content {
  padding: $indent-xs 0;
  border-radius: $border-radius;
  background-color: var(--common2);
  box-shadow: var(--box-shadow-lvl-2);

  &__item-wrapper {
    overflow: hidden;
    position: relative;
    padding: $indent-md $indent-lg;
    transition: background-color $transition;

    &:hover {
      background-color: var(--grey10);
      cursor: pointer;
    }
  }
}
