@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-adaptive-info {
  background-color: var(--common2);
  padding: $indent-xxs $indent-xxs 15px $indent-xxs;

  &__content {
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;

    &_hidden {
      padding-bottom: 5px;
    }
  }

  &__loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-wrapper {
      height: 400px;
    }
  }
}
