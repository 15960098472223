@use "./src/shared/assets/scss/variables/index.scss" as *;
.auth-loading {
  width: 454px;
  height: 200px;
  background-color: var(--common2);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: $border-radius;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $indent-xxl;
  }

  &__title {
    color: var(--grey120);
    margin-left: 18px;
  }

  &__icon {
    color: var(--cyan100);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0;

    &__header {
      margin-bottom: $indent-xxxl;
    }
  }
}
