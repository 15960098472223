@use "./src/shared/assets/scss/variables/index.scss" as *;
.count-records {
  &__text {
    color: var(--grey100);
    margin-right: $indent-xs;
    white-space: nowrap;
    display: inline-block;
  }

  &__number {
    display: inline-block;
  }
}
