@use "./src/shared/assets/scss/variables/index.scss" as *;
.icon-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    box-shadow: none;

    & svg {
      filter: drop-shadow($focused-svg-shadow var(--blue120));
    }
  }
}
