@use "./src/shared/assets/scss/variables/index.scss" as *;
.storages-header {
  height: 60px;
  padding: $indent-sm 40px $indent-sm $indent-lg;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  background-color: var(--common2);

  &__icon {
    color: var(--grey80);
  }

  &__select {
    width: 282px;
  }

  &__input {
    width: 575px;
  }

  &__left-block {
    display: flex;
    grid-gap: $indent-sm;
  }

  &__right-block {
    display: flex;
    grid-gap: $indent-xxs;
  }

  @media screen and (max-width: 1200px) {
    padding: $indent-sm $indent-lg;

    &__left-block {
      width: 100%;
    }

    &__select {
      width: 60%;
    }

    &__input {
      width: 40%;
    }
  }

  @media screen and (max-width: 768px) {
    &__icon {
      &-search {
        display: none;
      }
    }
  }
}
