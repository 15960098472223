@use "./src/shared/assets/scss/variables/index.scss" as *;
.popover-container {
  background: var(--common2);
  border-radius: $border-radius;
  min-width: 247px;
  width: min-content;
  height: max-content;
  padding: $indent-md 0;
  display: flex;
  flex-direction: column;
  grid-gap: $indent-xs;
  box-shadow: var(--box-shadow-lvl-2);

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
