@use "./src/shared/assets/scss/variables/index.scss" as *;
.trigger-modal-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  grid-gap: $indent-sm;
  padding: $indent-lg $indent-xl;
  justify-content: space-between;
  overflow-y: auto;

  &__title-input {
    margin-bottom: $indent-xxxl;
  }

  &__sub-title {
    margin-bottom: $indent-lg;

    &-become {
      text-align: center;
    }
  }

  &__row {
    display: flex;
    grid-gap: $indent-sm;
    justify-content: space-between;
    margin-bottom: $indent-lg;
  }

  &__custom {
    &-field-rows {
      display: flex;
      flex-direction: column;
    }

    &-field-row {
      grid-gap: $indent-sm;
      margin-bottom: $indent-xxxl;
      width: 100%;
      display: flex;
    }

    &-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      grid-gap: $indent-sm;
    }

    &-btn {
      margin-bottom: $indent-lg;
    }

    &-conditions {
      min-width: 280px;
    }
  }

  &__container {
    display: flex;
    grid-gap: $indent-sm;
  }

  &__attribute {
    max-width: 148px;
  }

  &__trigger-condition {
    min-width: 350px;
  }

  &__close-button {
    line-height: 0;

    &-icon {
      width: 24px;
      height: 24px;
      color: var(--grey50);
    }
  }

  &__separator {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey10);
    position: relative;
    margin: $indent-lg auto;

    &::after,
    &::before {
      content: " ";
      background-color: var(--grey20);
      width: 155px;
      height: 1px;
      vertical-align: middle;
      position: absolute;
    }

    &::after {
      left: 42px;
    }

    &::before {
      right: 42px;
    }
  }

  &__radio {
    margin-bottom: $indent-lg;
  }

  &__footer {
    display: flex;
    grid-gap: $indent-xs;
    margin-top: $indent-lg;
  }

  &__alert {
    min-height: auto;
    min-width: auto;

    &-text {
      font-weight: 500;
      font-size: $sm-text;
      line-height: 18px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: $indent-xl $indent-lg;

    &__separator {
      &::after,
      &::before {
        width: 35vw;
      }
    }

    &__footer {
      flex-direction: column-reverse;
      grid-gap: $indent-xxs;
    }

    &__button {
      width: 100%;
    }
  }
}
