@use "./src/shared/assets/scss/variables/index.scss" as *;
.access-edit {
  &__accordion {
    &-content {
      display: flex;
      flex-direction: column;
    }

    &-wrapper {
      margin: 10px 0;
    }

    &-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5px 18px 5px 73px;
    }
  }
}
