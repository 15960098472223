@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-organization-select {
  max-width: 315px;

  &__option {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    height: 56px;
    max-height: 56px;
    grid-gap: 2px;

    &-title {
      color: var(--grey200);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-type {
      color: var(--grey100);

      &_purple {
        color: var(--purple100);
      }

      &_cyan {
        color: var(--cyan100);
      }

      &_orange {
        color: var(--orange100);
      }
    }
  }
}
