@use "./src/shared/assets/scss/variables/index.scss" as *;
.radio {
  height: fit-content;
  grid-gap: $indent-md;
  display: flex;
  user-select: none;

  &_vertical {
    flex-direction: column;
  }

  &_horizontal {
    flex-direction: row;
    align-items: center;
  }
}
