@use "./src/shared/assets/scss/variables/index.scss" as *;
.sidebar-layout {
  display: flex;
  background-color: var(--background1);
  min-height: 100vh;
  margin: 0 auto;
  transition: background-color $transition;

  &__wrapper {
    flex-direction: column;
    flex-grow: 1;
    min-width: 200px;
    display: flex;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
}
