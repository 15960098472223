@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-table {
  display: flex;
  width: 100%;
  flex-direction: row;
  grid-gap: $indent-sm;
  flex-basis: 100%;
  overflow: auto;

  &__search {
    flex-basis: 30%;
    min-width: 400px;
    height: 100%;
    overflow: hidden;
  }

  &__table {
    flex-basis: 70%;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    overflow: hidden;

    &__table {
      flex-basis: auto;
    }

    &__search {
      flex-basis: auto;
      height: fit-content;
    }
  }

  @media screen and (max-width: 768px) {
    &__search {
      min-width: auto;
    }
  }
}
