@use "./src/shared/assets/scss/variables/index.scss" as *;
.time-picker {
  width: 100%;
  border-radius: $border-radius-sm;
  border: 1px solid var(--grey30);
  cursor: pointer;
  padding: 10px $indent-sm 0 0;
  display: grid;
  grid-template-columns: 1fr $indent-lg;
  user-select: none;

  &:hover {
    border-color: var(--grey100);
  }

  &:focus {
    border-color: var(--blue120);
  }

  &__dropdown-wrapper {
    position: relative;
    padding-left: $indent-sm;
    padding-bottom: 10px;
    text-align: left;
  }

  &__divider {
    background: var(--grey20);
    width: 1px;
  }

  &__time {
    display: flex;
    column-gap: $indent-xxs;
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: var(--grey100);
  }

  &__dropdown {
    position: absolute;
    height: 216px;
    overflow: hidden;
    background-color: var(--common2);
    z-index: $z-index-dropdown;
    left: 0;
    top: calc(100% + 1px);
    box-shadow: var(--box-shadow-lvl-2);
    border-radius: $border-radius-sm;
    display: flex;
    grid-template-columns: repeat(2, 1fr);

    &__content {
      display: flex;
    }
  }

  &__column {
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: var(--common2);
    }

    &::-webkit-scrollbar-thumb {
      border: $border-radius-sm var(--grey30);
      height: 88px;
    }
  }

  @media screen and (max-width: 768px) {
    &__dropdown {
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      bottom: 0;
      height: 370px;
      left: 0;
      top: auto;
      z-index: $z-index-dropdown;
      right: 0;
      animation-name: slide-in;
      animation-duration: 0.5s;

      &__content {
        height: auto;
        overflow-y: auto;
      }
    }

    &__divider {
      display: none;
    }

    &__column {
      width: 50%;
    }
  }
}

@keyframes slide-in {
  from {
    transform: translateY(170px);
  }

  to {
    transform: translateY(0);
  }
}
