@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-custom-fields-accordion {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  grid-gap: $indent-md;

  &__accordion {
    margin: $indent-md 0;
  }
}
