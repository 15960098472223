@use "./src/shared/assets/scss/variables/index.scss" as *;
.sla {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_small {
    align-items: flex-start;
  }

  &__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--blue120);

    &_small {
      flex-direction: row;
      align-items: flex-start;
    }

    &-info {
      background-color: var(--blue20);
      margin-bottom: $indent-lg;

      &_expired {
        background-color: var(--red20);
      }

      &_small {
        margin-bottom: 0;
      }

      &-wrapper {
        margin-left: 0;
        align-items: center;

        &_small {
          display: none;
        }
      }
    }

    &-title {
      color: var(--grey200);
      font-size: $md-text;
      margin-bottom: $indent-lg;
      font-weight: 500;
    }
  }

  &__clock {
    width: 18px;
    height: 18px;

    &-format {
      margin-left: $indent-xxs;
    }

    &_expired {
      color: var(--red100);
    }
  }

  &__progress-bar {
    width: 75%;
    margin: $indent-lg 60px;

    &_small {
      width: 100%;
      margin: $indent-sm 0 0 0;
    }
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $indent-xs;

    &_small {
      margin-right: $indent-xxs;
    }

    &-wrapper {
      margin: 0 auto $indent-xxl auto;
      display: flex;

      &_small {
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-items: start;
        grid-row-gap: $indent-xxs;
        margin: 0;
      }

      &_tickets-page {
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-items: start;
        grid-row-gap: $indent-xxs;
        margin: 0;
      }
    }

    &_decision {
      background-color: var(--blue20);
      color: var(--blue120);

      & svg {
        color: inherit;
        width: 11px;
      }

      & span {
        color: var(--blue120);
      }
    }

    &_reaction {
      background-color: var(--orange20);
      color: var(--orange120);

      & svg {
        color: inherit;
        width: 11px;
      }

      & span {
        color: var(--orange120);
      }
    }
  }

  &__round-clock {
    height: 20px;
    padding: 0 $indent-xs;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
      font-size: $sm-text;
      font-weight: 500;
      margin-left: 3px;
    }
  }

  &__wrapper {
    display: flex;
  }

  &__sub-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: $indent-sm;

    &-header {
      display: flex;

      &-title {
        color: var(--grey120);
        margin-right: $indent-xs;
        margin-top: 4px;
      }
    }
  }

  &__expired-text {
    margin: $indent-lg 60px;
    color: var(--red70);

    &_small {
      margin: $indent-xxs 0;
    }
  }

  &__expire-time {
    height: 20px;
    padding: 0 $indent-xs;
    margin-left: $indent-xs;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--red30);
    color: var(--red130);

    &_small {
      margin-left: 0;
    }

    &_tickets-page {
      margin-left: $indent-xxs;
    }
  }

  @media screen and (max-width: 768px) {
    &__sub-wrapper {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;

      &-header {
        margin-right: $indent-xs;
        align-items: center;

        &-title {
          font-weight: 500;
          font-size: $xs-text;
        }
      }
    }

    &__tag {
      &-wrapper {
        &_small {
          display: flex;
        }
      }
    }

    &__expire-time {
      &_small {
        margin-left: $indent-xxs;
      }
    }

    &__icon {
      &_small {
        margin-top: 0;
      }
    }

    &__icon-info_small {
      min-width: 20px;
      min-height: 20px;

      & svg {
        width: 12px;
      }
    }

    &__progress-bar_small {
      width: 90vw;
    }
  }
}

[data-theme|='dark'] {
  .sla {
    &__clock {
      color: var(--grey180);
    }

    &__round-clock {
      & span,
      svg {
        color: var(--grey180);
      }
    }

    &__expire-time {
      color: var(--grey180);
    }
  }
}

[data-theme='light-contrast'] {
  .sla {
    &__tag {
      &_decision {
        color: var(--blue180);

        & span {
          color: var(--blue180);
        }
      }

      &_reaction {
        color: var(--orange180);

        & span {
          color: var(--orange180);
        }
      }
    }

    &__expire-time {
      color: var(--red180);
      font-size: $sm-text;
      font-weight: 500;
    }

    &__clock {
      color: var(--blue180);

      &_expired {
        color: var(--red180);
      }
    }
  }
}
