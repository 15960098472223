@use "./src/shared/assets/scss/variables/index.scss" as *;
.data-table {
  &__creator {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 10px;
  }

  &__calendar {
    min-width: 20px;
    height: 20px;
    color: var(--grey80);
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--blue120);
    grid-gap: $indent-xs;

    & span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      opacity: 0.8;
    }

    &-icon {
      min-width: 20px;
      width: 20px;
      height: 20px;
      color: var(--blue60);
    }
  }
}

[data-theme|='dark'] {
  .data-table__title {
    color: #3392ff;
  }
}

[data-theme='light-contrast'] {
  .data-table {
    &__title {
      color: var(--blue170);
    }
  }
}
