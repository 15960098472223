@use "./src/shared/assets/scss/variables/index.scss" as *;
.ellipse-icon-button {
  min-width: 48px;
  height: 40px;
  transition: background-color $transition, border $transition, border-color $transition;
  position: relative;
  border-radius: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--blue120);
  color: var(--common2);

  &:hover {
    cursor: pointer;
    background-color: var(--blue100);
  }

  &:disabled {
    background-color: var(--grey50);
    cursor: not-allowed;
  }

  & svg {
    color: var(--common2);
  }

  &:focus {
    outline: 2px solid var(--blue180);
  }
}

[data-theme|='dark'] {
  .ellipse-icon-button {
    & svg {
      color: var(--grey200);
    }

    &:disabled {
      & svg {
        color: var(--common2);
      }
    }
  }
}
