@use "./src/shared/assets/scss/variables/index.scss" as *;
.input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  $this: &;

  &_xs {
    min-width: 140px;
  }

  &__container {
    display: inline-block;
    position: relative;
    width: 100%;

    &_xs {
      height: 32px;
    }

    &_s {
      height: 40px;
    }

    &_m {
      height: 48px;
    }

    &_l {
      height: 56px;
    }
  }

  &__item {
    position: absolute;
    color: var(--grey200);
    width: 100%;
    height: 100%;
    border: 1px solid var(--grey30);
    border-radius: $border-radius-sm;
    font-family: inherit;
    font-size: $sm-text;
    outline: none;
    padding-left: $indent-sm;
    line-height: 18px;
    z-index: $z-index-10;
    transition: border-color 0.3s;
    background-color: var(--common2);

    &_xs {
      &:not(:placeholder-shown),
      &:focus,
      &:-webkit-autofill {
        padding-top: 0;
        & ~ #{$this}__label {
          visibility: hidden;
        }
      }
    }

    &_s {
      &:not(:placeholder-shown),
      &:focus,
      &:-webkit-autofill {
        padding-top: 0;
        & ~ #{$this}__label {
          visibility: hidden;
        }
      }
    }

    &_m {
      &:not(:placeholder-shown),
      &:focus,
      &:-webkit-autofill {
        padding-top: $indent-md;
        & ~ #{$this}__label {
          top: 6px;
          line-height: 15px;
          font-size: $xs-text;
        }
      }
    }

    &_l {
      font-size: $md-text;
      line-height: 20px;

      &:not(:placeholder-shown),
      &:focus,
      &:-webkit-autofill {
        padding-top: 21px;
        & ~ #{$this}__label {
          top: 8px;
          line-height: 18px;
          font-size: $sm-text;
        }
      }
    }

    &:hover:not(#{$this}__item_error):not(:disabled) {
      border-color: var(--grey100);
    }

    &:focus:not(#{$this}__item_error):not(:disabled) {
      border-color: var(--blue120);
    }

    &_error {
      border-color: var(--red120);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--grey130);
      background: var(--grey10);
      border-color: var(--grey10);
      & ~ #{$this}__label,
      &:not(:placeholder-shown):disabled#{$this}__item:not(:focus):disabled ~ #{$this}__label {
        z-index: $z-index-20;
      }
    }

    &::-webkit-calendar-picker-indicator {
      background-image: url("../Input/calendar.svg");
      margin-right: $indent-xs;
      margin-bottom: $indent-sm;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 20px;
      height: 20px;
    }
  }

  &__label {
    font-size: $sm-text;
    white-space: nowrap;
    z-index: $z-index-10;
    overflow: hidden;
    font-weight: 400;
    max-width: 85%;
    text-overflow: ellipsis;
    position: absolute;
    left: $indent-sm;
    color: var(--grey100);
    cursor: text;
    font-style: normal;
    line-height: 18px;
    transition: top 200ms ease-in, font-size 200ms ease-in;

    &_xs {
      top: 7px;
    }

    &_s {
      top: 10px;
    }

    &_m {
      top: 14px;
    }

    &_l {
      font-size: $md-text;
      line-height: 20px;
      top: 19px;
    }
  }

  &__error {
    &-message {
      color: var(--red120);
      font-size: $xs-text;
      line-height: 15px;

      &_l {
        font-size: $sm-text;
        line-height: 18px;
      }
    }

    &-margin {
      margin-top: 6px;

      &_xs {
        margin-top: $indent-xxs;
      }
    }
  }

  &__eye-button {
    position: absolute;
    right: $indent-sm;
    top: 50%;
    transform: translateY(-50%);
    z-index: $z-index-10;
    color: var(--blue120);
  }

  &__icons-wrapper {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: $z-index-10;
    display: flex;
    align-items: center;
    grid-gap: $indent-xs;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--grey50);
    width: 20px;
    height: 20px;

    &_clear {
      cursor: pointer;
    }
  }
}
