@use "./src/shared/assets/scss/variables/index.scss" as *;
.contracts-drop-content {
  position: inherit;
  top: 5px;
  right: 0;
  box-shadow: var(--box-shadow-lvl-2);
  border-radius: $border-radius-sm;
  z-index: $z-index-dropdown;
  background-color: var(--common2);

  &__button {
    &-wrapper {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  @media screen and (max-width: 370px) {
    font-size: $xs-text;
  }
}
