@use "./src/shared/assets/scss/variables/index.scss" as *;
.users-from-groups-table {
  &__icon {
    width: 20px;
    min-width: 20px;
    color: var(--grey80);

    &_blue {
      color: var(--blue120);
    }
  }
}
