@use "./src/shared/assets/scss/variables/index.scss" as *;
.preview-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: var(--box-shadow-lvl-4);
  background-color: var(--transparent1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $z-index-modal;
  animation-name: appear;
  animation-duration: 300ms;

  &__container {
    position: relative;
    background: var(--background);
    border-radius: $border-radius-sm;
    width: 1386px;
    height: 884px;
    overflow: hidden;
  }

  &__header {
    background-color: var(--grey10);
    padding: $indent-xs $indent-lg $indent-xs $indent-lg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey20);
    height: 56px;

    &-left {
      color: var(--grey120);
      display: flex;
      align-items: center;

      &-user {
        line-height: $md-text;
        font-size: $xs-text;
        margin-bottom: $indent-xxs;
      }

      &-file {
        line-height: $lg-text;
        font-size: $sm-text;

        &_size {
          margin-right: $indent-xs;
        }
      }

      &-avatar {
        margin-right: $indent-xxs;
      }
    }

    &-right {
      display: flex;
      justify-content: center;
      align-items: center;

      &-name-file {
        margin-right: $indent-xxxl;
      }

      &-buttons {
        display: flex;

        &_download {
          margin-right: $indent-xs;
          border-radius: $border-radius-sm;
          width: 48px;
          height: 40px;
          padding: $indent-xs, $indent-sm, $indent-xs, $indent-sm;
          background: var(--grey20);
          color: var(--grey140);
        }

        &_close {
          width: 48px;
          height: 40px;
          background: var(--grey20);
          color: var(--grey140);
        }
      }
    }
  }

  &__image {
    max-height: 828px;
    max-width: 1386px;
  }

  &__buttons {
    &_right-arrow {
      background: var(--grey20);
      color: var(--grey140);
      width: 64px;
      height: 56px;
      border-radius: $border-radius-sm;
      margin-left: $indent-md;
    }

    &_left-arrow {
      background: var(--grey20);
      color: var(--grey140);
      width: 64px;
      height: 56px;
      border-radius: $border-radius-sm;
      margin-right: $indent-md;
    }
  }

  &__control-buttons {
    display: flex;
    position: absolute;
    z-index: 1;
    bottom: 24px;
    right: 24px;

    &_zoom {
      margin-right: $indent-xs;
      background: var(--grey20);
      color: var(--grey140);
      width: 48px;
      height: 40px;
      border-radius: $border-radius-sm;
    }

    &_reset {
      border: 2px solid var(--grey20);
      border-radius: $border-radius-sm;
      background: var(--grey20);
      width: 48px;
      height: 40px;
      color: var(--grey140);
    }
  }
}
