@use "./src/shared/assets/scss/variables/index.scss" as *;
.filter {
  position: relative;
  background-color: var(--grey20);
  display: table-row-group;

  $this: &;

  &__cell {
    overflow: inherit;
  }

  &__empty-cell {
    background-color: var(--grey20);
    left: 0;
    position: sticky;
  }

  &__button-wrapper {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__close-button {
    &:disabled {
      #{$this}__icon {
        path {
          stroke: var(--grey50);
        }
      }
    }

    &-cell {
      z-index: $z-index-filter;
      visibility: initial;
      position: sticky;
      right: -2px;
      background-color: var(--grey20);
    }
  }

  &__icon {
    color: var(--grey80);
  }

  &__row {
    background-color: var(--grey20);

    &:hover {
      background-color: var(--grey20);
    }
  }

  @media screen and (max-width: 768px) {
    &__close-button-cell {
      right: 0;
    }
  }
}

[data-theme|='dark'] {
  .filter {
    &__close-button {
      &:disabled {
        .filter__icon {
          color: var(--grey80);
        }
      }
    }

    &__icon path {
      stroke: var(--common2);
    }

    &__row {
      &:hover {
        background-color: var(--grey20);
      }
    }
  }
}
