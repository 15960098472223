@use "./src/shared/assets/scss/variables/index.scss" as *;
.responsibilities-table-container {
  min-width: 320px;

  &_full {
    height: 100%;
  }

  &_from-roles {
    height: 365px;
  }

  &_from-groups {
    height: 365px;
  }

  &__icon-delete {
    color: var(--red100);
  }

  @media screen and (max-width: 1200px) {
    &_full {
      height: 768px;
    }
  }

  @media screen and (max-width: 768px) {
    min-width: 0;

    &_full {
      height: 354px;
    }

    &_from-groups {
      height: 354px;
    }

    &_from-roles {
      height: 354px;
    }
  }
}
