@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-tabs {
  &__container {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: $indent-lg;
    height: 100%;

    &_create {
      padding-left: $indent-xxxl;
      padding-bottom: $indent-md;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color: var(--common2);
    color: var(--grey200);
    box-shadow: inset 0 -2px 0 0 var(--grey20);
    padding: 0 $indent-md;

    &-tabs {
      display: flex;
      flex-direction: row;
      column-gap: $indent-lg;
      overflow-x: auto;
    }
  }

  &__buttons {
    display: flex;
  }
}
