@use "./src/shared/assets/scss/variables/index.scss" as *;
.profile-user {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 287px;
  flex-basis: 100%;
  height: fit-content;
  min-height: calc(100% - 58px);
  background-color: var(--grey10);
  padding: 0 0 $indent-xl;
  margin-top: 58px;

  &__helper {
    flex-basis: 20%;
    min-width: 287px;
    max-width: 287px;
    display: flex;
    overflow: auto;
    background-color: var(--common2);
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-right: 1px solid var(--grey20);

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }

  &__arrow-icon {
    display: none;
    transform: rotate(90deg);
    transition: transform $transition ease-in-out;

    &_open {
      transform: rotate(-90deg);
      transition: transform $transition ease-in-out;
    }
  }

  &__initials {
    position: absolute;
    top: -29px;
  }

  &__full-name {
    margin-top: 50px;
    display: flex;
    text-align: center;
    position: relative;

    &-item {
      max-width: 180px;
      align-items: center;
      white-space: pre-wrap;
    }
  }

  &__fio {
    margin-bottom: $indent-xl;
    line-height: $indent-xl;
    color: var(--grey200);
  }

  &__organization {
    background: var(--cyan20);
    color: var(--cyan120);
    padding: $indent-xxs $indent-sm;
    border-radius: $border-radius-xs;
    margin-top: $indent-xs;

    &_client {
      background-color: var(--purple20);
      color: var(--purple120);
    }
  }

  &__row {
    width: $indent-xl;
    height: $indent-xl;
    border-radius: $border-radius;
    display: none;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: $indent-xs;
      width: $indent-xs;
      height: 2px;
      transition: all 0.2s ease-out;
      background-color: var(--grey100);
      transform: translate(-3px, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(2px, -50%) rotate(-45deg);
    }

    &-up {
      &::before {
        transform: translate(-3px, -50%) rotate(-45deg);
      }

      &::after {
        transform: translate(3px, -50%) rotate(45deg);
      }
    }
  }

  &__system-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--grey20);
    grid-gap: 1px;
    margin-top: $indent-lg;

    &-item {
      background-color: var(--common2);
      padding: $indent-sm;
    }
  }

  &__icon-wrapper {
    background-color: var(--grey10);
  }

  &__icon-inner {
    width: $indent-md;
    color: var(--grey100);
  }

  &__info-title {
    font-size: $sm-text;
    font-weight: 400;
    line-height: $indent-lg;
  }

  &__top-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $indent-xl $indent-lg $indent-xl;
    border-bottom: 1px solid var(--grey20);
  }

  @media screen and (max-width: 768px) {
    &__arrow-icon {
      display: block;
    }

    &__helper {
      border-radius: 0;
      overflow: initial;
      border-right: none;
      max-width: none;
    }

    &__row {
      display: block;
    }

    &__full-name {
      &-item {
        max-width: max-content;
      }
    }

    &__system-info {
      height: 0;
      display: none;

      &_open {
        height: auto;
        display: flex;
      }
    }
  }
}

[data-theme='light-contrast'] {
  .profile-user {
    &__organization {
      color: var(--cyan180);

      &_client {
        color: var(--purple180);
      }
    }
  }
}
