@use "./src/shared/assets/scss/variables/index.scss" as *;
.create-ticket {
  background-color: var(--grey10);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-bottom: 49px;

  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-title {
      display: block;
      margin-bottom: $indent-xl;
    }
  }

  &__inputs-card {
    max-width: 64%;
    flex-basis: 64%;
    margin-right: 14px;
  }

  &__radio-card {
    flex-basis: 34%;
  }

  &__radio-title {
    display: block;
    margin-bottom: 33px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $indent-md;
  }

  &__inputs-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $indent-md;
    width: 100%;

    :nth-child(1) {
      grid-column: span 2;
    }

    :nth-child(n+2) {
      grid-column: span 1;
    }
  }

  &__custom-fields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $indent-md;
    width: 100%;
  }

  &__editor {
    width: 100%;
  }

  &__buttons-block {
    margin-top: 48px;
    width: 100%;
    padding-right: 3px;
    display: flex;
    justify-content: flex-end;
  }

  &__submit-button {
    margin-left: 10px;
  }

  &__file {
    &-names-wrapper {
      display: flex;
      margin-top: 10px;
      overflow-x: auto;
      padding-bottom: $indent-xxs;
    }

    &-block:not(:last-child) {
      margin-right: $indent-md;
    }
  }

  &__multi-select-wrapper {
    display: flex;
    width: 100%;
    grid-gap: $indent-xs;
  }

  &__edit {
    &-buttons {
      display: flex;
      width: fit-content;
      height: 48px;
      flex-direction: row;
      background-color: var(--grey20);
      align-items: center;
      grid-gap: $indent-xs;
      border-radius: $border-radius-sm;
      padding: $indent-sm $indent-md;
    }

    &-close {
      background-color: var(--grey80);

      &:hover {
        background-color: var(--grey60);
      }
    }
  }

  &__option {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    height: 48px;
    max-height: 48px;
    grid-gap: 2px;

    &-title {
      font-weight: 400;
      color: var(--grey200);
      line-height: 18px;
      font-size: $sm-text;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-theme {
      line-height: 14px;
      font-size: $xs-text;
      color: var(--grey100);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 1300px) {
    &__inputs-card {
      flex-basis: auto;
      margin-right: 0;
      max-width: none;
      padding-bottom: 0;
    }

    &__file-names {
      margin-bottom: $indent-lg;
    }

    &__form {
      display: block;
    }

    &__radio-card {
      padding-top: 0;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    border-radius: 0;

    &__wrapper {
      gap: $indent-sm;
    }

    &__inputs-wrapper {
      gap: $indent-sm;

      :nth-child(1),
      :nth-child(2),
      :nth-child(5) {
        grid-column: span 3;
      }

      :nth-child(3) {
        grid-column: span 2;
      }

      :nth-child(4) {
        grid-column: span 1;
      }

      :nth-child(n+6) {
        grid-column: span 1;
      }
    }

    &__custom-fields {
      gap: $indent-sm;
      grid-template-columns: repeat(2, 1fr);
    }

    &__inputs-card {
      border-radius: 0;
    }

    &__slide-radio {
      flex-basis: 100%;
      order: -1;
    }

    &__radio-card {
      padding: 0;
      margin-bottom: 100px;
    }

    &__priority-radio {
      padding: $indent-sm $indent-md;
    }

    &__buttons-block {
      box-shadow: var(--box-shadow-lvl-3);
      position: fixed;
      right: 0;
      margin: auto;
      overflow: auto;
      left: 0;
      background-color: var(--common2);
      border-radius: $border-radius-sm;
      padding: 8px $indent-sm;
      bottom: 95px;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: $z-index-action-button;
    }

    &__file-names-wrapper {
      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
    }
  }

  &__priority-block {
    margin-left: 28px;
  }
}
