@use "./src/shared/assets/scss/variables/index.scss" as *;
.notification-circle {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--purple80);
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;

  &::after,
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    top: 0;
    background-color: var(--purple80);
    border-radius: 100%;
    -webkit-animation: pulse 2s infinite ease-in-out;
    animation: pulse 2s infinite ease-in-out;
  }

  &_new {
    background-color: var(--orange80);

    &::after,
    &::before {
      background-color: var(--orange80);
    }
  }

  &::before {
    margin: auto 0 auto -7px;
    height: 14px;
    width: 14px;
    opacity: 0.5;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  &::after {
    margin: auto 0 auto -9px;
    height: 18px;
    width: 18px;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    opacity: 0.25;
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
