@use "./src/shared/assets/scss/variables/index.scss" as *;
.range {
  display: flex;
  column-gap: $indent-xxs;
  align-items: center;

  &__typography {
    color: var(--grey80);
  }

  &__from {
    font-size: $sm-text;
    display: flex;
  }

  &__to {
    font-size: $sm-text;
    display: flex;
  }

  &__text {
    font-size: $sm-text;
    color: var(--grey80);
  }

  &__btn-clear {
    width: $indent-lg;
    height: $indent-lg;
    padding: 2px;
    background: var(--grey30);
    color: var(--grey120);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 16px;

    &__text {
      padding-top: 0;
      margin-bottom: 16px;
    }
  }
}
