@use "./src/shared/assets/scss/variables/index.scss" as *;
.utils {
  &__title {
    border: none;
    color: var(--blue120);
    transition: opacity $transition;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;

    &:hover {
      opacity: 0.8;
    }
  }

  &_customer {
    color: var(--purple120);
    background-color: var(--purple20);
  }

  &_service {
    color: var(--cyan120);
    background-color: var(--cyan20);
  }
}

[data-theme='light-contrast'] {
  .utils {
    &_customer {
      color: var(--purple180);
    }

    &_service {
      color: var(--cyan180);
    }

    &__title {
      color: var(--blue170);
    }
  }
}

[data-theme|='dark'] {
  .utils__title {
    color: #3392ff;
  }
}
