@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-tabs-block {
  &__tabs-wrapper {
    display: flex;
    flex-direction: row;
    grid-gap: $indent-md;
    box-shadow: inset 0 -2px 0 0 var(--grey20);
  }

  @media screen and (max-width: 1200px) {
    &__tabs-wrapper {
      grid-gap: 40px;
    }
  }
}
