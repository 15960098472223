@use "./src/shared/assets/scss/variables/index.scss" as *;
.profile-layout {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 52px);
  overflow: hidden;

  &__wrapper {
    display: flex;
    overflow: auto;
    flex-basis: 100%;
  }

  &__user {
    border-bottom-left-radius: $border-radius;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    max-height: none;
    height: fit-content;

    &__wrapper {
      flex-direction: column;
    }

    &__user {
      border-radius: 0;
    }

    &_no-data {
      height: auto;
    }
  }
}
