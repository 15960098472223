@use "./src/shared/assets/scss/variables/index.scss" as *;
.radio-group {
  width: 100%;
  padding: $indent-xxxl 44px;
  background-color: var(--grey10);
  user-select: none;

  &__label {
    display: flex;
    align-items: center;
    border-radius: $border-radius-sm;
    padding: $indent-xl $indent-md;
    background-color: var(--common2);
    margin-bottom: $indent-xs;
    border: 1.5px solid transparent;

    &:hover {
      cursor: pointer;
    }

    &_disabled {
      background-color: var(--grey10);
      border-color: var(--grey20);

      &:hover {
        cursor: not-allowed;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:focus-within {
      outline: 1px solid var(--blue120);
    }
  }

  &__input {
    position: relative;

    &::after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 44px;
      background-color: var(--grey20);
      position: absolute;
      right: -14px;
      bottom: -$indent-md;
      border-radius: $border-radius-sm;
    }

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      &:hover {
        cursor: not-allowed;
      }

      & ~ .radio__tab-title {
        color: var(--grey80);

        &::before {
          background-color: var(--grey20);
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding: $indent-xs;
  }

  @media screen and (max-width: 1024px) {
    padding: $indent-xxxl 44px;
  }

  @media screen and (max-width: 768px) {
    &__label {
      padding: $indent-sm $indent-md;
    }
  }
}
