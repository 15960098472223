@use "./src/shared/assets/scss/variables/index.scss" as *;
.supplementary-agreement-form {
  &__form {
    display: flex;
    flex-wrap: wrap;

    &-header {
      padding-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: inset 0 -2px 0 0 var(--grey20);

      &-tab {
        width: fit-content;
        padding-bottom: 15px;
        box-shadow: inset 0 -2px 0 0 var(--blue120);
      }
    }

    &-content {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      display: flex;
      flex-wrap: wrap;
      column-gap: $indent-md;
    }

    &-input-data {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &-upper-part {
      display: flex;
      justify-content: space-between;
    }

    &-lower-part {
      display: flex;
      justify-content: space-between;
    }

    &-lower-part::after {
      content: "";
      flex-basis: 32%;
    }
  }

  &__input {
    margin-bottom: $indent-md;

    &_one-of-three {
      flex-basis: 32%;
    }
  }

  &__textarea {
    flex-basis: 66%;
  }

  &__radio {
    display: flex;
    flex-direction: column;
    row-gap: $indent-md;
  }

  &__radio-group {
    row-gap: $indent-xs;
  }

  &__icon {
    color: var(--blue120);
    width: 24px;
    height: 24px;
  }

  &__tooltip {
    max-width: 280px;
  }

  @media screen and (max-width: 768px) {
    border-bottom: 2px solid var(--grey20);

    &__form {
      &-upper-part {
        flex-direction: column;
        min-height: 200px;
      }

      &-lower-part {
        flex-direction: column;
      }

      &-header {
        display: none;
      }

      &-content {
        border-radius: 0;
      }
    }

    &__input {
      &_one-of-three {
        flex-basis: auto;
      }
    }

    &__radio {
      margin-top: $indent-md;
    }
  }
}
