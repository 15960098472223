@use "./src/shared/assets/scss/variables/index.scss" as *;
.profile-telegram {
  flex-basis: 80%;
  background-color: var(--common2);
  display: flex;
  flex-direction: column;
  padding: 30px 42px;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  overflow-y: auto;

  &__title {
    color: var(--grey200);
    margin-bottom: $indent-sm;
  }

  &__sub-title {
    color: var(--grey120);
    margin-bottom: $indent-xxxl;
  }

  &__qr-code {
    margin-bottom: $indent-xl;
  }

  &__loader {
    margin-bottom: $indent-xl;
    margin-left: 84px;
  }

  &__button {
    width: 216px;
  }

  &__link {
    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    flex-basis: 100%;
    align-items: center;
    padding: 30px 24px;

    &__loader {
      margin-left: 0;
    }
  }
}
