@use "./src/shared/assets/scss/variables/index.scss" as *;
.all-tickets-panel {
  width: 40%;
  margin-top: $indent-sm;
  padding: $indent-xxxl 60px;
  border-radius: $border-radius;
  min-height: 382px;
  background: var(--common2);

  &__container {
    display: flex;
    justify-content: center;
    gap: 60px;
    margin-top: 40px;
  }

  &__content {
    display: flex;
    justify-content: center;
    gap: 40px;

    &-icon-block {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius-sm;
      background-color: var(--green120);
    }

    &-icon {
      color: var(--common2);
    }

    &-all {
      margin-top: $indent-xxxl;
      color: var(--grey120);

      &-quantity {
        color: var(--grey200);
      }
    }
  }

  &__line {
    width: 0;
    height: 126px;
    border: 1px solid var(--grey20);
    background-color: var(--grey20);
  }

  &__field {
    display: flex;
    align-items: flex-start;
    gap: $indent-sm;
    margin-top: $indent-xxl;

    &:first-child {
      margin-top: 0;
    }

    &-container {
      height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-title-container {
      display: flex;
    }

    &-quantity {
      width: 28px;
      text-align: right;
    }

    &-text {
      color: var(--grey120);
      white-space: nowrap;
    }

    &-progress-bar {
      width: 100px;
      background-color: transparent;

      &_loaded {
        &-first {
          background-color: var(--green100);
        }

        &-refusal {
          background-color: var(--orange100);
        }

        &-expire {
          background-color: var(--red70);
        }
      }
    }
  }

  &__tooltip {
    &-container {
      display: flex;
      grid-gap: 20px;
    }

    &-block {
      display: flex;
      gap: 12px;
    }

    &-question-icon {
      width: 16px;
      height: 16px;
      margin-left: $indent-sm;
      margin-top: -10px;
      outline: none;
      color: var(--grey120);
    }

    &-clock-icon {
      &-left {
        width: 12px;
        height: 12px;
        color: var(--orange120);
      }

      &-right {
        width: 12px;
        height: 12px;
        color: var(--blue120);
      }
    }

    &-clock-icon-container {
      &-left {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius;
        background-color: var(--orange20);
      }

      &-right {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius;
        background-color: var(--blue20);
      }
    }

    &-text {
      color: var(--grey40);
    }

    &-quantity {
      color: var(--grey10);
    }
  }

  @media screen and (max-width: 1600px) {
    width: 100%;

    &__container {
      gap: 150px;
    }

    &__field {
      &-progress-bar {
        width: 350px;
      }
    }
  }

  @media screen and (max-width: 1350px) {
    &__container {
      gap: 100px;
    }

    &__field {
      &-progress-bar {
        width: 150px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    padding: $indent-xxxl 40px;

    &__content {
      grid-gap: 20px;
    }

    &__field {
      &-progress-bar {
        width: 70px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      margin-top: $indent-xxl;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__container {
      margin-top: 40px;
      flex-direction: column-reverse;
      gap: 40px;
    }

    &__line {
      display: none;
    }

    &__field {
      &-progress-bar {
        width: 250px;
      }
    }
  }

  @media screen and (max-width: 470px) {
    &__field {
      &-progress-bar {
        width: 80px;
      }
    }
  }
}

[data-theme|='dark'] {
  .all-tickets-panel {
    &__content {
      &-icon {
        color: var(--grey200);
      }
    }

    &__tooltip {
      &-text {
        color: var(--grey120);
      }

      &-quantity {
        color: var(--grey200);
      }

      &-clock-icon {
        &-left {
          color: var(--grey200);
        }

        &-right {
          color: var(--grey200);
        }
      }
    }
  }
}
