@use "./src/shared/assets/scss/variables/index.scss" as *;
.pie-chart-legend {
  background-color: var(--common2);
  display: flex;
  flex-direction: column;
  grid-gap: $indent-xl;
  position: relative;
  max-width: 140px;
  margin-left: 70px;

  &::before {
    content: '';
    position: absolute;
    left: -44px;
    width: 1px;
    height: 80%;
    transform: translateY(-50%);
    top: 50%;
    background-color: var(--grey20);
  }

  &__item {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    grid-gap: $indent-md;

    &-name {
      color: var(--grey120);
      text-transform: uppercase;
      font-size: $xxs-text;
      font-weight: 500;
      letter-spacing: 1px;
    }

    &-value {
      color: var(--grey160);
    }

    &-info {
      text-align: start;
      padding-left: $indent-xl;
    }
  }

  &__circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    left: 0;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.highcharts-react__wrapper {
  width: 65%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
