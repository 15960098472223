@use "./src/shared/assets/scss/variables/index.scss" as *;
.tabs-item {
  display: inline-block;
  color: var(--grey-120);
  transition: color, border-bottom-color, var(--transition-delay);
  white-space: nowrap;
  position: relative;
  border-bottom: 2px solid transparent;
  user-select: none;
  font-weight: var(--font-weight-500);
  cursor: pointer;

  $this: &;

  &_14 {
    font-size: var(--font-size-14);
    padding-bottom: 6px;
    padding-top: 5px;
    line-height: var(--line-height-21);
  }

  &_16 {
    font-size: var(--font-size-16);
    padding-bottom: var(--indent-12pt);
    line-height: var(--line-height-21);
    padding-top: 13px;
  }

  &:hover,
  &:focus {
    &:not(#{$this}_disabled) {
      color: var(--grey-200);
    }
  }

  &:global.active {
    color: var(--grey-200);
    border-bottom-color: var(--blue-120);

    &:hover,
    &:focus {
      &:not(#{$this}_disabled) {
        border-bottom-color: var(--blue-100);
      }
    }
  }

  &:active:not(#{$this}_disabled) {
    border-bottom-color: var(--blue-140);
  }

  &_disabled {
    cursor: not-allowed;
    color: var(--grey-80);
  }
}
