@use "./src/shared/assets/scss/variables/index.scss" as *;
.data-empty {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: var(--grey200);
    margin-top: 50px;

    &_hidden {
      display: none;
    }

    &_no-files {
      display: none;
    }
  }

  &__message {
    text-align: center;
    margin-top: $indent-sm;
    color: var(--grey120);

    &_mt {
      font-size: $md-text;
      margin-top: 38px;
    }

    &_no-files {
      margin-top: $indent-md;
    }
  }

  &__button {
    margin-top: 46px;
  }

  &__icon-no-data {
    &_default {
      & path {
        stroke: var(--grey20);
      }
    }

    &_filter {
      & path {
        stroke: var(--grey20);
      }

      & circle {
        stroke: var(--blue20);
      }
    }

    &_ticket {
      & path {
        stroke: var(--grey20);
      }

      & ellipse {
        stroke: var(--blue20);
        fill: var(--blue20);
      }
    }

    &_no-expired {
      & path:first-child {
        stroke: var(--green40);
      }

      & path:nth-child(2) {
        stroke: var(--green90);
      }
    }

    &_files {
      color: var(--grey100);
    }
  }

  @media screen and (max-width: 768px) {
    flex-grow: 1;

    &__title {
      font-size: $lg-text;
      margin-top: $indent-xxxl;
    }

    &__button {
      margin-top: $indent-xxxl;
    }

    &__message {
      &_mt {
        margin-top: $indent-xxl;
      }
    }
  }
}
