@use "./src/shared/assets/scss/variables/index.scss" as *;
.builder {
  height: 100%;
  padding: $indent-xl;
  background-color: var(--common2);
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  grid-gap: $indent-xl;
  position: relative;
  overflow: hidden;

  &__background {
    background-color: var(--grey10);
    border-radius: $border-radius-sm;
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: $z-index-10;
    width: fit-content;

    &_left {
      right: auto;
      top: 44px;
      left: 44px;
      display: none;
    }
  }

  &__select-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: $z-index-10;
    width: fit-content;

    &_left {
      display: none;
    }
  }

  &__select {
    width: 239px;

    &_not-active {
      border: 1px solid var(--grey30);
      border-radius: $border-radius;
    }
  }

  &__create-button {
    background-color: var(--common2) !important;
    filter: drop-shadow(var(--box-shadow-lvl-1));
    min-height: 0;
    height: 32px;

    &-icon {
      width: 20px;
      height: 20px;
    }

    &_mobile {
      z-index: $z-index-10;
      display: none;
    }
  }

  &__copy-button {
    background-color: var(--common2);
    margin-right: $indent-sm;

    &-icon {
      color: var(--blue120);
    }
  }

  &__delete-button {
    background-color: var(--common2);
    filter: drop-shadow(var(--box-shadow-lvl-1));
    margin-right: $indent-sm;
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition;

    &_visible {
      opacity: 1;
      visibility: visible;
    }

    &-icon {
      color: var(--red100);
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 20px;
    right: 32px;
    bottom: 20px;
  }

  &__wrapper-save-buttons {
    display: flex;
    z-index: $z-index-10;
    grid-gap: $indent-md;
    padding: $indent-sm;
    background-color: var(--common2);
    border-radius: $border-radius;
    opacity: 1;
    visibility: visible;
    transition: opacity $transition;

    &-item {
      width: 95px;
    }

    &_hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__edit-button {
    z-index: $z-index-10;
    visibility: hidden;
    opacity: 0;
    transition: opacity $transition;

    &_visible {
      visibility: visible;
      opacity: 1;
    }
  }

  @media screen and (max-width: 1600px) {
    &__buttons-wrapper {
      &_left {
        display: flex;
      }
    }

    &__select-wrapper {
      display: none;

      &_left {
        display: flex;
        margin-left: $indent-xl;
      }

      &_hidden {
        display: block;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    &__select-wrapper {
      &_hidden {
        display: none;
      }
    }

    &__select {
      width: 180px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: $indent-sm;

    &__create-button {
      display: none;

      &_mobile {
        display: flex;
      }
    }

    &__select {
      width: 53vw;
      max-width: 264px;
    }

    &__buttons-wrapper {
      top: 12px;
      right: 12px;

      &_left {
        display: block;
        left: 24px;
        top: 24px;
      }
    }

    &__select-wrapper {
      &_left {
        margin-left: 0;
        margin-top: $indent-xl;
      }

      &_hidden {
        display: block;
      }
    }

    &__footer {
      flex-direction: column-reverse;
      align-items: flex-end;
      justify-content: flex-start;
      grid-gap: $indent-sm;
      left: 8px;
      right: 8px;
      bottom: 8px;
    }

    &__edit-button {
      margin-right: $indent-sm;
    }

    &__wrapper-save-buttons {
      width: 100%;
      flex-direction: column-reverse;
      grid-gap: $indent-sm;

      &-item {
        width: 100%;
      }

      &_hidden {
        display: none;
      }
    }
  }
}
