@use "./src/shared/assets/scss/variables/index.scss" as *;
.navigation {
  display: flex;
  flex-direction: column;
  min-width: 72px;
  padding: 40px $indent-xs $indent-xl $indent-xs;
  height: 100vh;
  background-color: var(--blue190);
  align-items: center;
  position: sticky;
  top: 0;
  z-index: $z-index-navigations;
  left: 0;
  transition: background-color $transition, min-width $transition;
  justify-content: space-between;

  $this: &;

  &_open {
    min-width: 200px;
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  &__tab {
    &:not(:last-child) {
      margin-bottom: $indent-xxs;
    }
  }

  &__t1-icon {
    &-block {
      padding-bottom: 52px;
      width: fit-content;
      transition: $transition opacity;

      &:hover {
        opacity: 0.8;
      }
    }

    &-label {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:focus {
        outline: 2px solid var(--blue180);
        border-radius: $border-radius-sm;
      }

      & svg {
        color: var(--common2);
      }
    }

    &-title {
      color: var(--common2);
      letter-spacing: 0.8px;
      font-weight: 700;
      font-size: $sm-text;
      text-transform: uppercase;
      opacity: 0;
      visibility: hidden;
      transition: opacity $transition, max-width $transition;
      margin-top: $indent-lg;
      max-width: 0;

      &_expanded {
        opacity: 1;
        visibility: visible;
        max-width: 120px;
      }
    }
  }

  &__separator {
    width: 100%;
    height: 1px;
    background-color: var(--common2);
    opacity: 0.2;
    margin: $indent-lg 0;
  }

  &__toggle-button {
    line-height: 0;
    color: var(--common2);
    transition: transform $transition, margin-left $transition;

    &_open {
      margin-left: 124px;
      transform: rotate(180deg);
    }
  }

  &__help-button {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid var(--grey120);

    &-wrapper {
      min-width: 56px;
      width: 56px;
      height: 52px;
      border-radius: $border-radius;
      display: none;
      justify-content: center;
      align-items: center;
      opacity: 0.6;
      transition: background-color $transition, opacity $transition, border $transition;

      &:hover {
        background-color: var(--blue170);
        opacity: 1;
      }
    }

    &-icon {
      width: 12px;
      height: 12px;
      color: var(--common2);
    }
  }

  @media screen and (max-width: 1200px) {
    &__toggle-button {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    height: 68px;
    bottom: 0;
    width: 100%;
    flex-direction: row;
    align-items: center;
    box-shadow: var(--box-shadow-lvl-2);
    min-height: auto;
    min-width: auto;
    padding: 0;

    &__tab {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    &__separator {
      display: none;
    }

    &__tabs {
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
      grid-gap: $indent-md;
      overflow-x: scroll;
      padding: 0 $indent-xs;

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
    }

    &__t1-icon-block {
      display: none;
    }

    &__user-tab {
      border-bottom: none;
      height: 100%;

      &-wrapper {
        height: 100%;
        min-width: 20%;
      }
    }

    &__initial-user {
      height: 32px;
      width: 32px;

      &-title {
        font-size: $xs-text;
      }
    }

    &__question {
      width: 30px;
      height: 30px;

      &-tab {
        width: auto;
        min-width: 20%;
        height: 100%;
      }
    }

    &__help-button {
      &-wrapper {
        display: flex;
      }
    }

    &__icon-wrapper {
      position: relative;
    }

    &__notification {
      position: absolute;
      top: 0;

      &_purple {
        left: 26px;
      }

      &_yellow {
        left: 20px;
        background-color: var(--yellow120);

        &::after,
        &::before {
          background-color: var(--yellow120);
        }
      }
    }
  }
}

[data-theme|='dark'] {
  .navigation {
    background-color: #0a2a45;

    &__t1-icon {
      &-label {
        & svg {
          color: var(--grey200);
        }
      }

      &-title {
        color: var(--grey200);
      }
    }

    &__separator {
      background-color: var(--grey200);
    }

    &__toggle-button {
      line-height: 0;
      color: var(--grey200);
    }

    &__help-button {
      &-wrapper {
        &:hover {
          background-color: var(--blue20);
        }
      }

      &-icon {
        color: var(--grey200);
      }
    }
  }
}
