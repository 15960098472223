@use "./src/shared/assets/scss/variables/index.scss" as *;
.organizations {
  display: flex;
  flex-direction: row;
  min-height: auto;
  max-height: none;
  flex-basis: 100%;
  overflow: auto;

  @media screen and (max-width: 1200px) {
    display: block;
  }

  @media screen and (max-width: 768px) {
    margin-top: 1px;
    min-height: auto;
    height: 100%;
    background-color: var(--common2);

    &__organizations-table-container {
      height: 100%;
      border-radius: 0;
    }
  }
}
