@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-table-articles-search {
  width: 100%;
  padding: 0;

  &__inner {
    padding: $indent-xl $indent-xxl;
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: $indent-xxxl;
  }

  &__loader {
    margin: 0 auto;
  }

  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: $indent-xs;
    width: fit-content;
  }

  &__start {
    &-title {
      color: var(--grey150);
    }
  }

  &__articles {
    display: flex;
    height: calc(100% - 224px);
    overflow-y: scroll;
    flex-direction: column;
    padding: $indent-lg;
  }

  &__categories {
    &-list {
      justify-content: flex-start;
      padding: $indent-lg $indent-xxl;
      grid-gap: 22px;
      max-height: calc(100% - 224px);
      overflow: auto;
      flex-direction: column;
    }
  }

  &__category {
    min-width: 240px;
  }

  &__no-data {
    height: 100%;
    max-height: calc(100% - 224px);
  }

  @media screen and (max-width: 1200px) {
    &__inner {
      flex-direction: column-reverse;
      padding: $indent-lg;
    }

    &__categories {
      &-list {
        height: auto;
        max-height: none;
        justify-content: center;
      }
    }

    &__articles {
      height: auto;
      overflow-y: visible;

      &-loader {
        width: 40px;
        height: 40px;
      }
    }

    &__no-data {
      height: auto;
      max-height: none;
      padding-bottom: 70px;
    }
  }

  @media screen and (max-width: 768px) {
    &__categories {
      &-list {
        grid-gap: 10px;
        justify-content: flex-start;
      }
    }
  }
}
