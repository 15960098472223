@use "./src/shared/assets/scss/variables/index.scss" as *;
.popover {
  position: relative;
  display: inline-block;
  height: fit-content;

  &__container {
    z-index: $z-index-popover;

    &_elevated {
      z-index: $z-index-popover-inner;
    }
  }
}
