@use "./src/shared/assets/scss/variables/index.scss" as *;
.weekdays {
  border-bottom: 1px solid var(--grey10);
  display: flex;
  margin-bottom: $indent-xxs;

  &__day {
    align-items: center;
    display: flex;
    height: $indent-xxxl;
    justify-content: center;
    width: $indent-xxxl;
  }

  &__typography {
    color: var(--grey100);
    font-weight: 500;
    font-size: $xs-text;
  }

  @media screen and (max-width: 768px) {
    &__day {
      width: 100%;
    }

    &__typography {
      font-size: $sm-text;
    }
  }
}
