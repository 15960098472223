@use "./src/shared/assets/scss/variables/index.scss" as *;
.skeleton {
  pointer-events: none;
  animation: skeleton-glow 1s linear infinite alternate;

  &_absolute {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    z-index: $z-index-skeleton;
  }
}

@keyframes skeleton-glow {
  0% {
    background: var(--blue160);
    border-color: var(--blue160);
  }

  100% {
    background: var(--blue180);
    border-color: var(--blue180);
  }
}
