@use "./src/shared/assets/scss/variables/index.scss" as *;
.dropdown-date-picker {
  display: flex;
  column-gap: $indent-xs;

  &__icon {
    color: var(--grey100);
    height: 20px;
    min-width: auto;

    &_table-filter {
      min-width: 24px;
      height: 24px;

      & svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__divider {
    background: var(--grey20);
    width: 1px;
  }

  &__items {
    border-radius: 0 0 $border-radius $border-radius;
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    &__items {
      display: flex;
      z-index: $z-index-dropdown;
      border-radius: $border-radius $border-radius 0 0;
      background: var(--common2);
      height: 100vh;
      width: 100%;
      position: inherit;
      margin: 0;
    }

    &__divider {
      display: none;
    }
  }
}
