@use "./src/shared/assets/scss/variables/index.scss" as *;
.user-assistance-search-state {
  background-color: var(--grey10);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-basis: 100%;

  &__icon {
    width: 64px;
    height: 64px;
    color: var(--grey50);
    margin-bottom: $indent-xl;
  }

  &__text {
    color: var(--grey100);
  }
}
