@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-create-header {
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;

  &__title {
    white-space: nowrap;
    color: var(--grey200);
    text-overflow: ellipsis;
    overflow: hidden;

    &-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      grid-gap: $indent-lg;
      padding: $indent-lg 60px $indent-lg $indent-lg;
      border-right: 1px solid var(--grey20);
      max-width: 100%;
      overflow: hidden;
      margin-right: $indent-lg;
    }
  }

  &__popover {
    position: absolute;
    right: $indent-xl;

    &-inner {
      padding: $indent-md;
      border-radius: $border-radius-sm;
      position: static;
    }
  }

  @media screen and (max-width: 1670px) {
    flex-direction: column;
    align-items: flex-start;

    &__form {
      padding: $indent-lg;
      width: 100%;
      margin-right: 0;
    }

    &__title {
      &-wrapper {
        border-right: none;
      }
    }

    &__popover {
      top: $indent-xl;
    }
  }

  @media screen and (max-width: 1250px) {
    &__form {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 768px) {
    box-shadow: inset 0 -1px 0 0 var(--grey20);

    &__is-solution {
      display: none;
    }

    &__title {
      margin: 0 auto;

      &-wrapper {
        width: 100%;
      }
    }
  }
}
