@use "./src/shared/assets/scss/variables/index.scss" as *;
.current-role-adaptive {
  &__icon {
    width: 16px;
    height: 16px;
    color: var(--grey100);

    &-wrapper {
      min-width: 32px;
      height: 32px;
      border-radius: $border-radius;

      &_check-mark {
        background-color: var(--green20);
      }
    }

    &_check-mark {
      width: 12px;
      height: 12px;
      color: var(--green120);
    }
  }

  &__info {
    font-size: $xs-text;

    &-block {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &_hidden {
        display: none;
      }
    }
  }
}
