@use "./src/shared/assets/scss/variables/index.scss" as *;
.responsibilities-full-table {
  &__title {
    border: none;
    color: var(--blue120);
    transition: opacity $transition;

    &:hover {
      opacity: 0.8;
    }
  }
}

[data-theme|='dark'] {
  .responsibilities-full-table__title {
    color: #3392ff;
  }
}

[data-theme='light-contrast'] {
  .responsibilities-full-table__title {
    color: var(--blue170);
  }
}
