@use "./src/shared/assets/scss/variables/index.scss" as *;
.contracts-table-container {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  transition: height $transition ease-in-out;
  overflow: auto;

  &_full {
    height: 100%;
  }

  &_from-organizations {
    height: 442px;
  }

  &_from-systems {
    height: 442px;
  }

  &__pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
    min-height: 62px;
  }

  &__content {
    display: flex;
    height: 100%;
    overflow: auto;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background-color: var(--common2);
    flex-direction: column;
    justify-content: space-between;
    padding: 0 18px;
  }

  &_close {
    height: $table-header-height;
    overflow: hidden;
  }

  &__icon-delete {
    color: var(--red100);
  }

  @media screen and (max-width: 1200px) {
    &_full {
      height: 768px;
    }

    &_close {
      height: $table-header-height;
    }
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
    border-bottom: 2px solid var(--grey20);

    &_from-organizations {
      height: 354px;
    }

    &_from-systems {
      height: 354px;
    }

    &_full {
      height: 354px;
    }

    &_close {
      height: $table-header-height;
    }

    &__content {
      position: relative;
      border-radius: 0;
      padding: 0 $indent-sm;
    }

    &__pagination {
      justify-content: center;
      padding: 0;
      min-height: 48px;
    }

    &__count-records {
      display: none;
    }
  }
}
