@use "./src/shared/assets/scss/variables/index.scss" as *;
.icon-button {
  transition:
    background-color $transition,
    border $transition,
    border-color $transition;
  position: relative;
  border-radius: $border-radius-sm;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  user-select: none;

  &_xxs {
    min-width: 36px;
    height: 32px;

    & svg {
      width: 12px;
      height: 12px;
    }
  }

  &_xs {
    min-width: 36px;
    height: 32px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &_s {
    min-width: 48px;
    height: 40px;

    & svg {
      width: 24px;
      height: 24px;
    }
  }

  &_m {
    min-width: 56px;
    height: 48px;

    & svg {
      width: 24px;
      height: 24px;
    }
  }

  &_l {
    min-width: 64px;
    height: 56px;

    & svg {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &_default {
    background-color: var(--blue120);
    color: var(--common2);

    &:hover {
      background-color: var(--blue100);
    }

    &:disabled {
      background-color: var(--grey20);
      cursor: not-allowed;

      & svg {
        color: var(--grey100);
      }
    }
  }

  &_outline {
    color: var(--blue120);
    background-color: transparent;
    border: 1px solid var(--grey30);

    &:hover {
      border-color: var(--blue120);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
      border-color: var(--grey40);

      & svg {
        color: var(--grey80);
      }
    }
  }

  &_flat {
    border: none;
    color: var(--blue120);
    background-color: transparent;

    &:hover {
      background-color: var(--blue10);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: transparent;

      & svg {
        color: var(--grey80);
      }
    }
  }

  &:focus {
    outline: 2px solid var(--blue180);
  }
}

[data-theme|='dark'] {
  .icon-button {
    &_flat {
      color: #3392ff;

      &:disabled {
        & svg {
          color: var(--grey50);
        }
      }
    }

    &_default {
      color: var(--grey200);

      &:disabled {
        & svg {
          color: var(--common2);
        }
      }
    }
  }
}
