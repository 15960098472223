:root[data-theme|="dark"] {
  // blue dark palette
  --blue10: #1c4263;
  --blue20: #1d4a72;
  --blue30: #1c5282;
  --blue40: #1c5b92;
  --blue50: #1a63a2;
  --blue60: #186bb4;
  --blue70: #1673c5;
  --blue80: #1379d8;
  --blue90: #0f7deb;
  --blue100: #1983f5;
  --blue110: #186bb4;
  --blue120: #1673c5;
  --blue130: #479dff;
  --blue140: #5ca8ff;
  --blue150: #70b3ff;
  --blue160: #85beff;
  --blue170: #99c9ff;
  --blue180: #add3ff;
  --blue190: #c2deff;
  --blue200: #d6e9ff;
  // cyan dark palette
  --cyan10: #28525c;
  --cyan20: #2b5d69;
  --cyan30: #2d6876;
  --cyan40: #2e7384;
  --cyan50: #2f7f92;
  --cyan60: #308aa1;
  --cyan70: #3097b0;
  --cyan80: #30a3c0;
  --cyan90: #2fb0d0;
  --cyan100: #38b7d6;
  --cyan110: #308aa1;
  --cyan120: #3097b0;
  --cyan130: #63cee9;
  --cyan140: #72d5ee;
  --cyan150: #82dcf2;
  --cyan160: #93e2f6;
  --cyan170: #a4e8f9;
  --cyan180: #b6edfb;
  --cyan190: #c9f3fd;
  --cyan200: #dcf7fe;
  // purple dark palette
  --purple10: #472b69;
  --purple20: #4e2d76;
  --purple30: #552e84;
  --purple40: #5c2f92;
  --purple50: #6330a1;
  --purple60: #6a30b0;
  --purple70: #7631c4;
  --purple80: #7f37d2;
  --purple90: #8745d9;
  --purple100: #9253df;
  --purple110: #6a30b0;
  --purple120: #7631c4;
  --purple130: #b280ef;
  --purple140: #ba8cf3;
  --purple150: #c298f6;
  --purple160: #caa4f9;
  --purple170: #d3b1fb;
  --purple180: #dbbffd;
  --purple190: #e3cdfe;
  --purple200: #ebdbff;
  // red dark palette
  --red10: #5c2d28;
  --red20: #69302b;
  --red30: #76332d;
  --red40: #84362e;
  --red50: #92382f;
  --red60: #a13a30;
  --red70: #b03b30;
  --red80: #c03c30;
  --red90: #d03f2f;
  --red100: #d7493c;
  --red110: #a13a30;
  --red120: #b03b30;
  --red130: #e97267;
  --red140: #ee8177;
  --red150: #f29087;
  --red160: #f6a098;
  --red170: #f9aba4;
  --red180: #fbbcb6;
  --red190: #fdcdc9;
  --red200: #fedfdc;
  // orange dark palette
  --orange10: #613823;
  --orange20: #6f3e25;
  --orange30: #7d4326;
  --orange40: #8b4927;
  --orange50: #9a4e28;
  --orange60: #a95328;
  --orange70: #b95827;
  --orange80: #c95d26;
  --orange90: #d66224;
  --orange100: #e16d33;
  --orange110: #a95328;
  --orange120: #b95827;
  --orange130: #f09060;
  --orange140: #f49d71;
  --orange150: #f8a982;
  --orange160: #fbb693;
  --orange170: #fdbfa1;
  --orange180: #feccb3;
  --orange190: #ffdac7;
  --orange200: #ffe7db;
  // yellow dark palette
  --yellow10: #634b21;
  --yellow20: #715423;
  --yellow30: #7f5e24;
  --yellow40: #8e6725;
  --yellow50: #9d7125;
  --yellow60: #ad7b25;
  --yellow70: #bc8524;
  --yellow80: #cd8f23;
  --yellow90: #d99920;
  --yellow100: #e39e26;
  --yellow110: #ad7b25;
  --yellow120: #bc8524;
  --yellow130: #f3ba59;
  --yellow140: #f6c36a;
  --yellow150: #facd80;
  --yellow160: #fcd992;
  --yellow170: #fede9f;
  --yellow180: #ffe6b3;
  --yellow190: #ffecc7;
  --yellow200: #fff3db;
  // green dark palette
  --green10: #194326;
  --green20: #1b4b29;
  --green30: #1b552d;
  --green40: #1c5e30;
  --green50: #1d6833;
  --green60: #1d7237;
  --green70: #1d813b;
  --green80: #1d9040;
  --green90: #1ea446;
  --green100: #1eb84c;
  --green110: #1d7237;
  --green120: #1d813b;
  --green130: #28e65b;
  --green140: #46ec72;
  --green150: #60f082;
  --green160: #7bf497;
  --green170: #96f8ad;
  --green180: #b2fbc0;
  --green190: #c9fcd4;
  --green200: #dbffe2;
  // grey dark palette
  --grey10: #191a1c;
  --grey20: #313235;
  --grey30: #3c3d40;
  --grey40: #46474a;
  --grey50: #515255;
  --grey60: #5c5d5f;
  --grey70: #68686a;
  --grey80: #727274;
  --grey90: #7d7d7f;
  --grey100: #88888a;
  --grey110: #929294;
  --grey120: #9d9e9f;
  --grey130: #a8a9aa;
  --grey140: #b3b4b5;
  --grey150: #bebfc0;
  --grey160: #c8c9c9;
  --grey170: #d4d4d4;
  --grey180: #dfdfdf;
  --grey190: #e8e8e8;
  --grey200: #f2f2f2;
  // common dark palette
  --common1: #f7f7f7;
  --common2: #202225;
  // background dark palette
  --background1: #0d0f12;
  // transparent dark
  --transparent1: rgba(13, 15, 18, 0.6);
  --transparent2: rgba(13, 15, 18, 0.75);
  // box-shadows dark
  --box-shadow-lvl-1: 0 1px 15px rgba(13, 15, 18, 0.15);
  --box-shadow-lvl-2: 0 4px 20px rgba(13, 15, 18, 0.25);
  --box-shadow-lvl-3: 0 0 45px rgba(13, 15, 18, 0.25);
  --box-shadow-lvl-4: 0 0 55px rgba(13, 15, 18, 0.25);
}
