@use "./src/shared/assets/scss/variables/index.scss" as *;
.user-assistance-add-buttons {
  background-color: var(--common2);
  padding: $indent-lg;
  display: flex;
  align-items: center;
  grid-gap: $indent-sm;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;

    &__button {
      width: 100%;
    }
  }
}
