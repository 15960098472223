@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-article-drop {
  &__icon {
    &-edit {
      color: var(--blue120);
    }

    &-delete {
      color: var(--red90);
      width: 20px;
      height: 20px;
    }
  }
}
