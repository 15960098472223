:root[data-theme="dark-contrast"] {
  // grey dark-contrast palette
  --grey10: #0d0f12;
  --grey20: #46474a;
  --grey30: #5c5d5f;
  --grey40: #727274;
  --grey50: #929294;
  --grey60: #9d9e9f;
  --grey70: #9d9e9f;
  --grey80: #a8a9aa;
  --grey90: #b3b4b5;
  --grey100: #bebfc0;
  --grey110: #c8c9c9;
  --grey120: #c8c9c9;
  --grey130: #d4d4d4;
  --grey140: #dfdfdf;
  --grey150: #e8e8e8;
  --grey160: #f2f2f2;
  --grey170: #f2f2f2;
  --grey180: #f2f2f2;
  --grey200: #fff;
  //// common dark-contrast palette
  --common2: #191a1c;
  // background dark-contrast palette
  --background1: #000;
}
