@use "./src/shared/assets/scss/variables/index.scss" as *;
.controls {
  display: flex;
  height: $indent-lg;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $indent-lg;

  &__divider {
    background: var(--grey20);
    width: 1px;
    height: 20px;
    margin-right: $indent-xxs;
  }

  &__icon {
    color: var(--grey200);

    &_rotated {
      transform: rotateY(180deg);
      color: var(--grey200);
    }
  }

  &__btn {
    &_hide {
      display: none;
      color: var(--grey60);
    }

    &-wrapper {
      width: 20px;
      height: 20px;

      & button {
        line-height: 0;
      }

      & svg {
        color: var(--grey60);
        width: 20px;
        height: 20px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    justify-content: space-around;
  }
}
