@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    word-wrap: break-word;

    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__text {
    word-break: break-all;
    line-height: $indent-xl;
    color: var(--grey120);
    font-size: $sm-text;
    max-height: 540px;
    overflow: auto;
  }

  &__files {
    display: flex;
    overflow-x: auto;
    padding: $indent-xxs;
  }

  &__file {
    margin-right: $indent-md;
  }

  &__buttons-wrapper {
    display: flex;
    max-width: 865px;
    align-items: center;
    margin-top: $indent-xl;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-gap: $indent-md;
  }

  &__button-restore {
    margin-top: $indent-xl;
    width: fit-content;
  }

  &__sub-title {
    text-transform: uppercase;
    color: var(--grey120);
    font-size: $xs-text;
    font-weight: 500;
    margin-right: $indent-xl;
  }

  &__to-appoint {
    margin-left: $indent-sm;
    padding: $indent-md $indent-md;
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__select {
    width: 340px;
  }

  &__icon-inner {
    color: var(--grey100);
    width: 16px;
    height: 16px;
  }

  &__system-info {
    overflow: auto;
    display: flex;

    &-item {
      position: relative;
      align-items: center;

      &:not(:first-child) {
        padding-left: 64px;

        &::after {
          content: '';
          display: inline-block;
          width: 1px;
          height: 20px;
          background-color: var(--grey30);
          position: absolute;
          left: $indent-xxxl;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__tooltip-system {
    max-width: 280px;

    &-version {
      font-weight: 500;
      margin-bottom: $indent-xs;
    }
  }

  &__block-info {
    margin-top: $indent-xxs;
    font-size: $sm-text;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-md;
  }

  &__jira-link {
    font-size: $sm-text;
    display: inline-block;
    max-width: 276px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__similar-solutions {
    display: flex;
    align-items: center;
    margin-left: $indent-lg;
    transition: opacity $transition;

    &:hover {
      opacity: 0.8;
    }

    &-icon {
      color: var(--blue120);
      width: 20px;
      height: 20px;
    }

    &-title {
      color: var(--blue120);
      margin-left: $indent-xs;
    }
  }

  &__tickets {
    display: flex;
    gap: $indent-xs;
    flex-wrap: wrap;
  }

  &__loader-wrapper {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__ticket-attachments {
    overflow-x: auto;
  }

  @media screen and (max-width: 1350px) {
    &__buttons-wrapper {
      display: block;
    }

    &__take-in-process {
      margin-bottom: $indent-sm;
    }

    &__form {
      margin-top: $indent-sm;
    }
  }

  @media screen and (max-width: 992px) {
    &__form {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &__select {
      width: 100%;
      max-width: 300px;
    }

    &__to-appoint {
      margin-left: 0;
      margin-top: $indent-sm;
    }
  }
}
