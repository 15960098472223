@use "./src/shared/assets/scss/variables/index.scss" as *;
.current-state-form {
  &__radio {
    width: 256px;
    margin-right: $indent-sm;
  }

  &__select {
    min-width: 100px;
    max-width: 320px;

    &:not(:last-child) {
      margin-right: $indent-sm;
    }
  }

  @media screen and (max-width: 1600px) {
    &__radio {
      width: 320px;
    }
  }

  @media screen and (max-width: 768px) {
    &__radio {
      width: 100%;
      margin-right: 0;
    }

    &__select {
      min-width: auto;
      max-width: none;
      width: 100%;
      margin-right: 0;
      margin-top: 10px;
    }
  }
}
