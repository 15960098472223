@use "./src/shared/assets/scss/variables/index.scss" as *;
.icon-button {
  transition:
    background-color var(--transition-delay),
    border var(--transition-delay),
    border-color var(--transition-delay);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  user-select: none;

  $this: &;

  & svg {
    transition: color var(--transition-delay);
  }

  &_xxs {
    height: 24px;

    & svg {
      width: 16px;
      height: 16px;
    }
  }

  &_xs {
    height: 32px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &_s {
    height: 40px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &_m {
    height: 48px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &_l {
    height: 56px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &_square {
    border-radius: var(--border-radius-4);

    &#{$this} {
      &_xxs {
        min-width: 28px;
      }

      &_xs {
        min-width: 36px;
      }

      &_s {
        min-width: 48px;
      }

      &_m {
        min-width: 56px;
      }

      &_l {
        min-width: 64px;
      }
    }
  }

  &_circle {
    border-radius: 50%;

    &#{$this} {
      &_xxs {
        min-width: 24px;
      }

      &_xs {
        min-width: 32px;
      }

      &_s {
        min-width: 40px;
      }

      &_m {
        min-width: 48px;
      }

      &_l {
        min-width: 56px;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  &_default {
    &#{$this} {
      &_primary {
        background-color: var(--blue-120);
        color: var(--common-white);

        &:hover {
          background-color: var(--blue-100);
        }

        &:active,
        #{$this}_active {
          background-color: var(--blue-140);
        }
      }

      &_secondary {
        background-color: var(--grey-20);
        color: var(--grey-140);

        &:hover {
          background-color: var(--grey-30);
        }

        &:active,
        #{$this}_active {
          background-color: var(--grey-60);
        }
      }

      &_danger {
        background-color: var(--red-120);
        color: var(--common-white);

        &:hover {
          background-color: var(--red-100);
        }

        &:active,
        #{$this}_active {
          background-color: var(--red-140);
        }
      }

      &_success {
        background-color: var(--green-120);
        color: var(--common-white);

        &:hover {
          background-color: var(--green-100);
        }

        &:active,
        #{$this}_active {
          background-color: var(--green-140);
        }
      }

      &:disabled:not(#{$this}_loading) {
        background-color: var(--grey-10);

        & svg {
          color: var(--grey-60);
        }
      }
    }
  }

  &_outline {
    background-color: var(--common-white);
    border: 1px solid var(--grey-30);
    &#{$this} {
      &_primary {
        color: var(--blue-120);

        &:hover {
          border-color: var(--blue-120);
        }

        &:active,
        #{$this}_active {
          border-color: var(--blue-140);
        }
      }

      &_secondary {
        color: var(--grey-140);

        &:hover {
          border-color: var(--grey-140);
        }

        &:active,
        #{$this}_active {
          border-color: var(--grey-140);
        }
      }

      &_danger {
        color: var(--red-120);

        &:hover {
          border-color: var(--red-120);
        }

        &:active,
        #{$this}_active {
          border-color: var(--red-140);
        }
      }

      &_success {
        color: var(--green-120);

        &:hover {
          border-color: var(--green-120);
        }

        &:active,
        #{$this}_active {
          border-color: var(--green-140);
        }
      }

      &:disabled:not(#{$this}_loading) {
        border-color: var(--grey-30);

        & svg {
          color: var(--grey-60);
        }
      }
    }
  }

  &_flat {
    border: none;
    background-color: transparent;

    &#{$this} {
      &_primary {
        color: var(--blue-120);

        &:hover {
          background-color: var(--blue-10);
        }

        &:active,
        &#{$this}_active {
          background-color: var(--blue-20);
        }
      }

      &_secondary {
        color: var(--grey-140);

        &:hover {
          background-color: var(--grey-10);
        }

        &:active,
        #{$this}_active {
          background-color: var(--grey-20);
        }
      }

      &_danger {
        color: var(--red-120);

        &:hover {
          background-color: var(--red-10);
        }

        &:active,
        #{$this}_active {
          background-color: var(--red-20);
        }
      }

      &_success {
        color: var(--green-120);

        &:hover {
          background-color: var(--green-10);
        }

        &:active,
        #{$this}_active {
          background-color: var(--green-20);
        }
      }

      &:disabled:not(#{$this}_loading) {
        background-color: transparent;

        & svg {
          color: var(--grey-60);
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  // TODO: Не макет
  &:focus {
    outline: 2px solid var(--blue-180);
  }
}
