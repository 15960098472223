@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-create-category {
  grid-gap: $indent-md;

  &__buttons {
    grid-gap: $indent-xxs;
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: var(--blue120);
  }
}
