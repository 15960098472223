:root {
  --indent-4pt: 4px;
  --indent-8pt: 8px;
  --indent-12pt: 12px;
  --indent-16pt: 16px;
  --indent-20pt: 20px;
  --indent-24pt: 24px;
  --indent-28pt: 28px;
  --indent-32pt: 32px;
  --indent-36pt: 36px;
  --indent-40pt: 40px;
  --indent-44pt: 44px;
  --indent-48pt: 48px;
  --indent-52pt: 52px;
  --indent-56pt: 56px;
  --indent-60pt: 60px;
  --indent-64pt: 64px;
  --indent-68pt: 68px;
  --indent-72pt: 72px;
}
