@use "./src/shared/assets/scss/variables/index.scss" as *;
.delete-by-condition-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: $z-index-modal;
  box-shadow: var(--box-shadow-lvl-4);
  background-color: var(--transparent1);
  animation-name: appear;
  animation-duration: 300ms;

  &__content {
    width: 600px;
    position: relative;
    max-height: 90vh;
    background: var(--common2);
    display: flex;
    flex-direction: column;
    animation-name: slide-in;
    animation-duration: 0.5s;
    border-radius: $border-radius;
  }

  &__header {
    background-color: var(--grey10);
    padding: $indent-xl $indent-xl $indent-lg $indent-xl;
    border-radius: $border-radius $border-radius 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey20);
  }

  &__title {
    font-weight: 500;
    font-size: $lg-text;
    color: var(--grey200);
  }

  &__form {
    background: var(--common2);
    border-radius: $border-radius;
    padding: $indent-xl;
    overflow: auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__description {
    color: var(--grey200);
  }

  &__buttons {
    display: flex;
    margin-top: 42px;
    align-items: center;
    justify-content: flex-end;
  }

  &__cancel-button {
    margin-right: 5px;
  }

  &__cross {
    color: var(--grey100);
  }

  &__button {
    position: absolute;
    right: 30px;
  }

  @keyframes appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes slide-in {
    from {
      transform: translateY(-70px);
    }

    to {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 768px) {
    animation-name: none;

    &__content {
      width: 100%;
      height: 100%;
      max-height: none;
      border-radius: 0;
      animation-name: none;
    }

    &__form-create {
      padding: 20px;
      flex-basis: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }

    &__buttons {
      flex-direction: column;
      display: flex;
      align-items: normal;
      justify-content: flex-end;
    }

    &__cancel-button {
      margin-right: 0;
      margin-bottom: $indent-xxs;
    }

    &__header {
      justify-content: center;
      border-radius: 0;
    }

    &__button {
      display: none;
    }
  }
}
