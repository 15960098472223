@use "./src/shared/assets/scss/variables/index.scss" as *;
.modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 1rem;
  width: 100%;
  justify-content: center;

  &__table {
    display: flex;
    flex-direction: column;
    height: 438px;
    border: 1px solid var(--grey30);
    flex-basis: 100%;
    min-width: 300px;
    max-width: 800px;

    &-title {
      padding: $indent-lg;
      border-bottom: 1px solid var(--grey30);
    }

    &-content {
      flex-basis: 100%;
      margin: 15px 18px 0;
    }

    &-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-right: $indent-xxxl;
      margin: $indent-md 0;
    }
  }

  &__root {
    width: 90%;
  }

  &__pagination {
    margin-left: $indent-md;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;
  }

  &__count {
    margin: $indent-sm $indent-xl;
  }

  &__arrow {
    &_right {
      width: 14px;
      height: 14px;
      transform: rotate(-90deg);
    }

    &_left {
      width: 14px;
      height: 14px;
      transform: rotate(90deg);
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    &__root {
      width: 100%;
      max-width: none;
    }

    &__form {
      padding: $indent-md $indent-sm;
    }

    &__table {
      border: none;
      width: 100%;
      min-height: 354px;
      max-height: 924px;
      flex-basis: auto;

      &-title {
        padding: 0;
        border-bottom: none;
      }
    }

    &__count {
      display: none;
    }

    &__buttons {
      flex-direction: row-reverse;
      justify-content: center;
      width: 100%;
    }

    &__button {
      flex-basis: 45%;
    }

    &__arrow {
      &_right {
        transform: rotate(360deg);
      }

      &_left {
        transform: rotate(180deg);
      }
    }
  }
}
