@use "./src/shared/assets/scss/variables/index.scss" as *;
.avatar {
  display: flex;
  position: relative;
  border-radius: 50%;
  align-items: center;

  $this: &;

  &_xxs {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    #{$this}__text {
      font-size: $xxs-text;
      line-height: 12px;
    }
  }

  &_xs {
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    #{$this}__text {
      font-size: $xs-text;
      line-height: 15px;
    }
  }

  &_s {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    #{$this}__text {
      font-size: $md-text;
      line-height: 20px;
    }
  }

  &_m {
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    #{$this}__text {
      font-size: $lg-text;
      line-height: 24px;
    }
  }

  &_l {
    min-width: 56px;
    max-width: 56px;
    height: 56px;
    #{$this}__text {
      font-size: $xl-text;
      line-height: 30px;
    }
  }

  &_purple {
    background-color: var(--purple20);
    color: var(--purple120);
  }

  &_blue {
    background-color: var(--blue20);
    color: var(--blue120);
  }

  &_cyan {
    background-color: var(--cyan20);
    color: var(--cyan120);
  }

  &_green {
    background-color: var(--green20);
    color: var(--green120);
  }

  &_yellow {
    background-color: var(--yellow20);
    color: var(--yellow120);
  }

  &_red {
    background-color: var(--red20);
    color: var(--red120);
  }

  &_grey {
    background-color: var(--grey20);
    color: var(--grey120);
  }

  &__text {
    margin: 0 auto;
    font-weight: 500;
  }

  &__circle {
    position: absolute;
    top: 2px;
    right: 2px;
  }
}

[data-theme|='dark'] {
  .avatar {
    color: var(--grey180);
  }
}

[data-theme='light-contrast'] {
  .avatar {
    &_cyan {
      color: var(--cyan180);
    }

    &_green {
      color: var(--green180);
    }

    &_yellow {
      color: var(--yellow180);
    }

    &_red {
      color: var(--red180);
    }

    &_grey {
      color: var(--grey180);
    }
  }
}
