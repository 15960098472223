@use "./src/shared/assets/scss/variables/index.scss" as *;
.work-group-modal {
  &__form-info {
    margin: $indent-xl $indent-xl 0;
  }

  &__list-info {
    margin-bottom: $indent-sm;
  }

  &__info-icon {
    color: var(--grey100);
    width: 40px;
    height: 40px;
  }

  &__title-block {
    font-size: $md-text;
    color: var(--grey200);
  }

  @media screen and (max-width: 768px) {
    &__form-info {
      margin: 0;
    }

    &__modal-title {
      font-weight: 500;
      font-size: $xs-text;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}
