@use "./src/shared/assets/scss/variables/index.scss" as *;
.roll-up-button {
  position: relative;
  min-width: 16px;
  height: 16px;
  margin-right: 14px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 25%;
    right: 25%;
    top: 50%;
    display: block;
    width: 9px;
    height: 2px;
    transition: all 0.2s ease-out;
    background-color: var(--grey50);
    transform: translate(-2px, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(3px, -50%) rotate(-45deg);
  }

  &_up {
    &::before {
      transform: translate(-2px, -50%) rotate(-45deg);
    }

    &::after {
      transform: translate(3px, -50%) rotate(45deg);
    }
  }
}
