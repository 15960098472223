@use "./src/shared/assets/scss/variables/index.scss" as *;
.storage {
  display: flex;
  align-items: center;
  padding-left: $indent-xl;
  padding-right: $indent-xl;
  height: 74px;
  width: 100%;

  &__container {
    display: flex;
    align-items: center;
    margin-bottom: $indent-sm;
    border-radius: $border-radius;
    background-color: var(--common2);
  }

  &__icon {
    margin-right: $indent-xl;
    color: var(--grey100);
  }

  &__info {
    &-container {
      width: 250px;
      display: flex;
      flex-direction: column;
    }

    &-number {
      margin-bottom: $indent-xxs;
      color: var(--grey200);
      width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-organization {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: var(--grey120);

      &-container {
        display: flex;
        align-items: center;
        grid-gap: $indent-xs;
      }
    }

    &-used {
      width: 100px;
      margin-left: $indent-xl;
      margin-right: $indent-xl;
      color: var(--grey200);
    }
  }

  &__checkbox {
    margin-left: $indent-xl;
    margin-right: $indent-xl;

    &-container {
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid var(--grey20);
    }
  }

  &__progress-bar {
    width: 371px;
    margin-left: $indent-xl;

    &-loader {
      background-color: var(--blue120);
    }
  }

  @media screen and (max-width: 1200px) {
    &__progress-bar {
      width: 250px;
    }
  }

  @media screen and (max-width: 992px) {
    &__progress-bar {
      width: 150px;
    }

    &__info {
      &-container {
        width: 100px;
      }

      &-number {
        width: 100px;
      }
    }

    &__icon {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 $indent-sm;

    &__checkbox {
      margin: 0 $indent-sm;
    }

    &__progress-bar {
      width: 200px;
    }
  }

  @media screen and (max-width: 500px) {
    &__info {
      &-used {
        width: 50px;
        margin: 0 $indent-sm;
      }
    }

    &__progress-bar {
      width: 100%;
    }
  }
}
