@use "./src/shared/assets/scss/variables/index.scss" as *;
.environment {
  max-width: 794px;

  &__separator {
    border-top: 1px solid var(--grey20);
    margin-bottom: $indent-xl;
  }

  &__input-system {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 18px;
    margin-bottom: $indent-xl;
  }

  &__input-create {
    display: flex;
    margin-left: $indent-xxxl;
    margin-bottom: $indent-sm;
  }

  &__button-container {
    display: flex;
  }

  &__button-icon-item {
    display: flex;
    justify-content: center;
    width: 56px;
    height: 48px;
    border: 1px solid var(--grey30);
    border-radius: $border-radius-sm;
    margin-left: $indent-sm;

    &_create {
      color: var(--green120);
    }

    &_close {
      color: var(--red120);
    }

    &_delete {
      color: var(--grey80);
      width: 66px;
    }
  }

  &__environments {
    display: flex;
    margin-bottom: $indent-sm;

    &-checkbox {
      margin-right: $indent-md;
    }
  }
}
