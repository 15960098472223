@use "./src/shared/assets/scss/variables/index.scss" as *;
.interface-settings {
  width: 328px;
  display: flex;
  flex-direction: column;
  grid-gap: $indent-xl;

  &__title {
    margin-bottom: 20px;
  }

  &__switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $xs-text;
    font-weight: 400;
    grid-gap: $indent-xs;

    &-label {
      font-size: $xs-text;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 320px;
  }

  @media screen and (max-width: 768px) {
    align-self: center;
  }
}
