@use "./src/shared/assets/scss/variables/index.scss" as *;
.triggers-table-container {
  height: 100%;

  &__icon-delete {
    color: var(--red100);
  }

  @media screen and (max-width: 1200px) {
    height: 768px;
  }
}
