@use "./src/shared/assets/scss/variables/index.scss" as *;
.tickets-dashboard {
  padding: $indent-xxxl 40px;
  border-radius: $border-radius;
  min-height: 382px;
  background: var(--common2);

  &__title {
    color: var(--grey180);

    &-content {
      min-height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  }

  &__warning {
    display: flex;
    align-items: center;
    padding: $indent-xs $indent-xs;
    margin-right: $indent-xxxl;
    gap: 10px;
    border-radius: $border-radius;
    background-color: var(--blue30);

    &-icon {
      min-width: 22px;
      height: 22px;
    }
  }

  &__info {
    &-wrapper {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
    }

    &-block-wrapper {
      position: relative;
      min-width: 80px;
    }

    &-vl {
      margin-top: 40px;
      display: inline-block;
      width: 1px;
      height: 48px;
      background-color: var(--grey20);
    }

    &-block {
      height: 40px;
      width: 40px;
      border-radius: $border-radius-sm;
      display: flex;
      align-items: center;
      justify-content: center;

      &_active {
        background-color: var(--blue80);
      }

      &_expired {
        background-color: var(--red30);
      }

      &_sla {
        background-color: var(--green80);
      }

      &_solved {
        background-color: var(--green100);
      }

      &-icon {
        &_active {
          color: var(--common2);
        }

        &_expired {
          color: var(--red120);
        }

        &_sla {
          color: var(--common2);
        }

        &_solved {
          color: var(--common2);
        }
      }

      &-title {
        color: var(--grey120);
        margin-top: $indent-xxxl;
      }

      &-sub-title {
        color: var(--grey200);
        margin-top: $indent-xxs;
      }
    }
  }

  &__percent {
    display: inline-block;
  }

  &__sla {
    &-block:not(:last-child) {
      margin-bottom: $indent-xxxl;
    }

    &-title {
      color: var(--grey120);
      margin-bottom: $indent-xs;
    }

    &-progress-bar {
      width: 282px;
      background-color: transparent;

      &_loaded {
        background-color: var(--blue100);
      }

      &-wrapper {
        display: flex;
        align-items: center;
      }

      &-value {
        color: var(--grey200);
        margin-right: $indent-sm;
      }
    }
  }

  &__progress-bar-wrapper {
    margin-top: -30px;
    width: 300px;
    height: 200px;
    overflow-y: auto;
  }

  @media screen and (max-width: 1024px) {
    padding: $indent-xxxl 40px;
    min-height: auto;

    &__warning {
      margin-right: 0;
    }

    &__info {
      &-wrapper {
        flex-wrap: wrap;
        margin-top: $indent-xl;
      }

      &-vl {
        &:nth-child(6) {
          display: none;
        }
      }
    }

    &__progress-bar-wrapper {
      flex-basis: 100%;
      margin-top: $indent-xl;
      padding-top: $indent-xl;
      border-top: 1px solid var(--grey20);
    }

    &__sla {
      &-progress-bar {
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: $indent-xxxl 20px;
    border-radius: 0;

    &__info-block-wrapper {
      width: auto;
    }
  }
}

[data-theme|='dark'] {
  .tickets-dashboard {
    &__title {
      color: var(--grey200);
    }

    &__info-block {
      &-title {
        color: var(--grey200);
      }

      &-icon {
        &_active {
          color: var(--grey200);
        }

        &_expired {
          color: var(--grey200);
        }

        &_sla {
          color: var(--grey200);
        }

        &_solved {
          color: var(--grey200);
        }
      }
    }

    &__sla-title {
      color: var(--grey200);
    }
  }
}
