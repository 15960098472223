@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-table-articles-container {
  min-width: 320px;
  height: 100%;

  &__icon-delete {
    color: var(--red100);
  }

  @media screen and (max-width: 1200px) {
    height: 768px;
  }

  @media screen and (max-width: 768px) {
    min-width: 0;
    height: 354px;
  }
}
