@use "./src/shared/assets/scss/variables/index.scss" as *;
.sla-patterns-list {
  overflow-y: scroll;
  height: 210px;

  &__data-empty {
    display: flex;
    height: 100%;
  }

  &__item {
    border-bottom: 1px solid var(--grey20);
    padding: $indent-sm;
    transition: background-color $transition;

    $this: &;

    &-icon {
      color: var(--blue120);
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &:hover {
      background-color: var(--grey10);
      transition: background-color $transition;
      cursor: pointer;
    }

    &_active {
      background-color: var(--blue20);
      transition: background-color $transition;
      #{$this}-title {
        color: var(--blue120);
        transition: color $transition;

        &-time {
          color: var(--blue80);
          transition: color $transition;
        }
      }

      &:hover {
        background-color: var(--blue20);
      }
    }

    &-title {
      display: flex;
      flex-direction: column;

      &-time {
        color: var(--grey100);
      }
    }

    &-btn {
      &_disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
}
