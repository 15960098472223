@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-article {
  max-height: 100vh;
  overflow-y: hidden;

  &__header-icon {
    width: 20px;
    height: 20px;
    color: var(--grey100);
  }

  &__title {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media screen and (max-width: 1200px) {
    max-height: none;
  }
}
