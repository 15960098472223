@use "./src/shared/assets/scss/variables/index.scss" as *;
.cell {
  display: table-cell;
  min-width: 30px;
  vertical-align: middle;
  font-size: $xs-text;
  line-height: 15px;
  padding-bottom: 10px;
  padding-top: $indent-sm;
}
