/* z-index Variables */
$z-index-10: 10;
$z-index-20: 20;

/* z-index Component */
$z-index-filter: 700;
$z-index-action-button: 800;
$z-index-sticky-block: 850;
$z-index-tob-bar: 860;
$z-index-navigations: 870;
$z-index-skeleton: 880;
$z-index-table-loader: 900;
$z-index-progress-line: 910;
$z-index-progress-thumb: 920;
$z-index-menu: 930;
$z-index-drawer: 950;
$z-index-dropdown: 1000;
$z-index-popover: 1050;
$z-index-notification: 1070;
$z-index-alert: 1080;
$z-index-modal: 1090;
$z-index-drawer-inner: 1100;
$z-index-popover-inner: 1120;
$z-index-modal-portal: 1140;
$z-index-modal-higher: 1150;
