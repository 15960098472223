@use "./src/shared/assets/scss/variables/index.scss" as *;
.similar-ticket-content {
  padding: $indent-xl;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &__tag {
    margin-bottom: $indent-sm;
  }

  &__title {
    color: var(--grey200);
    margin-bottom: $indent-sm;
  }

  &__description {
    color: var(--grey120);
    font-size: $sm-text;
    line-height: 23px;
    font-weight: 400;
    margin-bottom: $indent-md;
  }

  &__attachments {
    display: flex;
    overflow-x: auto;
    padding-bottom: $indent-xxs;
    margin-bottom: $indent-md;

    &-item:not(:last-child) {
      margin-right: $indent-sm;
    }
  }

  @media screen and (max-width: 768px) {
    padding: $indent-md $indent-lg;

    &__title {
      font-size: $sm-text;
    }

    &__description {
      font-size: $xs-text;
    }
  }
}
