@use "./src/shared/assets/scss/variables/index.scss" as *;
.tabs-item {
  display: inline-block;
  margin-left: $indent-xxxl;
  color: var(--grey100);
  padding-bottom: $indent-xs;
  transition: color, border-bottom-color, $transition;
  white-space: nowrap;
  position: relative;
  border-bottom: 2px solid transparent;
  user-select: none;
  line-height: 20px;

  $this: &;

  &:first-child {
    margin-left: 0;
  }

  &_14 {
    font-size: $sm-text;
  }

  &_16 {
    font-size: $md-text;
  }

  &_with-content {
    padding-right: $indent-xxxl;
  }

  &_unchecked {
    position: absolute;
    top: 5px;
    right: -5px;
  }

  &:hover,
  &:focus {
    &:not(#{$this}_disabled) {
      color: var(--common1);
    }
  }

  &_active {
    font-weight: 500;
    color: var(--common1);
    border-bottom-color: var(--blue120);

    &:hover,
    &:focus {
      &:not(#{$this}_disabled) {
        border-bottom-color: var(--blue100);
      }
    }
  }

  &_disabled {
    cursor: not-allowed;
    color: var(--grey50);
    &#{$this}_active {
      color: var(--grey50);
      border-bottom-color: var(--grey50);
    }
  }

  @media screen and (max-width: 1024px) {
    margin-left: $indent-sm;
  }

  @media screen and (max-width: 768px) {
    margin-left: $indent-sm;
    font-size: $sm-text;

    &_with-content {
      padding-right: $indent-xl;
    }
  }
}
