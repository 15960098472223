@use "./src/shared/assets/scss/variables/index.scss" as *;
.contract-sign {
  &__btn {
    min-height: 32px;
    padding: $indent-xs;
  }

  &__block {
    background-color: var(--purple20);
    color: var(--purple120);
  }

  @media screen and (max-width: 768px) {
    &__btn {
      width: 100%;
    }
  }
}
