@use "./src/shared/assets/scss/variables/index.scss" as *;
.tickets-table-container {
  min-width: 300px;

  &__number-el {
    position: absolute;
    top: -9px;
    right: 0;
  }

  &__sticky-wrapper {
    display: none;
  }

  &__create-button {
    display: none;
    color: var(--common2);
  }

  &__icon-delete {
    color: var(--red100);
  }

  &__title_from-desktop {
    max-width: none;
  }

  @media screen and (max-width: 768px) {
    min-width: 0;

    &__header {
      min-height: auto;

      &_from-org {
        min-height: 58px;
      }

      &_from-desktop {
        min-height: 58px;
      }
    }

    &__sticky-wrapper {
      visibility: hidden;
      display: flex;
      position: absolute;
      top: 0;
      right: $indent-xl;
      bottom: 76px;
    }

    &__create-button {
      align-self: flex-end;
      position: sticky;
      bottom: 86px;
      z-index: $z-index-action-button;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: var(--blue120);
      visibility: initial;
    }
  }
}
