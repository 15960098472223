@use "./src/shared/assets/scss/variables/index.scss" as *;
.desktop-history {
  display: flex;
  grid-gap: $indent-sm;

  &__chart {
    width: auto;
  }

  &__left-container {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;
    flex-basis: 50%;
  }

  &__table {
    flex-basis: 50%;
    height: 900px;
    min-width: 600px;
  }

  &__title {
    margin-right: $indent-sm;
    font-weight: 500;
    font-size: $md-text;
    color: var(--grey200);

    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (max-width: 1500px) {
    &__left-container {
      flex-basis: 60%;
    }

    &__table {
      flex-basis: 40%;
      min-width: 400px;
    }
  }

  @media screen and (max-width: 1400px) {
    flex-direction: column;

    &__left-container {
      flex-basis: auto;
    }

    &__table {
      flex-basis: auto;
      height: 540px;
    }
  }

  @media screen and (max-width: 768px) {
    grid-gap: 0;

    &__left-container {
      grid-gap: 0;
    }

    &__table {
      height: 360px;
      min-width: 300px;
    }

    &__title {
      font-size: $sm-text;
    }
  }
}
