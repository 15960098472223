@use "./src/shared/assets/scss/variables/index.scss" as *;
.spin {
  display: flex;
  justify-content: center;
  align-items: center;

  &__circle {
    --size: 54px;

    width: var(--size);
    height: var(--size);
    border-width: 2.5px;
    border-style: solid;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;

    $this: &;

    &_basic {
      border-color: transparent;
      border-top-color: var(--blue-120);
    }

    &_default {
      &#{$this} {
        &_primary {
          border-color: var(--blue-60);
        }

        &_secondary {
          border-color: var(--grey-60);
        }

        &_danger {
          border-color: var(--red-60);
        }

        &_success {
          border-color: var(--green-60);
        }

        &_primary,
        &_secondary,
        &_danger,
        &_success {
          border-top-color: var(--common-white);
        }
      }
    }

    &_outline {
      &#{$this} {
        &_primary {
          border-color: var(--blue-30);
          border-top-color: var(--blue-120);
        }

        &_secondary {
          border-color: var(--grey-30);
          border-top-color: var(--grey-120);
        }

        &_danger {
          border-color: var(--red-30);
          border-top-color: var(--red-120);
        }

        &_success {
          border-color: var(--green-30);
          border-top-color: var(--green-120);
        }
      }
    }

    &_xxs {
      --size: 16px;
    }

    &_xs {
      --size: 20px;
    }

    &_s {
      --size: 30px;
    }

    &_m {
      --size: 38px;
    }

    &_l {
      --size: 46px;
    }

    &_xl {
      --size: 54px;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
