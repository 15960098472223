@use "./src/shared/assets/scss/variables/index.scss" as *;
.notification-toast {
  box-shadow: var(--box-shadow-lvl-3);
  border-radius: $border-radius;
  background: var(--common2);
  padding: $indent-sm $indent-md $indent-sm $indent-xl;
  width: 380px;
  max-width: 380px;

  &__text {
    max-width: 272px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
  }
}
