@use "./src/shared/assets/scss/variables/index.scss" as *;
.export-submit {
  width: 473px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__all {
    width: 273px;
    text-align: center;
    color: var(--grey100);
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__list-round-icon {
    margin-top: 95px;
    width: 60px;
    height: 60px;
  }

  &__select-text {
    margin-top: $indent-xl;
    color: var(--grey100);
  }

  &__quantity-tickets {
    margin-top: $indent-sm;
    color: var(--grey200);
  }

  &__export-button {
    margin-top: $indent-lg;
    width: 181px;
    height: 40px;
  }

  &__search-button {
    margin-top: $indent-lg;
    width: 181px;
    height: 40px;
    background-color: var(--common2);
  }

  &__cancel-button {
    margin-top: $indent-sm;
    width: 181px;
  }

  &__loader {
    position: absolute;
    width: 40px;
    height: 40px;
    margin-top: $indent-xxxl;
  }

  @media screen and (max-width: 1200px) {
    width: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    width: 100%;
    overflow: visible;

    &__all {
      display: none;
    }

    &__list-round-icon {
      margin-top: $indent-md;
    }

    &__export-button {
      position: fixed;
      bottom: 20px;
      z-index: $z-index-action-button;
    }

    &__search-button {
      position: fixed;
      bottom: 20px;
      z-index: $z-index-action-button;
    }

    &__loader {
      margin-top: -40px;
    }

    &__cancel-button {
      margin-bottom: 52px;
    }
  }
}
