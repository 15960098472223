@use "./src/shared/assets/scss/variables/index.scss" as *;
.tooltip {
  padding: $indent-xxs $indent-xs !important;
  border-radius: $border-radius-sm !important;
  font-weight: 400 !important;
  font-size: $sm-text !important;
  color: var(--common2) !important;
  max-width: 80vw;
  text-transform: none;

  &::before {
    display: none;
  }
}

[data-theme|='dark'] {
  .tooltip {
    color: var(--grey200) !important;
  }
}
