@use "./src/shared/assets/scss/variables/index.scss" as *;
.systems-table-container {
  &_full {
    height: 100%;
  }

  &_from-contracts {
    height: 442px;
  }

  &__icon-delete {
    color: var(--red100);
  }

  @media screen and (max-width: 1200px) {
    &_full {
      height: 768px;
    }
  }

  @media screen and (max-width: 768px) {
    &_from-contracts {
      height: 354px;
    }

    &_full {
      height: 354px;
    }
  }
}
