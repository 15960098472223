@use "./src/shared/assets/scss/variables/index.scss" as *;
.employees-sla-timer {
  padding: $indent-md $indent-xxxl;
  background-color: var(--common2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 12px;

  &__left-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__clock {
    color: var(--orange120);

    &_decision {
      color: var(--blue120);
    }
  }

  &__icon-wrapper {
    background-color: var(--orange20);

    &_decision {
      background-color: var(--blue20);
    }
  }

  &__tag {
    margin-top: $indent-xl;

    &_decision {
      background-color: var(--blue20);
      color: var(--blue120);
    }

    &_reaction {
      background-color: var(--orange20);
      color: var(--orange120);
    }
  }

  &__title {
    color: var(--grey100);
    margin-bottom: 6px;
  }

  &__time-wrapper {
    display: flex;
    font-size: 28px;
    font-weight: 500;

    &_expired {
      font-size: 18px;
    }
  }

  &__separator {
    padding: 0 $indent-xs;

    &_expired {
      padding: 0 6px;
    }
  }

  &__hr {
    border: 1px solid var(--grey20);
    width: 190px;
    margin: $indent-lg 0;
  }

  @media screen and (max-width: 768px) {
    padding: $indent-md $indent-xl;
  }
}

[data-theme|='dark'] {
  .employees-sla-timer {
    &__clock {
      color: var(--grey180);

      &_decision {
        color: var(--grey180);
      }
    }
  }
}

[data-theme='light-contrast'] {
  .employees-sla-timer {
    &__tag {
      &_decision {
        color: var(--blue180);
      }

      &_reaction {
        color: var(--orange180);
      }
    }
  }
}
