@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-articles-search {
  min-height: 40px;
  padding: 0 $indent-md;
  width: 100%;
  color: var(--grey200);

  &:hover {
    background-color: var(--grey10);
  }

  &__tooltip {
    position: absolute !important;
    top: 10px !important;
    left: 30px !important;
  }
}
