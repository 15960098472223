@use "./src/shared/assets/scss/variables/index.scss" as *;
.menu {
  display: block;
  border: none;

  &__list {
    padding: 0;
    margin: 0;
    overscroll-behavior: none;
    display: block;
    position: absolute;
    max-height: 0;
    transform: translateY(100%);
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 200px;
    max-width: 400px;
    background-color: var(--common2);
    transition: max-height 0.2s ease-in, padding 0.2s ease-in, overflow 1.5s ease-in,;
    overflow: hidden;
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: var(--box-shadow-lvl-2);
    z-index: $z-index-menu;

    &_open {
      overflow: auto;
      padding: $indent-xs 0;
      transition: max-height 0.2s ease-in, padding 0.2s ease-in, overflow 1.5s ease-in;

      &_xs {
        max-height: 176px;
      }

      &_s {
        max-height: 216px;
      }

      &_m {
        max-height: 256px;
      }

      &_l {
        max-height: 296px;
      }
    }
  }
}
