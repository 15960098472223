@use "./src/shared/assets/scss/variables/index.scss" as *;
.users-add-to-contract {
  &__left-table {
    width: 763px;
  }

  &__icon {
    min-width: 20px;
    width: 20px;
    color: var(--grey80);

    &_blue {
      color: var(--blue120);
    }
  }

  @media screen and (max-width: 768px) {
    &__left-table {
      width: 100%;
    }
  }
}
