@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: $indent-xs;
  padding: $indent-xs $indent-md;
  border-radius: 60px;
  border: 1px solid var(--grey20);
  font-size: $sm-text;

  &__title {
    color: var(--grey200);
  }

  &:hover {
    background: var(--grey10);
    cursor: pointer;
  }

  &:active {
    background: var(--grey20);
  }

  &:focus {
    border: 1px solid var(--blue180);
  }

  &__close {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--grey20);
    color: var(--grey100);

    &:hover {
      opacity: 0.8;
    }

    &-icon {
      width: 10px;
      height: 10px;
    }
  }
}
