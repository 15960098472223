@use "./src/shared/assets/scss/variables/index.scss" as *;
.tickets-from-desktop-table {
  &__cell {
    white-space: nowrap;
    display: flex;
    align-items: center;

    &-item {
      margin-right: 8px;
    }
  }

  &__sla-cell {
    display: flex;
    align-items: center;
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $indent-xs;
    margin-left: $indent-sm;
    padding: $indent-xxs $indent-sm;

    &-icon {
      width: 11px;
      margin-right: 3px;
    }

    &_decision {
      background-color: var(--blue20);
      color: var(--blue120);
    }

    &_reaction {
      background-color: var(--orange20);
      color: var(--orange120);
    }
  }

  &__round-clock {
    padding: 2px $indent-xs;
    border-radius: 2px;
    display: flex;
    align-items: center;
    font-size: $sm-text;
    font-weight: 500;
  }
}

[data-theme|='dark'] {
  .tickets-from-desktop-table {
    &__round-clock {
      color: var(--grey180);
    }
  }
}

[data-theme='light-contrast'] {
  .tickets-from-desktop-table {
    &__tag {
      &_decision {
        color: var(--blue180);
      }

      &_reaction {
        color: var(--orange180);
      }
    }
  }
}
