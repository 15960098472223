@use "./src/shared/assets/scss/variables/index.scss" as *;
.radio-colors {
  display: flex;
  width: 100%;
  flex-direction: row;
  grid-gap: 10px;

  $this: &;

  &__label {
    position: relative;

    &_disabled {
      cursor: not-allowed;
    }
  }

  &__item {
    height: 40px;
    min-width: 40px;
    border: 1px solid var(--grey20);
    border-radius: $border-radius-sm;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color $transition;

    & span {
      height: 12px;
      min-width: 12px;
      display: inline-block;
      border-radius: $border-radius-xs;
    }
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:not(:disabled) {
      &:not(:checked) + #{$this}__item:hover {
        cursor: pointer;
        border-color: var(--grey70);
      }
    }

    &:checked + #{$this}__item {
      border: 2px solid var(--blue120);
    }

    &:focus {
      & #{$this}__item {
        border: 2px solid var(--blue120);
      }

      &:not(:checked) #{$this}__item {
        border: 2px solid var(--blue120);
      }
    }

    &:disabled {
      & +  #{$this}__item {
        background-color: var(--grey10);
      }
    }
  }

  @media screen and (max-width: 768px) {
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
}
