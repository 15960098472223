@use "./src/shared/assets/scss/variables/index.scss" as *;
.custom-fields-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: $indent-lg;

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: $indent-md;
  }

  &__button {
    &_delete {
      color: var(--gray140);
    }
  }
}
