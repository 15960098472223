@use "./src/shared/assets/scss/variables/index.scss" as *;
.actions-table-container {
  min-width: 320px;
  height: 382px;

  @media screen and (max-width: 768px) {
    height: 354px;
    min-width: 0;
  }
}
