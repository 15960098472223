// TODO feature/tsp-2515 - sass warnings
@use '../variables' as *;

:root[data-theme] {
  --ck-custom-background: var(--common2);
  --ck-color-text: var(--grey200);
  --ck-color-base-background: var(--common2);
  --ck-color-base-border: var(--grey30);
  --ck-color-button-on-color: var(--blue120);
  --ck-focus-ring: 1px solid var(--blue120);
  --ck-color-button-default-hover-background: var(--grey50);
  --ck-color-split-button-hover-background: var(--grey10);
  --ck-color-button-on-background: var(--grey10);
  --ck-color-button-on-hover-background: var(--blue20);
  --ck-z-modal: calc(var(--ck-z-default) + 9999);
  --ck-image-style-spacing: 1.5em;
}

:global {
  .ck-editor__editable {
    min-height: 500px;
  }

  .ck-toolbar {
    background-color: var(--common1);
  }

  .ck.ck-editor,
  .ck-editor__main,
  .ck-content {
    ol,
    ul {
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 20px;
    }

    img {
      border-radius: $border-radius;
    }
  }
}

[data-theme|='dark'] {
  :global {
    .ck.ck-editor,
    .ck-editor__main,
    .ck-content {
      span {
        color: var(--grey200) !important;
        background-color: transparent !important;
      }
    }
  }
}
