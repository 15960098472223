@use "./src/shared/assets/scss/variables/index.scss" as *;
.button-group {
  display: flex;
  align-items: center;

  &_gap-xxs {
    gap: $indent-xxs;
  }

  &_gap-xs {
    gap: $indent-xs;
  }

  &_gap-sm {
    gap: $indent-sm;
  }

  &_gap-md {
    gap: $indent-md;
  }

  &_gap-lg {
    gap: $indent-lg;
  }

  &_gap-xl {
    gap: $indent-xl;
  }

  &_gap-xxl {
    gap: $indent-xxl;
  }

  &_gap-xxxl {
    gap: $indent-xxxl;
  }
}
