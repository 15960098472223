@use "./src/shared/assets/scss/variables/index.scss" as *;
.similar-article-content {
  padding: $indent-xl;
  overflow-y: auto;
  color: var(--grey120);
  font-size: $sm-text;
  font-weight: 400;
  line-height: 18px;

  @media screen and (max-width: 768px) {
    padding: $indent-md $indent-lg;
  }
}
