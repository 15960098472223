@use "./src/shared/assets/scss/variables/index.scss" as *;
.responsibility-create {
  &__form {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-xs;
  }

  &__accordion {
    &-content {
      display: flex;
      flex-direction: column;
    }

    &-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 7px 17px 7px $indent-xxxl;
    }
  }
}
