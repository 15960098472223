@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integrations-priorities {
  &__text {
    color: var(--grey200);
  }

  &__content {
    padding-top: $indent-md;
  }

  @media screen and (max-width: 1400px) {
    &__priority-block {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 48%;
    }
  }

  @media screen and (max-width: 576px) {
    &__priority-block {
      max-width: 40%;
    }
  }

  @media screen and (max-width: 360px) {
    &__priority-block {
      max-width: 34%;
    }
  }
}
