@use "./src/shared/assets/scss/variables/index.scss" as *;
.contract-status {
  &::before {
    display: inline-block;
    content: "";
    width: 4px;
    height: 20px;
    border-radius: $border-radius-sm;
    margin-right: 10px;
    vertical-align: middle;
  }

  &_active {
    &::before {
      background-color: var(--green120);
    }
  }

  &_closed {
    &::before {
      background-color: var(--grey50);
    }
  }

  &_planned {
    &::before {
      background-color: var(--blue120);
    }
  }

  &_not-signed {
    &::before {
      background-color: var(--yellow120);
    }
  }
}
