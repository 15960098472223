@use "./src/shared/assets/scss/variables/index.scss" as *;
.delete-button {
  box-shadow: var(--box-shadow-lvl-2);
  border-radius: $border-radius-sm;
  background-color: var(--common2);

  &_without-shadow {
    box-shadow: none;
  }

  &__icon {
    color: var(--red100);
    width: 20px;
    height: 20px;

    &_disabled {
      color: inherit;
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: flex-start;

    &__wrapper {
      padding: 24px;
    }
  }
}
