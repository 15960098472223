@use "./src/shared/assets/scss/variables/index.scss" as *;
.responsible-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $indent-md;
  background-color: var(--common2);
  border-radius: $border-radius;

  &__name {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: $indent-sm;
    font-size: $sm-text;
  }

  &__personal-data {
    position: relative;
    font-size: $sm-text;
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;
    width: 195px;
    overflow-x: hidden;
    padding-left: 24px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: var(--grey20);
    }

    &-icon {
      width: 20px;
      min-width: 20px;
      color: var(--grey80);
    }
  }
}
