@use "./src/shared/assets/scss/variables/index.scss" as *;
.storage-full-table {
  &__block {
    display: flex;
    align-items: center;
    grid-gap: $indent-xs;
  }

  &__icon {
    color: var(--grey80);
    width: 20px;
    height: 20px;
  }

  &__text {
    color: var(--grey160);

    &-decoration {
      color: var(--grey160);
      text-decoration-line: underline;
      text-decoration-color: var(--grey160);
    }
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    background-color: var(--grey20);
    border-radius: 50%;

    &-text {
      color: var(--grey140);
    }
  }

  &__additional {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    background: var(--grey20);
    letter-spacing: 1px;
    border-radius: $border-radius-xs;

    &-text {
      padding: 0 $indent-sm;
      color: var(--grey100);
    }
  }
}
