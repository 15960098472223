@use "./src/shared/assets/scss/variables/index.scss" as *;
.tickets {
  display: flex;
  flex-basis: 100%;
  grid-gap: $indent-sm;
  overflow: auto;

  &__tickets-table-container {
    flex-basis: 74%;
    min-width: 300px;
    height: 100%;
  }

  &__create-button {
    display: none;
  }

  &__current-ticket {
    height: 100%;
    flex-basis: 26%;
    overflow-y: auto;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;

    &__tickets-table-container {
      flex-basis: 65%;
      min-width: 300px;
      height: 60%;
    }

    &__current-ticket {
      width: 100%;
      flex-basis: 35%;
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__current-ticket {
      display: none;
    }

    &__tickets-table-container {
      flex-grow: 1;
      height: 100%;
      border-radius: 0;
      min-height: auto;
      border-bottom: none;
    }
  }
}
