@use "./src/shared/assets/scss/variables/index.scss" as *;
.contract {
  display: flex;
  justify-content: space-between;
  grid-gap: $indent-sm;

  &__subscribe {
    display: flex;
    justify-content: center;
    margin: $indent-sm 18px;
  }

  &__left-container {
    width: calc(65% - 12px);
    min-width: 300px;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;
  }

  &__right-container {
    width: 35%;
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;
  }

  &__loader {
    height: 100%;
  }

  @media screen and (max-width: 1700px) {
    &__left-container {
      width: calc(60% - 12px);
    }

    &__right-container {
      width: 40%;
    }
  }

  @media screen and (max-width: 1500px) {
    &__left-container {
      width: calc(55% - 12px);
    }

    &__right-container {
      width: 45%;
    }
  }

  @media screen and (max-width: 1200px) {
    display: block;

    &__right-container {
      height: auto;
      width: 100%;
      margin-top: $indent-sm;
    }

    &__left-container {
      height: auto;
      width: 100%;
      min-height: 0;
    }
  }

  @media screen and (max-width: 768px) {
    min-height: auto;

    &__left-container {
      min-width: 0;
      border-radius: 0;
      grid-gap: 0;
    }

    &__right-container {
      grid-gap: 0;
      margin-top: 0;
    }
  }
}

[data-theme='light-contrast'] {
  .contract {
    &__auto-close {
      color: var(--purple180);
    }
  }
}
