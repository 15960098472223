@use "./src/shared/assets/scss/variables/index.scss" as *;
.bottom-buttons-block {
  display: flex;
  padding: 0;
  overflow: hidden;
  box-shadow: var(--box-shadow-lvl-3);
  height: 0;
  max-height: 0;
  opacity: 0;
  transition: opacity $transition;

  &_open {
    padding: $indent-xl;
    opacity: 1;
    height: 88px;
    max-height: none;
    margin-top: $indent-sm;
  }

  &__submit-button {
    margin-left: $indent-lg;
  }

  @media screen and (max-width: 768px) {
    right: 0;
    margin: auto;
    left: 0;
    background-color: var(--common2);
    border-radius: $border-radius-sm;
    padding: $indent-sm;
    bottom: 86px;
    width: fit-content;
    justify-content: center;
    align-items: center;
    max-height: none;
    height: fit-content;
    opacity: 1;

    &__submit-button {
      margin-left: $indent-md;
    }
  }
}
