@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-layout {
  &__header-priority {
    &_none {
      border-color: transparent;
    }

    &_low {
      border-color: var(--green120);
    }

    &_middle {
      border-color: var(--yellow90);
    }

    &_high {
      border-color: var(--orange110);
    }

    &_very-high {
      border-color: var(--red130);
    }
  }

  &__ticket-info-item {
    &_new {
      background-color: var(--orange110);
    }

    &_in-process {
      background-color: var(--blue120);
    }

    &_closed {
      background-color: var(--green100);
    }

    &_waiting-to-close {
      background-color: var(--green50);
    }

    &_waiting-info {
      background-color: var(--cyan110);
    }

    &_reopen {
      background-color: var(--orange90);
    }
  }

  &__info {
    display: flex;
    align-items: center;

    &-group {
      color: var(--blue120);
      margin-left: 6px;
      font-size: $md-text;
      transition: opacity $transition;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__ticket-info-icon {
    width: $indent-lg;
    color: var(--grey100);
  }

  &__user-icon {
    width: $indent-md;
  }

  &__ticket-status-icon {
    color: var(--common2);
  }

  &__icon-delete {
    color: var(--red100);
  }

  &__sub-title {
    display: none;
  }

  &__label {
    height: 20px;
    padding: 0 $indent-xs;
    border-radius: 2px;
    display: flex;
    align-items: center;
    background-color: var(--red20);
    color: var(--red120);
    margin-bottom: $indent-sm;
  }

  @media screen and (max-width: 768px) {
    &__info {
      &-group {
        font-size: $sm-text;
      }
    }

    &__label {
      margin-bottom: 0;
    }
  }
}

[data-theme|='dark'] {
  .ticket-layout__ticket-status-icon {
    color: var(--grey200);
  }
}
