@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integrations {
  display: flex;
  flex-direction: row;
  min-height: auto;
  max-height: none;
  flex-basis: 100%;
  overflow: hidden;

  &__sub-tables {
    &-wrapper {
      height: 100%;
    }
  }

  @media screen and (max-width: 1200px) {
    display: block;
  }

  @media screen and (max-width: 768px) {
    min-height: auto;
    height: 100%;
  }
}
