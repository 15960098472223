@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-type-editor {
  &-icon {
    color: var(--grey100);
    width: 24px;
    height: 24px;
  }

  &__tag {
    margin-left: $indent-xl;
  }
}
