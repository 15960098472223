@use "./src/shared/assets/scss/variables/index.scss" as *;
.top-bar {
  background-color: var(--background1);
  position: sticky;
  z-index: $z-index-tob-bar;
  top: 0;
  min-height: 40px;
  margin-top: $indent-sm;
  padding: 0 $indent-xl 0 $indent-xxxl;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color $transition;

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    grid-gap: $indent-xxs;
  }

  &__button {
    width: 36px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    color: var(--grey140);
    position: relative;
    border-radius: $border-radius-sm;
    transition: background-color $transition;
    overflow: hidden;

    &:hover {
      background-color: var(--grey10);
    }
  }

  &__profile-button {
    color: var(--grey200);
    font-weight: 500;
    font-size: $md-text;
    padding: 0 $indent-xs;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: $border-radius-sm;
    transition: background-color $transition;

    &:hover {
      background-color: var(--grey10);
    }
  }

  &__popover {
    &-icon {
      color: var(--grey80);
    }

    &-container {
      min-width: auto;
    }
  }

  &__notification {
    position: absolute;
    bottom: 6px;
    right: 8px;

    &_blue {
      background-color: var(--blue100);

      &::after,
      &::before {
        background-color: var(--blue100);
      }
    }

    &_yellow {
      background-color: var(--yellow120);

      &::after,
      &::before {
        background-color: var(--yellow120);
      }
    }
  }

  &__notifications-block {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: -384px;
    top: 0;
    width: 384px;
    transition: right $transition;

    &_show {
      right: 0;
      transition: right $transition;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
