:root {
  --line-height-12: 12px;
  --line-height-14: 14px;
  --line-height-16: 16px;
  --line-height-18: 18px;
  --line-height-21: 21px;
  --line-height-22: 22px;
  --line-height-23: 23px;
  --line-height-25: 25px;
  --line-height-30: 30px;
  --line-height-35: 35px;
}
