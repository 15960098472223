@use "./src/shared/assets/scss/variables/index.scss" as *;
.sla {
  display: flex;
  flex-direction: column;
  row-gap: $indent-xl;
  padding: 0 $indent-xl;

  &__container {
    display: flex;
    align-items: center;
  }

  &__opening-hours-block {
    width: 100%;
    display: flex;
    margin-right: $indent-sm;

    &-info {
      display: flex;
      width: 30%;
      align-items: center;
      z-index: $z-index-10;
      font-weight: 400;
      font-size: $sm-text;
    }

    &-icon {
      margin-right: $indent-sm;
      width: 20px;
      height: 20px;
      color: var(--grey50);
    }
  }

  &__inputs-block {
    width: 100%;
    display: flex;
    margin-right: $indent-sm;

    &-item:first-child {
      margin-right: $indent-xs;
    }

    &-item {
      z-index: $z-index-10;
      width: 100%;

      & [type='time']::-webkit-calendar-picker-indicator {
        background: none;
        display: none;
      }
    }
  }

  &__ticket-type {
    display: flex;
    flex-direction: column;
    row-gap: $indent-sm;
  }

  &__switches {
    display: flex;
    gap: $indent-xl;
    flex-wrap: wrap;
  }
}
