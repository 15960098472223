:root[data-theme="light-contrast"] {
  // blue light-contrast palette
  --blue10: #d6ecff;
  --blue20: #bfe1ff;
  --blue30: #a8d4ff;
  --blue70: #3392ff;
  --blue100: #006bc9;
  --blue110: #006bc9;
  --blue120: #0062b8;
  // cyan light-contrast palette
  --cyan120: #00779e;
  // purple light-contrast palette
  --purple80: #873ff3;
  --purple100: #7232cc;
  --purple120: #642bb3;
  // red light-contrast palette
  --red20: #ffc7c2;
  --red120: #b80f00;
  // orange light-contrast palette
  --orange20: #ffd9ba;
  --orange90: #f56600;
  --orange120: #d65200;
  // green light-contrast palette
  --green10: #d7f7dd;
  --green20: #b2eec0;
  --green30: #97e6ac;
  --green50: #25bb52;
  --green80: #00a832;
  --green100: #008a29;
  --green120: #007523;
  // grey light-contrast palette
  --grey10: #e9edf2;
  --grey20: #cdd4da;
  --grey30: #b3bcc6;
  --grey40: #99a5b3;
  --grey50: #6a7a88;
  --grey60: #62717e;
  --grey70: #62717e;
  --grey80: #596774;
  --grey90: #515e6a;
  --grey100: #46535e;
  --grey110: #3f4b55;
  --grey120: #3f4b55;
  --grey130: #37424b;
  --grey140: #303940;
  --grey150: #282f36;
  --grey160: #21272c;
  --grey170: #21272c;
  --grey180: #21272c;
  --grey200: #000;
  // background light-contrast palette
  --background1: #bec3cc;
}
