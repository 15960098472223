@use "./src/shared/assets/scss/variables/index.scss" as *;
.table-head-cell {
  white-space: nowrap;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  padding: 0 $indent-sm;
  font-weight: 500;
  font-size: $sm-text;
  line-height: 18px;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 40px;

  &:not(:last-child) {
    width: 10%;
  }

  &_sorted-field {
    &:hover {
      cursor: pointer;
    }
  }

  &_sort {
    position: relative;
    transition: opacity $transition;

    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      margin-top: 2px;
      transform: translateY(-50%) rotate(180deg);
      border-width: 0 4px 4px;
      border-style: solid;
      border-color: var(--grey140) transparent;
      transition: transform $transition;
    }

    &:hover::after {
      cursor: pointer;
      opacity: 0.6;
    }
  }

  &_asc {
    &::after {
      transform: translateY(-50%) rotate(0deg);
    }
  }

  &_desc {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &_disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: $xs-text;
  }
}
