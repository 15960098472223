@use "./src/shared/assets/scss/variables/index.scss" as *;
.tickets-export {
  height: 100vh;
  width: 100%;

  &__container {
    background-color: var(--common2);
    box-shadow: var(--box-shadow-lvl-2);
    border-radius: $border-radius 0 0 $border-radius;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: $indent-xl;
    height: 65px;
    background-color: var(--grey10);
    border-radius: $border-radius 0 0 0;

    &-title {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      justify-content: center;
      color: var(--grey200);
    }

    &-close {
      &-button {
        background-color: var(--grey10);

        &:hover {
          background-color: var(--grey10);
        }

        &-mobile {
          background-color: var(--grey10);
          display: none;

          &:hover {
            background-color: var(--grey10);
          }
        }
      }

      &-icon {
        width: 20px;
        height: 20px;
        color: var(--grey100);

        &:hover {
          background-color: var(--grey100);
        }

        &-mobile {
          width: 20px;
          height: 20px;
          color: var(--grey100);

          &:hover {
            background-color: var(--grey100);
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: $indent-xxxl;
  }

  @media screen and (max-width: 1200px) {
    &__header {
      padding-left: $indent-lg;
      min-height: 55px;
    }

    &__content {
      padding-left: $indent-xl;
    }
  }

  @media screen and (max-width: 768px) {
    height: 100%;

    &__container {
      width: 100%;
      border-radius: 0;
    }

    &__header {
      flex-direction: row-reverse;
      border-radius: 0;

      &-title {
        line-height: 24px;
        margin-right: 60px;
      }

      &-close-button {
        display: none;

        &-mobile {
          display: block;
        }
      }
    }

    &__content {
      flex-direction: column;
      flex-basis: 100%;
      overflow-y: auto;
      padding-left: $indent-sm;
    }
  }
}
