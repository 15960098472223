@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-create-article {
  margin-top: $indent-sm;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  padding: 0;

  &__input {
    padding: $indent-md $indent-xl;
    box-shadow: inset 0 -1px 0 0 var(--grey20);
  }

  &__editor {
    padding: $indent-md $indent-xl;
    flex-basis: 100%;
  }

  &__buttons-block {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: $indent-md $indent-xl;
    grid-gap: $indent-xs;
  }
}
