@use "./src/shared/assets/scss/variables/index.scss" as *;
.work-group-page {
  &__content {
    margin-top: $indent-sm;
  }

  @media screen and (max-width: 768px) {
    &__content {
      margin-top: 0;
      background-color: var(--common2);
      margin-bottom: $indent-xl;
    }
  }
}
