@use "./src/shared/assets/scss/variables/index.scss" as *;
.add-note-modal {
  position: relative;
  height: 100%;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $indent-md $indent-xl;
    align-items: center;
    color: var(--grey200);
  }

  &__close {
    color: var(--grey100);
  }

  &__textarea {
    border: none;

    &-wrapper {
      height: 100px;
      border-bottom: 1px solid var(--grey30);
    }

    &-container {
      height: 100%;
    }
  }

  &__title {
    height: 44px;
    display: flex;
    align-items: flex-end;
  }

  &__buttons {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    padding: $indent-sm $indent-md;
    column-gap: $indent-sm;
    border-top: 1px solid var(--grey30);
    width: 100%;

    &-attachment {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--blue120);
      height: 100%;
      min-width: 50px;
      transition: background-color 0.4s;
    }
  }

  &__files {
    &-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      grid-gap: $indent-sm;
      margin-bottom: $indent-lg;
    }
  }

  &__body {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    padding: 0 $indent-xl;
    overflow: auto;
    width: 400px;
    row-gap: $indent-sm;
    height: 100%;
  }
}
