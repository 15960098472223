@use "./src/shared/assets/scss/variables/index.scss" as *;
.solution {
  border-radius: $border-radius-sm;
  padding: $indent-sm $indent-lg;
  background-color: var(--green10);

  &__header {
    margin-bottom: $indent-xs;
    display: flex;
    justify-content: space-between;
  }

  &__text {
    font-size: $sm-text;
    line-height: 22px;
    word-break: break-all;
    color: var(--grey180);
  }
}
