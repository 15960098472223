@use "./src/shared/assets/scss/variables/index.scss" as *;
.work-group-form {
  &__form {
    display: flex;
    flex-wrap: wrap;

    &-header {
      padding-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: inset 0 -2px 0 0 var(--grey20);

      &-tab {
        width: fit-content;
        padding-bottom: 15px;
        box-shadow: inset 0 -2px 0 0 var(--blue120);
      }
    }

    &-content {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__input {
    margin-bottom: $indent-md;

    &_one-of-three {
      flex-basis: 32%;
    }

    &_mr {
      margin-right: $indent-md;
    }
  }

  &__hidden-block {
    margin-top: $indent-xxxl;

    &-content {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 768px) {
    border-bottom: 2px solid var(--grey20);

    &__form {
      &-header {
        display: none;
      }

      &-content {
        border-radius: 0;
      }
    }

    &__input {
      &_one-of-three {
        flex-basis: 100%;
      }
    }
  }
}
