@use "./src/shared/assets/scss/variables/index.scss" as *;
.profile-user-tabs {
  display: flex;
  flex-direction: column;
  grid-gap: $indent-sm;
  width: 100%;
  padding: $indent-xxxl $indent-xl 0 $indent-xl;

  &__tab {
    padding: $indent-xs $indent-sm;
    position: relative;
    transition: all 0.4s;

    &:hover {
      background-color: var(--blue10);
      transition: all 0.4s;
    }

    &_active {
      background-color: var(--common2);
    }
  }

  &__icon {
    width: 16px;
    height: 16px;

    &-exit {
      width: 300px;
      background-color: var(--common2);
    }

    &-circle {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);

      &_news {
        background-color: var(--yellow100);

        &::after,
        &::before {
          background-color: var(--yellow100);
        }
      }
    }

    &-block {
      width: 32px;
      height: 32px;
      min-width: 0;
      background-color: var(--common2);
      color: var(--grey100);

      &-notifications {
        color: var(--common2);
        background-color: var(--purple100);
      }

      &-settings {
        color: var(--common2);
        background-color: var(--blue120);
      }

      &-telegram {
        color: var(--common2);
        background-color: #229ed9;
      }

      &-news {
        color: var(--common2);
        background-color: var(--yellow120);
      }
    }
  }

  &__text {
    font-size: $sm-text;
    color: var(--grey200);

    &-wrapper {
      margin-left: $indent-md;
    }
  }

  &__title-block-notifications {
    padding-right: $indent-xl;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;

    &__tab {
      border-radius: $border-radius-sm;
    }
  }
}

[data-theme|='dark'] {
  .profile-user-tabs {
    &__icon {
      &-block {
        &-notifications {
          color: var(--grey180);
        }

        &-settings {
          color: var(--grey180);
        }

        &-telegram {
          color: var(--grey180);
        }

        &-news {
          color: var(--grey180);
        }
      }
    }
  }
}
