@use "./src/shared/assets/scss/variables/index.scss" as *;
.status-block {
  white-space: nowrap;
  width: fit-content;
  padding: 2px $indent-sm;
  border-radius: 2px;
  color: var(--common2);

  &_new {
    color: var(--orange10);
    background-color: var(--orange80);
  }

  &_work {
    color: var(--blue120);
    background-color: var(--blue20);
  }

  &_close {
    color: var(--green120);
    background-color: var(--green20);
  }

  &_pending-closure {
    color: var(--common2);
    background-color: var(--green50);
  }

  &_waiting-info {
    color: var(--cyan120);
    background-color: var(--cyan20);
  }

  &_reopen {
    color: var(--common2);
    background-color: var(--orange90);
  }

  @media screen and (max-width: 768px) {
    font-size: $xxs-text;
  }
}

[data-theme|='dark'] {
  .status-block {
    color: var(--grey180);
  }
}

[data-theme='light-contrast'] {
  .status-block {
    &_new {
      color: var(--orange180);
    }

    &_work {
      color: var(--blue180);
    }

    &_close {
      color: var(--green180);
    }

    &_waiting-info {
      color: var(--cyan180);
    }
  }
}
