@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-article {
  flex-basis: 70%;
  overflow: auto;

  &__wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    grid-gap: $indent-sm;
    flex-basis: 100%;
    margin-top: $indent-sm;
    overflow: auto;
  }

  &__search {
    flex-basis: 30%;
    min-width: 400px;
    height: 100%;
    overflow: hidden;
  }

  @media screen and (max-width: 1200px) {
    flex-basis: auto;
    height: fit-content;
    min-height: 300px;

    &__wrapper {
      flex-direction: column;
      overflow: hidden;
    }

    &__search {
      flex-basis: auto;
      height: fit-content;
    }
  }

  @media screen and (max-width: 768px) {
    &__search {
      min-width: auto;
    }
  }
}
