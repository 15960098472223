// TODO feature/tsp-2515 - sass warnings
@use '../variables' as *;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: background-color $transition;

  &::after,
  &::before {
    box-sizing: border-box;
  }

  &::-webkit-scrollbar {
    background-color: var(--grey20);
    border-radius: $border-radius-sm;
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border: $border-radius solid var(--grey60);
    background: var(--grey60);
    border-radius: 6px;

    //&:active {
    //  border: 5px solid var(--grey10);
    //  background: var(--grey70);
    //}
  }

  &::-webkit-scrollbar-corner {
    background-color: var(--common2);
  }
}

html {
  font-size: $md-text;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  font-family: $Roboto;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: var(--grey200);
  fill: var(--grey200);
  background-color: var(--background1);
  font-weight: 400;
  font-size: $md-text;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex-grow: 1;
}

a {
  text-decoration: none;
  outline: 0;
  color: var(--blue120);
  fill: var(--blue120);
  cursor: pointer;
  transition: color $transition, fill $transition;

  &:focus {
    text-shadow: $focused-text-shadow var(--background1);
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.8;
  }
}

ol,
ul {
  list-style: none;
}

img {
  display: block;
}

input {
  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

textarea {
  resize: vertical;
}

button,
input,
textarea,
select {
  background-color: transparent;
  font-family: $Roboto;
  border: 0;
  border-radius: 0;
  outline: 0;
  cursor: pointer;
  font-size: 14px;
}

select {
  &:hover {
    border-color: var(--grey100);
  }

  &:focus {
    border-color: var(--blue120);
  }
}
