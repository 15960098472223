@use "./src/shared/assets/scss/variables/index.scss" as *;
.filters {
  margin-top: $indent-lg;
  margin-bottom: $indent-xxs;

  &-blocks {
    display: flex;
    justify-content: space-between;
    padding: 0 $indent-xl;
  }

  &-radio {
    width: 100%;
    color: var(--grey200);
    font-size: $sm-text;
    line-height: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__popover-inner {
    width: 100%;

    &-btn {
      font-size: $sm-text;
      line-height: 18px;
      padding-left: $indent-xxl;
    }

    &-icon {
      width: 19px;
      height: 19px;
    }
  }
}
