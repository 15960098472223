@use "./src/shared/assets/scss/variables/index.scss" as *;
.active-tickets-panel {
  width: 60%;
  margin-top: $indent-sm;
  padding: $indent-xxxl 60px;
  border-radius: $border-radius;
  min-height: 382px;
  background: var(--common2);

  &__content {
    display: flex;
    justify-content: center;
    gap: 60px;
    margin-top: 40px;

    &-line {
      width: 0;
      height: 126px;
      border: 1px solid var(--grey20);
      background-color: var(--grey20);
    }
  }

  &__block {
    display: flex;
    gap: 40px;

    &-icon {
      &-all-container {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius-sm;
        background-color: var(--blue80);
      }

      &-all {
        color: var(--common2);
      }

      &-expire-container {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius-sm;
        background-color: var(--red30);
      }

      &-expire {
        color: var(--red120);
      }
    }

    &-all {
      width: 85px;
      margin-top: $indent-xxxl;
      color: var(--grey120);
    }

    &-all-quantity {
      color: var(--grey200);
    }
  }

  &__field {
    display: flex;
    align-items: flex-start;
    gap: $indent-sm;
    margin-top: $indent-xxl;

    &-container {
      height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &:first-child {
      margin-top: 0;
    }

    &-quantity {
      text-align: right;
    }

    &-text {
      color: var(--grey120);
    }

    &-progress-bar {
      width: 150px;
      background-color: transparent;

      &_loaded {
        &-work {
          background-color: var(--blue70);
        }

        &-info {
          background-color: var(--cyan80);
        }

        &-closure {
          background-color: var(--green80);
        }

        &-new {
          background-color: var(--orange90);
        }
      }
    }
  }

  @media screen and (max-width: 1600px) {
    width: 100%;

    &__content {
      justify-content: space-between;
    }

    &__field {
      &-progress-bar {
        width: 350px;
      }
    }
  }

  @media screen and (max-width: 1350px) {
    &__field {
      &-progress-bar {
        width: 150px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    padding: $indent-xxxl 40px;

    &__field {
      &-progress-bar {
        width: 55px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      margin-top: $indent-xxl;
      flex-direction: column;
      align-items: flex-start;

      &-line {
        display: none;
      }
    }

    &__field {
      &-progress-bar {
        width: 85px;
      }
    }
  }
}

[data-theme|='dark'] {
  .active-tickets-panel {
    &__block {
      &-icon {
        &-all {
          color: var(--grey200);
        }

        &-expire {
          color: var(--grey200);
        }
      }
    }
  }
}
