@use "./src/shared/assets/scss/variables/index.scss" as *;
.notifications-layout {
  position: fixed;
  top: 56px;
  right: 94px;
  z-index: $z-index-notification;

  &__toast {
    &:not(:last-child) {
      margin-bottom: $indent-sm;
    }

    &-enter {
      opacity: 0;
      transform: scale(0.9);

      &-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
      }
    }
  }

  @media screen and (max-width: 768px) {
    top: $indent-lg;
    width: 100%;
    right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
