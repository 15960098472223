@use "./src/shared/assets/scss/variables/index.scss" as *;
.table-container {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  opacity: 1;
  transition: height $transition ease-in-out;
  overflow: auto;

  $this: &;

  &#{$this}_close {
    height: $table-header-height;
    overflow: hidden;
  }

  &__pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 62px;
  }

  &__content {
    display: flex;
    height: 100%;
    overflow: auto;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background-color: var(--common2);
    flex-direction: column;
    justify-content: space-between;
    padding: 0 $indent-xl;
    position: relative;

    &_loading {
      overflow: hidden;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--common2);
    z-index: $z-index-table-loader;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s;

    &_show {
      visibility: visible;
      opacity: 0.8;
    }
  }

  &__progress-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background-color: var(--blue40);
    z-index: $z-index-progress-line;
  }

  &__thumb {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: $z-index-progress-thumb;
    width: 0;
    height: 4px;
    background-color: var(--blue120);
    animation: thumb_animation 3s linear infinite;
  }

  @keyframes thumb_animation {
    0% { width: 0; }
    100% { width: 100%; }
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
    border-bottom: 2px solid var(--grey20);

    &__content {
      flex-grow: 1;
      position: relative;
      border-radius: 0;
      padding: 0 $indent-sm;
    }

    &__pagination {
      justify-content: center;
      min-height: 48px;
    }

    &__count-records {
      display: none;
    }
  }
}
