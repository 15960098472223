@use "./src/shared/assets/scss/variables/index.scss" as *;
.list-files {
  &__title {
    margin-bottom: var(--indent-12pt);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--indent-8pt);
  }
}
