@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-synchronization {
  display: flex;
  flex-direction: column;
  row-gap: $indent-xl;
  padding: 0 $indent-lg;
  height: 100%;

  &__switches {
    display: flex;
    flex-direction: column;
    row-gap: $indent-sm;
  }

  &__switch {
    height: 44px;
    display: flex;
    align-items: center;
  }

  &__switch-block {
    display: flex;
    flex-direction: column;

    &-description {
      color: var(--grey100);
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    row-gap: $indent-sm;
  }
}
