@use "./src/shared/assets/scss/variables/index.scss" as *;
.similar-solutions-list-element {
  padding: $indent-sm;
  display: flex;
  grid-gap: $indent-sm;
  background-color: var(--common2);
  border-radius: $border-radius;
  transition: opacity $transition;
  overflow: hidden;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  &__icon {
    color: var(--common2);

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 24px;
      height: 24px;
      border-radius: $border-radius-sm;

      &_ticket {
        background-color: var(--green100);
      }

      &_article {
        background-color: var(--blue120);
      }
    }
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title {
    color: var(--grey200);
    margin-bottom: $indent-xxs;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &__sub-title {
    color: var(--grey100);
    margin-bottom: $indent-xs;
  }

  &__text {
    color: var(--grey120);
    font-size: $sm-text;
    line-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__highlight {
    background-color: var(--blue40);
  }

  @media screen and (max-width: 768px) {
    &__title {
      font-size: $sm-text;
    }

    &__sub-title {
      font-size: $xs-text;
    }

    &__text {
      font-size: $xs-text;
      -webkit-line-clamp: 3;
    }
  }
}

[data-theme|='dark'] {
  .similar-solutions-list-element__icon {
    color: var(--grey200);
  }
}
