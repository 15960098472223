@use "./src/shared/assets/scss/variables/index.scss" as *;
.user-assistance-article-detail-header {
  &__title {
    color: var(--grey200);
    margin-left: $indent-xl;
    margin-right: 14px;

    &-wrapper {
      padding: $indent-xl;
      display: flex;
      align-items: center;
    }

    &-link {
      height: 16px;
      margin-bottom: $indent-sm;
    }
  }

  &__icon {
    color: var(--grey90);
  }

  &__info-blocks {
    display: flex;
    padding: 0 $indent-xl $indent-xl $indent-xl;
    overflow-x: auto;

    &-item:not(:last-child) {
      margin-right: 64px;

      &:not(:last-child)::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 20px;
        background-color: var(--grey30);
        position: absolute;
        right: -$indent-xxxl;
      }
    }
  }

  &__ticket {
    &-info-icon {
      width: $indent-lg;
      color: var(--grey100);
    }
  }

  &__user-icon {
    width: $indent-md;
  }

  @media screen and (max-width: 768px) {
    &__title {
      font-size: $md-text;

      &-wrapper {
        padding: $indent-md $indent-lg;
      }

      &-link {
        margin-bottom: $indent-xs;
      }
    }

    &__info-blocks {
      padding: $indent-xs $indent-lg;
      border-top: 1px solid var(--grey20);
      border-bottom: 1px solid var(--grey20);

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }

      &-item {
        &-text {
          font-size: $xs-text;
        }

        &:not(:last-child) {
          margin-right: 48px;

          &::after {
            right: -$indent-xl;
          }
        }
      }
    }
  }
}
