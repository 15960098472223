@use "./src/shared/assets/scss/variables/index.scss" as *;
.radio-tab {
  &_none {
    &::before {
      background-color: var(--grey20);
    }
  }

  &_low {
    &::before {
      background-color: var(--green120);
    }
  }

  &_medium {
    &::before {
      background-color: var(--yellow90);
    }
  }

  &_high {
    &::before {
      background-color: var(--orange110);
    }
  }

  &_critical {
    &::before {
      background-color: var(--red130);
    }
  }
}
