@use "./src/shared/assets/scss/variables/index.scss" as *;
.statuses-builder {
  display: flex;
  flex-direction: row;
  grid-gap: $indent-sm;
  flex-basis: 100%;
  overflow: hidden;

  &__table {
    width: 25%;
    min-width: 470px;
  }

  &__builder {
    width: 75%;
  }

  @media screen and (max-width: 1600px) {
    &__table {
      display: none;
    }

    &__builder {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 1px;
    flex-grow: 1;
  }
}
