@use "./src/shared/assets/scss/variables/index.scss" as *;
.drawer {
  z-index: $z-index-drawer;
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  box-shadow: var(--box-shadow-lvl-4);
  background-color: transparent;
  visibility: collapse;
  opacity: 0;
  transition: opacity $transition, background-color $transition, visibility $transition;

  &_active {
    visibility: visible;
    background-color: var(--transparent1);
    opacity: 1;
  }

  &_elevated {
    z-index: $z-index-drawer-inner;
  }

  &__content {
    position: absolute;
    right: 0;
    background-color: var(--common2);
    box-shadow: var(--box-shadow-lvl-2);
    border-radius: $border-radius 0 0 $border-radius;
    height: 100%;
    width: min-content;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform 1s;

    &_active {
      transform: translateX(0%);
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    &__content {
      width: 100%;
      border-radius: 0;
    }
  }
}
