@use "./src/shared/assets/scss/variables/index.scss" as *;
.table-row {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  border-top: 0.5px solid var(--grey20);
  border-bottom: 0.5px solid var(--grey20);
  background-color: var(--common2);
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--grey10);
    transition: background-color 0.3s;
  }

  &_active {
    background-color: var(--blue20);

    &:hover {
      background-color: var(--blue20);
      cursor: auto;
    }
  }

  &:first-child {
    border-top: none;
  }

  &_focusable:focus {
    outline: 2px solid var(--blue180);
    box-shadow: none;
    border-radius: $border-radius-sm;
  }

  &_clickable {
    cursor: pointer;
  }

  &_disabled {
    opacity: 0.3;
    pointer-events: none;

    &:focus {
      outline: none;
    }
  }

  &_deleted {
    opacity: 0.65;
  }

  @media screen and (max-width: 768px) {
    &:hover {
      background-color: var(--common2);
    }

    &_active {
      &:hover {
        background-color: var(--blue20);
        cursor: auto;
      }
    }
  }
}

[data-theme|='dark'] {
  .table-row {
    &:hover {
      background-color: var(--blue20);
      transition: background-color 0.3s;
    }

    &_active {
      background-color: #152132;

      &:hover {
        background-color: #152132;
      }
    }

    @media screen and (max-width: 768px) {
      &:hover {
        background-color: var(--common2);
      }

      &_active {
        &:hover {
          background-color: #152132;
        }
      }
    }
  }
}
