@use "./src/shared/assets/scss/variables/index.scss" as *;
.preview-attachment {
  margin-right: $indent-xl;
  display: flex;

  &_is-pointer {
    cursor: pointer;
  }

  &__s {
    align-items: center;
    margin-bottom: $indent-xs;
  }

  &__m {
    flex-direction: column;
    position: relative;

    &-img {
      align-items: flex-start;
    }

    &-doc {
      flex-direction: row;
      align-items: center;
    }
  }

  &__l {
    &-img {
      align-items: flex-start;
    }

    &-doc {
      align-items: center;
    }
  }

  &__description {
    &_m {
      &-doc {
        margin-left: $indent-sm;
      }

      &-img {
        display: flex;
        justify-content: space-between;
      }
    }

    &_l {
      &-doc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: $indent-sm;
      }

      &-img {
        flex-direction: row;
        display: flex;
      }
    }
  }

  &__name {
    &_s {
      margin-left: $indent-sm;
      margin-bottom: $indent-xs;
    }

    &_m {
      flex-basis: 75%;
      margin-bottom: $indent-xs;
    }

    &_l {
      flex-basis: 70%;
    }
  }

  &__info {
    display: flex;
    color: var(--grey120);

    &_l {
      justify-content: flex-end;
      flex-basis: 30%;
    }

    &_m {
      flex-basis: 25%;
      justify-content: flex-end;
    }

    &_s {
      margin-left: $indent-sm;

      &-file-size {
        margin-right: $indent-sm;
      }
    }
  }

  &__icon-button {
    visibility: hidden;

    &_l,
    &_m {
      &-img {
        background: var(--grey20);
        color: var(--grey140);
      }

      &-doc {
        margin-left: $indent-xs;
      }
    }

    @media screen and (max-width: 768px) {
      visibility: visible;
    }
  }

  &__buttons {
    &_s {
      margin-left: $indent-xs;
    }

    &_m {
      &-img {
        position: absolute;
        top: $indent-xs;
        right: $indent-xs;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &_l {
      &-img {
        position: absolute;
        bottom: $indent-xs;
        right: $indent-xs;
      }
    }
  }

  &__icon path {
    fill: var(--blue120);
  }

  &__icon-info {
    display: flex;
  }

  &__image {
    overflow: hidden;

    &-container {
      &_m {
        width: 200px;
      }

      &_l {
        width: 265px;
      }
    }

    &_s {
      width: 56px;
      height: 56px;
    }

    &_m {
      margin-bottom: $indent-xs;
      height: 113px;
    }

    &_l {
      position: relative;
      height: 150px;
      margin-bottom: $indent-xs;
    }

    &_logo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover &__icon-button {
    visibility: visible;
  }
}
