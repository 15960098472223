@use "./src/shared/assets/scss/variables/index.scss" as *;
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: var(--common2);

  $this: &;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__first-page-button,
  &__last-page-button {
    border-radius: 50%;
    width: 32px;
    height: 32px;

    &:hover {
      background-color: var(--grey10);
    }

    &:focus {
      background-color: var(--grey10);
    }

    &-icon {
      height: 100%;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__first-page-button {
    transform: rotate(180deg);
    color: var(--grey100);
  }

  &__last-page-button {
    position: relative;
    color: var(--grey100);
  }

  &__prev {
    transform: rotate(180deg);
  }

  &__next-button,
  &__prev-button {
    margin: 0 $indent-xs;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey100);
    font-size: $sm-text;

    & a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: var(--grey100);

      &:hover {
        background-color: var(--grey10);
      }

      &:focus {
        background-color: var(--grey10);
      }
    }

    &_active a {
      background: var(--blue10);
      color: var(--blue120);
    }

    &_disabled a {
      cursor: not-allowed;
    }

    &-mobile {
      display: none;
    }
  }

  &__select {
    min-width: auto;
    width: 54px;
    margin-left: $indent-lg;

    &-content {
      transform: translate(0, 0%);
      bottom: 35px;
      top: auto;
      min-width: 100%;
    }

    &-title {
      overflow: visible;
      margin-left: -4px;
    }

    &-current-option {
      padding-top: 0;
      position: relative;
    }
  }

  &__page-number-mobile {
    display: none;
    white-space: nowrap;
    font-size: $sm-text;
    color: var(--grey100);
    margin: 0 44px;
  }

  &_mobile {
    margin-right: 0;
    width: 100%;
    justify-content: space-between;

    #{$this}__wrapper {
      width: 100%;
      justify-content: space-between;
    }

    #{$this}__page-number-mobile {
      display: block;
    }

    #{$this}__button-mobile {
      display: block;
    }

    #{$this}__select {
      display: none;
    }

    #{$this}__button {
      display: none;
    }

    #{$this}__first-page-button,
    #{$this}__last-page-button {
      &-icon {
        color: var(--grey120);
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-right: 0;
    width: 100%;
    justify-content: space-between;

    &__wrapper {
      width: 100%;
      justify-content: space-between;
    }

    &__page-number-mobile {
      display: block;
    }

    &__button-mobile {
      display: block;
    }

    &__select {
      display: none;
    }

    &__button {
      display: none;
    }

    &__first-page-button,
    &__last-page-button {
      &-icon {
        color: var(--grey120);
      }
    }
  }

  @media screen and (max-width: 368px) {
    &__page-number-mobile {
      margin: 0 28px;
    }
  }
}

[data-theme|='dark'] {
  .pagination {
    &__first-page-button,
    &__last-page-button {
      &:hover {
        color: var(--blue160);
      }

      &:focus {
        color: var(--blue160);
      }
    }

    &__button {
      & a {
        &:hover {
          color: var(--blue160);
        }

        &:focus {
          color: var(--blue160);
        }
      }

      &_active a {
        color: var(--blue160);
      }
    }
  }
}
