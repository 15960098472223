@use "./src/shared/assets/scss/variables/index.scss" as *;
.notification-storage-information {
  &__icon {
    width: 16px;
  }

  &__wrapper {
    width: 100%;
  }

  &__organization-title {
    color: var(--grey200);
  }
}
