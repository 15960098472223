@use "./src/shared/assets/scss/variables/index.scss" as *;
.popovers {
  display: flex;
  flex-direction: column;
  width: 35px;
  margin-top: 36px;

  &__item {
    margin-bottom: $indent-xs;
    color: var(--grey100);
  }
}
