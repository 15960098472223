@use "./src/shared/assets/scss/variables/index.scss" as *;
.menu-item {
  color: var(--grey200);
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: 0 $indent-sm;
  text-align: start;
  text-overflow: ellipsis;
  transition: min-height 0.3s ease-out;
  white-space: nowrap;
  width: 100%;
  outline: none;
  user-select: none;

  &_disabled {
    background: var(--grey10);
  }

  &_xs {
    height: 32px;
    max-height: 32px;
    line-height: 32px;
    font-size: $sm-text;
  }

  &_s {
    height: 40px;
    max-height: 40px;
    line-height: 40px;
    font-size: $sm-text;
  }

  &_m {
    height: 48px;
    max-height: 48px;
    line-height: 48px;
    font-size: $sm-text;
  }

  &_l {
    height: 56px;
    max-height: 56px;
    line-height: 56px;
    font-size: $md-text;
  }

  &:hover,
  &:focus,
  &_selected {
    background-color: var(--grey10);
  }
}
