@use "./src/shared/assets/scss/variables/index.scss" as *;
.attachment-detail {
  display: flex;
  min-height: 120px;
  padding: $indent-md 0;
  border-bottom: 1px solid var(--grey30);
  overflow-x: auto;

  &__wrapper {
    display: flex;
    width: 100%;
    flex-basis: 100%;
    flex-direction: column;

    &_no-notes {
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  &__no-notes {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-xl;
    color: var(--grey120);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--grey100);
    font-size: $sm-text;
    grid-gap: $indent-xs;
  }

  &__data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: $indent-sm;
    align-items: baseline;
  }

  &__user-data {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: $indent-xs;
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: $indent-xs;
    color: var(--grey200);

    &-files {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      grid-gap: $indent-xs;
    }
  }
}
