@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-category-title {
  font-weight: 500;
  color: var(--grey150);
  font-size: $md-text;
  max-width: 100%;
  flex-basis: 100%;

  &__icon {
    color: var(--grey60);
  }

  &_full {
    max-width: calc(100% - 31px);
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 55px);
  }

  &_sub-category {
    font-size: $sm-text;
    font-weight: 400;
  }
}
