@use "./src/shared/assets/scss/variables/index.scss" as *;
.accordion {
  width: 100%;

  $this: &;

  &__header {
    height: 40px;
    padding: 0 $indent-md;
    background-color: var(--grey20);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &:has(#{$this}__button:focus) {
      outline: 2px solid var(--blue180);
    }
  }

  &__title {
    user-select: none;
  }

  &__content {
    overflow: hidden;
    transition: max-height $transition cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;

    &_visible {
      animation: delay-overflow 2s;
      overflow: visible;
    }

    &_collapsed {
      max-height: 0;
      transition: max-height $transition cubic-bezier(0, 1, 0, 1);
    }
  }

  @keyframes delay-overflow {
    from { overflow: auto; }
  }
}
