@use "./src/shared/assets/scss/variables/index.scss" as *;
.input-date-picker {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  $this: &;

  &__content {
    display: flex;
    border: 1px solid var(--grey30);
    border-radius: $border-radius-sm;
    background-color: var(--common2);
    align-items: center;
    width: 100%;
    padding: 0 $indent-sm;
    transition: border-color 0.3s;

    &_xs {
      height: 32px;
      min-width: 220px;
    }

    &_s {
      height: 40px;
    }

    &_m {
      height: 48px;
    }

    &_l {
      height: 56px;
    }

    &_disabled {
      background: var(--grey10);
      border-color: var(--grey10);
      pointer-events: none;
      & #{$this}__placeholder {
        color: var(--grey80);
      }

      & #{$this}__input {
        color: var(--grey130);
      }
    }

    &_error {
      border-color: var(--red120);
    }

    &:hover:not(#{$this}__content_error):not(#{$this}__content_disabled) {
      border-color: var(--grey100);
    }

    &:focus-within:not(#{$this}__content_error):not(#{$this}__content_disabled) {
      border-color: var(--blue120);
    }
  }

  &__error {
    &-message {
      color: var(--red120);
      font-size: $xs-text;
      line-height: 15px;

      &_l {
        font-size: $sm-text;
        line-height: 18px;
      }
    }

    &-margin {
      margin-top: 6px;

      &_xs {
        margin-top: $indent-xxs;
      }
    }
  }

  &__typography {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    &_filled {
      align-items: flex-end;
    }
  }

  &__placeholder {
    color: var(--grey100);
    white-space: nowrap;
    position: absolute;
    transition: top 200ms ease-in, font-size 200ms ease-in;
    line-height: 18px;
    font-size: $sm-text;
    text-overflow: ellipsis;
    max-width: 90%;
    overflow: hidden;

    &_xs {
      top: 6px;
    }

    &_s {
      top: 9px;
    }

    &_m {
      top: 13px;
    }

    &_l {
      line-height: 20px;
      font-size: $md-text;
      top: 18px;
    }

    &_minimized {
      font-size: $xs-text;
      line-height: 15px;
      transition: top 200ms ease-in, font-size 200ms ease-in;

      &_xs {
        visibility: hidden;
      }

      &_s {
        visibility: hidden;
      }

      &_m {
        top: 5px;
      }

      &_l {
        line-height: 18px;
        font-size: $sm-text;
        top: 7px;
      }
    }
  }

  &__input {
    position: absolute;
    width: 100%;
    display: inline-block;
    color: var(--grey200);
    white-space: nowrap;
    overflow-x: scroll;
    cursor: initial;
    font-size: $sm-text;
    line-height: 18px;

    &::-webkit-scrollbar {
      display: none;
    }

    &_xs {
      top: 6px;
    }

    &_s {
      top: 9px;
    }

    &_m {
      top: 22px;
    }

    &_l {
      line-height: 20px;
      font-size: $md-text;
      top: 27px;
    }
  }

  &__icons-container {
    margin-right: $indent-xxs;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 8px;
    height: auto;
    width: auto;
  }

  &__icon {
    height: 20px;
  }
}
