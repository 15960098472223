@use "./src/shared/assets/scss/variables/index.scss" as *;
.popover-down-content {
  position: relative;
  box-shadow: var(--box-shadow-lvl-2);
  border-radius: $border-radius-sm;
  width: 247px;
  background-color: var(--common2);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  grid-gap: $indent-xs;

  &__icon-plus {
    margin-right: 11.33px;
    width: 20px;
    height: 20px;

    & path {
      stroke-width: 2;
      stroke: var(--blue120);
    }
  }

  &__icon-close {
    margin-left: -2px;
    margin-right: 11.33px;
    width: 20px;
    height: 20px;

    & path {
      stroke-width: 3;
      stroke: var(--grey120);
    }
  }

  &__button-close {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
