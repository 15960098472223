@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-close-modal {
  &__text {
    max-height: 100px;
  }

  @media screen and (max-width: 768px) {
    &__text {
      width: 100%;
    }
  }
}
