@use "./src/shared/assets/scss/variables/index.scss" as *;
.system-form {
  display: flex;
  gap: $indent-md;
  height: 100%;

  &__card {
    width: 100%;
    background: var(--common2);
    border-radius: $border-radius;
  }

  &__header {
    background: var(--common2);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-bottom: 1px solid var(--grey20);
    display: flex;
    min-height: 60px;
    align-items: center;
    padding: $indent-lg $indent-xl;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: $indent-sm;
    padding: $indent-xl;
  }

  &__text-area {
    height: 110px;
  }

  &__index {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: $indent-xs;

    &-container {
      display: grid;
      grid-template-columns: 22% auto;
      grid-gap: $indent-xs;
    }

    &-error {
      color: var(--red120);
      font-size: $xs-text;
      font-weight: 100;
    }

    &-notification {
      display: flex;
      align-items: center;
      grid-gap: $indent-sm;
      width: 100%;
      min-height: 50px;
      padding: $indent-xxs $indent-sm;
      border-radius: $border-radius-md;
      background-color: var(--blue10);

      &-icon {
        width: 22px;
        height: 22px;
        color: var(--blue120);
      }

      &-text {
        color: var(--grey200);
      }

      &-number {
        max-width: 175px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--grey200);
      }
    }
  }

  &__button {
    &-container {
      display: flex;
      column-gap: $indent-sm;
    }

    &_create {
      color: var(--green120);
    }

    &_close {
      color: var(--red120);
    }

    &_delete {
      color: var(--grey80);
      width: 66px;
    }

    &_add {
      justify-content: left;
    }
  }

  &__environments {
    display: flex;
    column-gap: $indent-sm;
  }

  &__approve {
    color: var(--red100);
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}
