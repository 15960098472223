@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-comments {
  &__attachment-files {
    display: flex;
    flex-direction: column;
  }

  &__head {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey20);
  }

  &__theme {
    font-weight: 500;
    color: var(--grey200);
    font-size: $sm-text;
  }

  &__all-content {
    display: flex;
  }

  &__content-wrapper {
    min-width: 200px;
    flex-basis: 100%;
  }

  &__attachments {
    max-width: 400px;
    flex-basis: 100%;
    border-left: 1px solid var(--grey20);
    border-radius: 0;
    border-bottom-right-radius: $border-radius;

    &_closed {
      display: none;
    }

    &-title {
      color: var(--grey120);
    }

    &-container {
      margin-top: $indent-lg;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 414px;
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  &__content {
    padding: $indent-xl;
    height: 400px;
    background-color: var(--grey10);
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  &__loader {
    height: 400px;
    background-color: var(--grey10);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__chat-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: $indent-lg;

    &-title {
      color: var(--grey100);
    }
  }

  &__more-horizontal {
    color: var(--grey30);
  }

  &__input-wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    flex-direction: column;
    grid-gap: $indent-xs;

    &_attachments-open {
      border-bottom-right-radius: 0;
    }
  }

  &__input-field {
    border: 1px solid var(--grey30);
    border-radius: $border-radius-sm;
    display: flex;
    align-items: center;

    &:focus-within {
      border-color: var(--blue120);
    }

    &_disabled {
      background-color: var(--grey10);
    }
  }

  &__attachment-input {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--grey30);
    border-top-left-radius: $border-radius-sm;
    border-bottom-left-radius: $border-radius-sm;
    color: var(--blue120);
    min-width: 50px;
    transition: background-color $transition;
    max-height: 112px;

    &_open {
      background-color: var(--blue20);
    }

    &_disabled {
      color: var(--grey60);
      cursor: not-allowed;
    }

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      box-shadow: none;

      & svg {
        filter: drop-shadow($focused-svg-shadow var(--blue120));
      }
    }
  }

  &__input {
    width: 92%;
    font-size: $sm-text;
    color: var(--grey200);
    resize: none;
    min-height: 30px;
    padding-top: 6px;
    line-height: 18px;
    max-height: 96px;

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__input-block {
    margin: 6px 0;
    padding: 0 $indent-md;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__send-buttons {
    display: flex;

    &-send {
      margin-left: $indent-xs;
    }
  }

  &__multi-select-wrapper {
    display: flex;
    width: 100%;
    grid-gap: $indent-xs;
  }

  &__edit {
    &-buttons {
      display: flex;
      width: fit-content;
      height: 48px;
      flex-direction: row;
      background-color: var(--grey20);
      align-items: center;
      grid-gap: $indent-xs;
      border-radius: $border-radius-sm;
      padding: $indent-sm $indent-md;
    }

    &-close {
      background-color: var(--grey80);

      &:hover {
        background-color: var(--grey60);
      }
    }
  }

  &__comment {
    margin-top: $indent-md;

    &_my {
      margin-left: auto;
    }

    &:last-child {
      margin-top: 0;
    }

    &-enter {
      opacity: 0;
      transform: scale(0.9);

      &-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
      }
    }
  }

  &__file-load {
    &-wrapper {
      padding: $indent-xl;
      height: 400px;
      background-color: var(--grey10);
    }

    &-card {
      height: 100%;
    }
  }

  &__file {
    &-upload {
      height: 100%;
      background-color: var(--common2);
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--grey30);
    }

    &-names-wrapper {
      margin-top: 10px;
      display: flex;
      overflow-x: auto;
      padding-bottom: $indent-xxs;
    }

    &-block:not(:last-child) {
      margin-right: 15px;
    }
  }

  &__option {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    height: 48px;
    max-height: 48px;
    grid-gap: 2px;

    &-title {
      font-weight: 400;
      color: var(--grey200);
      line-height: 18px;
      font-size: $sm-text;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-theme {
      line-height: 14px;
      font-size: $xs-text;
      color: var(--grey100);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__select-menu {
    transform: translate(0, 0%);
    bottom: 48px;
    top: auto;
  }

  @media screen and (max-width: 1500px) {
    &__attachments {
      max-width: none;
      flex-basis: 100%;
      border-left: none;
      border-bottom-left-radius: $border-radius;
    }

    &__content {
      &-wrapper {
        height: 100%;

        &_hidden {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &__attachments {
      flex-basis: 100%;
    }

    &__content {
      &-wrapper {
        &_hidden {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    &__file-upload {
      &-icon {
        flex-basis: 80%;
        margin-bottom: $indent-xs;
        margin-right: 0;
      }

      &-title {
        flex-basis: 85%;
        margin-bottom: $indent-xxs;
      }

      &-sub-title {
        flex-basis: 85%;
        margin-bottom: $indent-xxs;
      }

      &-button {
        flex-basis: 60%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-grow: 1;
    min-height: 100px;
    display: flex;
    flex-direction: column;

    &__all-content {
      flex-grow: 1;
      min-height: 100px;
      flex-direction: column;
      overflow: auto;
    }

    &__title {
      font-size: $sm-text;
      font-weight: 500;
    }

    &__content {
      padding: 8px;
      flex-grow: 1;
      max-height: 466px;

      &-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;

        &_hidden {
          display: none;
        }
      }
    }

    &__input {
      font-size: $md-text;

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
    }

    &__head {
      padding: 10px $indent-lg;
      border-radius: 0;
      border-top: 1px solid var(--grey20);
    }

    &__input-wrapper {
      border-radius: 0;
      padding: 8px $indent-lg;
    }

    &__attachments {
      min-width: 200px;
      border-left: none;
      border-radius: 0;

      &-container {
        max-height: 400px;
        height: auto;
      }
    }

    &__file {
      &-load {
        &-wrapper {
          flex-basis: 100%;
          height: auto;
        }
      }

      &-names-wrapper {
        &::-webkit-scrollbar {
          height: 0;
          width: 0;
        }
      }
    }
  }
}
