@use "./src/shared/assets/scss/variables/index.scss" as *;
.notification-link {
  position: relative;
  padding-right: 14px;
  padding-top: $indent-xxs;

  &__circle {
    position: absolute;
    top: 0;
    right: 0;

    &_hide {
      display: none;
    }
  }
}

[data-theme|='dark'] {
  .notification-link {
    color: #3392ff;
  }
}

[data-theme='light-contrast'] {
  .notification-link {
    color: var(--blue170);
  }
}
