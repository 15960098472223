@use "./src/shared/assets/scss/variables/index.scss" as *;
.setting-fields-list {
  &__checkbox {
    height: 40px;
  }

  &__line {
    height: 1px;
    background-color: var(--grey30);
  }

  &__list {
    padding-left: $indent-xxxl;
  }
}
