@use "./src/shared/assets/scss/variables/index.scss" as *;
.profile-news-layout {
  flex-basis: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  grid-gap: $indent-sm;

  &__tabs {
    display: flex;
    flex-direction: row;
    padding: $indent-lg $indent-md 0 $indent-xl;
    box-shadow: inset 0 -2px 0 0 var(--grey20);
    width: 100%;
    position: relative;
    justify-content: space-between;
    min-height: 60px;
    max-height: 60px;

    &-item {
      padding-top: 10px;
    }
  }

  &__add-button {
    margin-top: -6px;
  }

  &__content {
    flex-basis: 50%;
    min-width: 300px;
    background-color: var(--common2);
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    display: flex;
    flex-direction: column;
  }

  &__detail {
    flex-basis: 50%;
    min-width: 320px;
    display: flex;
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    grid-gap: 1px;

    &__content {
      height: 50%;
      border-bottom-right-radius: 0;
    }

    &__detail {
      height: 50%;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    grid-gap: 1px;

    &__content {
      height: auto;
      flex-basis: 100%;
      border-radius: 0;
    }

    &__detail {
      height: auto;
      border-bottom-right-radius: 0;
    }

    &__tabs {
      padding: $indent-md $indent-md 0 $indent-md;
    }

    &__add-button {
      margin-top: 0;
      position: fixed;
      right: 26px;
      bottom: 102px;
      z-index: $z-index-action-button;
    }
  }
}
