@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-comment {
  display: flex;
  padding: $indent-sm $indent-xl;
  border-radius: $border-radius-sm;
  width: 70%;

  &_solution {
    border: 1.5px solid var(--green50);
  }

  &_request {
    border: 1.5px solid var(--blue120);
  }

  &_reopen {
    border: 1.5px solid var(--orange50);
  }

  &__icon {
    width: 22px;
    height: 22px;
    color: var(--grey200);
  }

  &__left-block {
    width: 6%;
    min-width: 44px;
  }

  &__right-block {
    width: 92%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin-top: 3px;
    display: flex;
    justify-content: space-between;
  }

  &__name {
    color: var(--grey120);
  }

  &__date {
    color: var(--grey120);
  }

  &__message {
    margin-top: $indent-xs;
    font-size: $sm-text;
    color: var(--grey200);
    line-height: 23px;
    max-width: 600px;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  &__tag {
    margin-top: $indent-xs;

    &_solution {
      background-color: var(--green20);
      color: var(--green120);
    }

    &_request {
      background-color: var(--blue20);
      color: var(--blue120);
    }

    &_reopen {
      background-color: var(--orange20);
      color: var(--orange120);
    }
  }

  &__buttons {
    display: flex;
    margin-top: $indent-xl;
    margin-bottom: 10px;
  }

  &__cancel-button {
    margin-right: 15px;
  }

  &__solution {
    margin-top: $indent-xl;
    display: flex;
    align-items: center;
    margin-bottom: $indent-sm;

    &-icon {
      &_accepted {
        color: var(--green120);
      }
    }

    &-text {
      margin-left: $indent-sm;

      &_await {
        color: var(--grey200);
      }

      &_accepted {
        color: var(--green120);
      }
    }
  }

  &__request {
    display: flex;
    margin-top: $indent-xs;

    &-block {
      margin-left: $indent-xs;
      background-color: var(--blue20);
      color: var(--blue120);
    }

    &-icon {
      align-items: center;
    }

    &-text {
      margin-left: $indent-xxs;
      color: var(--grey120);
      max-width: 340px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__check-mark {
    width: 13px;
    height: 13px;
    color: var(--common2);
  }

  &__cancel {
    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: $indent-xl;
    }

    &-title {
      color: var(--red120);
      margin-bottom: $indent-md;
    }

    &-buttons {
      margin-top: $indent-md;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    &-submit-button {
      margin-left: 15px;
    }
  }

  &__canceled-wrapper {
    margin-top: $indent-xl;
    display: flex;
    flex-direction: column;
    margin-bottom: $indent-sm;

    &-title {
      color: var(--red120);
      margin-bottom: $indent-md;
    }

    &-text {
      font-size: $sm-text;
      max-width: 600px;
      overflow-x: hidden;
      word-wrap: break-word;
    }
  }

  &__files-wrapper {
    display: flex;
    padding-bottom: $indent-xxs;
    overflow-y: hidden;
    overflow-x: auto;

    &_image {
      height: 200px;
    }
  }

  &__file:not(:last-child) {
    margin-right: 10px;
  }

  &__custom-status {
    &-wrapper {
      margin-top: $indent-xs;
      display: flex;
      align-items: center;
    }

    &-icon {
      color: var(--grey100);
      margin-right: $indent-sm;
    }
  }

  &__tickets {
    display: flex;
    margin-top: $indent-xs;
    gap: $indent-xs;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 1400px) {
    width: 90%;

    &__request {
      &-text {
        font-size: $xs-text;
        max-width: 300px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    width: 95%;

    &__message {
      max-width: 400px;
    }

    &__request {
      &-text {
        font-size: $xs-text;
        max-width: 200px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;

    &__message {
      max-width: 360px;
    }

    &__request {
      flex-direction: column;

      &-block {
        margin-left: $indent-xxs;
      }

      &-icon {
        display: none;
      }

      &-text {
        font-size: $xs-text;
        max-width: 160px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__message {
      font-size: $xs-text;
      line-height: 18px;
      max-width: 400px;
    }

    &__name {
      font-size: $xs-text;
    }

    &__date {
      font-size: $xs-text;
    }

    &__request {
      flex-direction: column;

      &-block {
        margin-left: $indent-xxs;
      }

      &-icon {
        display: none;
      }

      &-text {
        font-size: $xs-text;
        max-width: 300px;
      }
    }

    &__files-wrapper {
      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
    }
  }
}

[data-theme='light-contrast'] {
  .ticket-comment {
    &__tag {
      &_solution {
        color: var(--green180);
      }

      &_request {
        color: var(--blue180);
      }
    }

    &__request {
      &-block {
        color: var(--blue180);
      }
    }
  }
}
