@use "./src/shared/assets/scss/variables/index.scss" as *;
.table-body-cell {
  display: table-cell;
  vertical-align: middle;
  font-size: $sm-text;
  white-space: nowrap;
  text-align: left;
  min-width: 40px;
  max-width: 260px;
  text-overflow: ellipsis;
  height: 40px;
  overflow: hidden;
  padding: 0 $indent-sm;

  &_filter {
    overflow: inherit;

    &:first-child {
      position: sticky;
      left: 0;
      z-index: $z-index-filter;
      background-color: var(--grey20);
    }
  }
}
