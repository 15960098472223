@use "./src/shared/assets/scss/variables/index.scss" as *;
.organizations-table-container {
  height: 100%;

  &_service {
    background-color: var(--cyan20);
    color: var(--cyan120);
  }

  &__icon {
    width: 20px;
    min-width: 20px;
    color: var(--grey80);
  }

  &__icon-delete {
    color: var(--red100);
  }

  &__modal {
    width: auto;

    &-delete {
      max-width: 522px;
      line-height: 1.25;

      &-text {
        margin-bottom: $indent-lg;
      }
    }

    &-text {
      padding: $indent-xl;
      text-align: center;
    }
  }

  @media screen and (max-width: 1200px) {
    height: 768px;
  }

  @media screen and (max-width: 768px) {
    height: 354px;

    &__modal {
      width: 100%;

      &-text {
        padding: 0;
      }

      &-delete {
        max-width: none;
      }
    }
  }
}
