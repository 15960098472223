@use "./src/shared/assets/scss/variables/index.scss" as *;
.status-side-menu {
  background-color: var(--common2);
  min-width: 364px;
  max-width: 364px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $indent-md;
    padding: $indent-xxs 0;

    &-button {
      line-height: 0;
      transition: opacity $transition;

      &:hover {
        opacity: 0.8;
      }

      &-icon {
        color: var(--grey100);
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: $indent-lg;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: none;
    min-width: 0;
    z-index: $z-index-drawer;
  }
}
