@use "./src/shared/assets/scss/variables/index.scss" as *;
.file-chip {
  display: flex;
  align-items: center;
  background: var(--common2);
  border-radius: 51px;
  border: 1px solid var(--blue120);
  padding: $indent-xxs $indent-md;
  transition: opacity $transition;
  user-select: none;

  &__title {
    color: var(--grey200);
    font-weight: 500;
    font-size: $xs-text;
    margin-left: 8px;
    white-space: nowrap;
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__icon path {
    fill: var(--blue120);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &_default {
    &:hover {
      cursor: default;
      opacity: 1;
    }
  }

  &:focus {
    border: 2px solid var(--blue180);
  }

  &__delete {
    width: 10px;
    height: 10px;
    color: var(--grey120);
    margin-left: 12px;
  }

  @media screen and (max-width: 768px) {
    padding: $indent-xxs $indent-md;

    &__title {
      font-size: $xxs-text;
      margin-left: $indent-xs;
    }

    &__icon {
      width: 17px;
    }
  }
}
