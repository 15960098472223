:root[data-theme|="light"] {
  // blue light palette
  --blue10: #edf7ff;
  --blue20: #d6ecff;
  --blue30: #bfe1ff;
  --blue40: #a8d4ff;
  --blue50: #91c8ff;
  --blue60: #7abdff;
  --blue70: #63acff;
  --blue80: #4da0ff;
  --blue90: #3392ff;
  --blue100: #08f;
  --blue110: #007eed;
  --blue120: #0075db;
  --blue130: #006bc9;
  --blue140: #0062b8;
  --blue150: #005aaa;
  --blue160: #004f94;
  --blue170: #004582;
  --blue180: #003c70;
  --blue190: #00325e;
  --blue200: #00294d;
  // cyan light palette
  --cyan10: #e6f6fa;
  --cyan20: #d7f0f7;
  --cyan30: #c4e8f2;
  --cyan40: #afe2f0;
  --cyan50: #98d9ed;
  --cyan60: #80d0e8;
  --cyan70: #6bc8e3;
  --cyan80: #48bde0;
  --cyan90: #29b5e3;
  --cyan100: #00a9e0;
  --cyan110: #00a0d4;
  --cyan120: #0092c2;
  --cyan130: #0085b0;
  --cyan140: #00779e;
  --cyan150: #006c8f;
  --cyan160: #006080;
  --cyan170: #005773;
  --cyan180: #004d66;
  --cyan190: #004359;
  --cyan200: #003a4d;
  // purple light palette
  --purple10: #f6f2ff;
  --purple20: #f0e9ff;
  --purple30: #e4d8ff;
  --purple40: #d6c5ff;
  --purple50: #c9b2ff;
  --purple60: #b699ff;
  --purple70: #ab85ff;
  --purple80: #a070ff;
  --purple90: #945eff;
  --purple100: #8a4cf6;
  --purple110: #873ff3;
  --purple120: #8138e6;
  --purple130: #7232cc;
  --purple140: #642bb3;
  --purple150: #5927a0;
  --purple160: #4f228c;
  --purple170: #421d77;
  --purple180: #3b146b;
  --purple190: #301056;
  --purple200: #251041;
  // red light palette
  --red10: #ffefed;
  --red20: #ffe1de;
  --red30: #ffc7c2;
  --red40: #ffafa8;
  --red50: #ff9a91;
  --red60: #ff857a;
  --red70: #ff7063;
  --red80: #ff5c4d;
  --red90: #f43;
  --red100: #fc311e;
  --red110: #ed301f;
  --red120: #d92e1e;
  --red130: #c91a0a;
  --red140: #b80f00;
  --red150: #a60e00;
  --red160: #940c00;
  --red170: #850b00;
  --red180: #700b02;
  --red190: #610800;
  --red200: #4d0600;
  // orange light palette
  --orange10: #fff4e8;
  --orange20: #ffe7d1;
  --orange30: #ffd9ba;
  --orange40: #ffcca6;
  --orange50: #ffbf91;
  --orange60: #ffb37d;
  --orange70: #ffa769;
  --orange80: #ff9a52;
  --orange90: #fc8d3d;
  --orange100: #fa7f28;
  --orange110: #f77314;
  --orange120: #f56600;
  --orange130: #e65c00;
  --orange140: #d65200;
  --orange150: #c74900;
  --orange160: #b84000;
  --orange170: #a83800;
  --orange180: #942c00;
  --orange190: #802200;
  --orange200: #6b1900;
  // yellow light palette
  --yellow10: #fff9d9;
  --yellow20: #fff3c2;
  --yellow30: #ffedab;
  --yellow40: #ffe799;
  --yellow50: #ffe085;
  --yellow60: #ffdb70;
  --yellow70: #ffd466;
  --yellow80: #ffce52;
  --yellow90: #ffc633;
  --yellow100: #ffb70f;
  --yellow110: #ffae00;
  --yellow120: #f5a700;
  --yellow130: #eb9c00;
  --yellow140: #e19200;
  --yellow150: #d08000;
  --yellow160: #bf6f00;
  --yellow170: #ae6600;
  --yellow180: #9d5600;
  --yellow190: #8c4800;
  --yellow200: #7b3b00;
  // green light palette
  --green10: #edfff0;
  --green20: #d7f7dd;
  --green30: #b2eec0;
  --green40: #97e6ac;
  --green50: #7edd99;
  --green60: #66d485;
  --green70: #50cc75;
  --green80: #3bc464;
  --green90: #25bb52;
  --green100: #00b336;
  --green110: #00a832;
  --green120: #009e2f;
  --green130: #00942c;
  --green140: #008a29;
  --green150: #008026;
  --green160: #007523;
  --green170: #006b20;
  --green180: #00611d;
  --green190: #005419;
  --green200: #004715;
  // grey light palette
  --grey10: #f6f8f9;
  --grey20: #e9edf2;
  --grey30: #dadfe4;
  --grey40: #cdd4da;
  --grey50: #c0c8d0;
  --grey60: #b3bdc6;
  --grey70: #a5b0bd;
  --grey80: #99a5b3;
  --grey90: #8a98a8;
  --grey100: #788694;
  --grey110: #6a7a88;
  --grey120: #62717e;
  --grey130: #596774;
  --grey140: #515e6a;
  --grey150: #46535e;
  --grey160: #3f4b55;
  --grey170: #37424b;
  --grey180: #303940;
  --grey190: #282f36;
  --grey200: #21272c;
  // common light palette
  --common1: #000;
  --common2: #fff;
  // background light palette
  --background1: #dde5ed;
  // transparent light
  --transparent1: rgba(33, 39, 44, 0.2);
  --transparent2: rgba(33, 39, 44, 0.75);
  // box-shadows light
  --box-shadow-lvl-1: 0 1px 4px rgba(33, 39, 44, 0.09);
  --box-shadow-lvl-2: 0 4px 15px rgba(33, 39, 44, 0.1);
  --box-shadow-lvl-3: 0 0 40px rgba(33, 39, 44, 0.1);
  --box-shadow-lvl-4: 0 0 55px rgba(33, 39, 44, 0.1);

  // TODO удалить после добавления либы

  // blue light palette
  --blue-10: #edf7ff;
  --blue-20: #d6ecff;
  --blue-30: #bfe1ff;
  --blue-40: #a8d4ff;
  --blue-50: #91c8ff;
  --blue-60: #7abcff;
  --blue-70: #63acff;
  --blue-80: #4da0ff;
  --blue-90: #3392ff;
  --blue-100: #08f;
  --blue-110: #007eed;
  --blue-120: #0075db;
  --blue-130: #006bc9;
  --blue-140: #0062b8;
  --blue-150: #005aaa;
  --blue-160: #004f94;
  --blue-170: #004582;
  --blue-180: #003c70;
  --blue-190: #00325e;
  --blue-200: #00294d;

  // cyan light palette
  --cyan-10: #e6f6fa;
  --cyan-20: #d7f0f7;
  --cyan-30: #c4e8f2;
  --cyan-40: #afe2f0;
  --cyan-50: #98d9ed;
  --cyan-60: #80d0e8;
  --cyan-70: #6bc8e3;
  --cyan-80: #48bde0;
  --cyan-90: #29b5e3;
  --cyan-100: #00a9e0;
  --cyan-110: #00a0d4;
  --cyan-120: #0092c2;
  --cyan-130: #0085b0;
  --cyan-140: #00779e;
  --cyan-150: #006c8f;
  --cyan-160: #006080;
  --cyan-170: #005773;
  --cyan-180: #004d66;
  --cyan-190: #004359;
  --cyan-200: #003a4d;

  // purple light palette
  --purple-10: #f6f2ff;
  --purple-20: #f0e9ff;
  --purple-30: #e4d8ff;
  --purple-40: #d6c5ff;
  --purple-50: #c9b2ff;
  --purple-60: #b699ff;
  --purple-70: #ab85ff;
  --purple-80: #a070ff;
  --purple-90: #945eff;
  --purple-100: #8a4cf6;
  --purple-110: #873ff3;
  --purple-120: #8138e6;
  --purple-130: #7232cc;
  --purple-140: #642bb3;
  --purple-150: #5927a0;
  --purple-160: #4f228c;
  --purple-170: #421d77;
  --purple-180: #3b146b;
  --purple-190: #301056;
  --purple-200: #251041;

  // red light palette
  --red-10: #ffefed;
  --red-20: #ffe1de;
  --red-30: #ffc7c2;
  --red-40: #ffafa8;
  --red-50: #ff9a91;
  --red-60: #ff857a;
  --red-70: #ff7063;
  --red-80: #ff5c4d;
  --red-90: #f43;
  --red-100: #fc311e;
  --red-110: #ed301f;
  --red-120: #d92e1e;
  --red-130: #c91a0a;
  --red-140: #b80f00;
  --red-150: #a60e00;
  --red-160: #940c00;
  --red-170: #850b00;
  --red-180: #700b02;
  --red-190: #610800;
  --red-200: #4d0600;

  // orange light palette
  --orange-10: #fff4e8;
  --orange-20: #ffe7d1;
  --orange-30: #ffd9ba;
  --orange-40: #ffcca6;
  --orange-50: #ffbf91;
  --orange-60: #ffb37d;
  --orange-70: #ffa769;
  --orange-80: #ff9a52;
  --orange-90: #fc8d3d;
  --orange-100: #fa7f28;
  --orange-110: #f77314;
  --orange-120: #f56600;
  --orange-130: #e55c00;
  --orange-140: #d65200;
  --orange-150: #c74900;
  --orange-160: #b84000;
  --orange-170: #a83800;
  --orange-180: #942c00;
  --orange-190: #802200;
  --orange-200: #6b1900;

  // yellow light palette
  --yellow-10: #fff9d9;
  --yellow-20: #fff3c2;
  --yellow-30: #ffedab;
  --yellow-40: #ffe799;
  --yellow-50: #ffe085;
  --yellow-60: #ffdb70;
  --yellow-70: #ffd466;
  --yellow-80: #ffce52;
  --yellow-90: #ffc633;
  --yellow-100: #ffb70f;
  --yellow-110: #ffae00;
  --yellow-120: #f5a700;
  --yellow-130: #eb9c00;
  --yellow-140: #e19200;
  --yellow-150: #d08000;
  --yellow-160: #bf6f00;
  --yellow-170: #ae6600;
  --yellow-180: #9d5600;
  --yellow-190: #8c4800;
  --yellow-200: #7b3b00;

  // green light palette
  --green-10: #edfff0;
  --green-20: #d7f7dd;
  --green-30: #b2eec0;
  --green-40: #97e6ac;
  --green-50: #7edd99;
  --green-60: #66d485;
  --green-70: #50cc75;
  --green-80: #3bc464;
  --green-90: #25bb52;
  --green-100: #00b336;
  --green-110: #00a832;
  --green-120: #009e2f;
  --green-130: #00942c;
  --green-140: #008a29;
  --green-150: #008026;
  --green-160: #007523;
  --green-170: #006b20;
  --green-180: #00611d;
  --green-190: #005419;
  --green-200: #004715;

  // grey light palette
  --grey-10: #f6f8f9;
  --grey-20: #e9edf2;
  --grey-30: #dadfe4;
  --grey-40: #cdd4da;
  --grey-50: #c0c8d0;
  --grey-60: #b3bcc6;
  --grey-70: #a5b0bd;
  --grey-80: #99a5b3;
  --grey-90: #8a98a8;
  --grey-100: #788694;
  --grey-110: #6a7a88;
  --grey-120: #62717e;
  --grey-130: #596774;
  --grey-140: #515e6a;
  --grey-150: #46535e;
  --grey-160: #3f4b55;
  --grey-170: #37424b;
  --grey-180: #303940;
  --grey-190: #282f36;
  --grey-200: #21272c;

  // common light palette
  --common-white: #fff;
  --common-black: #000;
  --common-gradient: linear-gradient(#005bb0, #00adeb);

  // background light palette
  --background: #e5e7eb;

  // transparent light
  --transparent-1: rgb(33 39 44 / 20%);
  --transparent-2: rgb(33 39 44 / 75%);

  // box-shadows light
  --box-shadow-xxs: 0 1px 4px 0 rgb(33 39 44 / 9%);
  --box-shadow-xs: 0 4px 15px 0 rgb(33 39 44 / 10%);
  --box-shadow-s: 0 0 40px 0 rgb(33 39 44 / 10%);
  --box-shadow-m: 0 0 55px 0 rgb(33 39 44 / 10%);
}
