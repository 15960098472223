@use "./src/shared/assets/scss/variables/index.scss" as *;
.approve-or-cancel-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: $z-index-modal-higher;
  box-shadow: var(--box-shadow-lvl-4);
  background-color: var(--transparent1);
  animation-name: appear;
  animation-duration: 300ms;

  &__content {
    position: relative;
    padding: $indent-xl;
    background: var(--common2);
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    animation-name: slide-in;
    animation-duration: 0.5s;
  }

  &__text {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    color: var(--grey200);
  }

  &__description {
    margin-top: 10px;
    text-align: center;
    color: var(--grey100);
  }

  &__buttons-block {
    margin-top: 37px;
    display: flex;
    justify-content: flex-end;
  }

  &__cancel-button {
    margin-right: 5px;
  }

  @keyframes appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes slide-in {
    from {
      transform: translateY(-70px);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes slide-in-adaptive {
    from {
      transform: translateY(170px);
    }

    to {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 768px) {
    display: block;

    &__content {
      border-radius: $border-radius $border-radius 0 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      animation-name: slide-in-adaptive;
    }

    &__buttons-block {
      flex-direction: column;
    }

    &__cancel-button {
      margin-right: 0;
      margin-bottom: $indent-xxs;
    }
  }
}
