@use "./src/shared/assets/scss/variables/index.scss" as *;
.attachment-detail {
  display: flex;
  align-items: center;
  min-height: 120px;
  margin: 0 $indent-md;
  padding: $indent-md 0;
  border-bottom: 1px solid var(--grey30);

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color: var(--grey100);
    font-size: $sm-text;
  }

  &__short-name {
    color: var(--grey100);
  }

  &__data {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;
  }

  &__user-data {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: $indent-xs;
  }

  &__date {
    height: 100%;
    display: flex;
    align-items: flex-start;

    &-text {
      color: var(--grey100);
    }
  }

  &__files {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: $indent-xs;
  }
}
