@use "./src/shared/assets/scss/variables/index.scss" as *;
.checkbox {
  user-select: none;
  display: flex;
  align-items: center;
  grid-gap: $indent-sm;

  $this: &;

  &:hover {
    cursor: pointer;
  }

  &_disabled {
    &:hover {
      cursor: not-allowed;
    }

    #{$this}__label {
      color: var(--grey80);
    }
  }

  &__icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-width: 2px;
    border-style: solid;
    box-sizing: border-box;
    background-color: var(--common2);
    color: var(--common2);
    border-radius: 2px;
    border-color: var(--grey80);
    transition: background-color $transition, border-color $transition;
  }

  &__input {
    display: none;

    &:checked {
      & + #{$this}__icon {
        background-color: var(--blue120);
        border-color: var(--blue120);
      }

      &:hover:not(:disabled) + #{$this}__icon {
        background-color: var(--blue100);
        border-color: var(--blue100);
      }

      &:disabled + #{$this}__icon {
        background-color: var(--grey50);
        border-color: var(--grey50);
      }
    }

    &:hover:not(:disabled) + #{$this}__icon {
      border-color: var(--grey60);
    }

    &:disabled + #{$this}__icon {
      border-color: var(--grey50);
    }
  }

  &__label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
