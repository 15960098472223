@use "./src/shared/assets/scss/variables/index.scss" as *;
.notification {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &__cross {
    color: var(--grey100);
  }

  &__icon {
    white-space: nowrap;
    margin-top: 6px;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-sm;
    background-color: var(--blue120);
    margin-right: $indent-xs;
    color: var(--common2);

    &_blue {
      background-color: var(--blue120);
      color: var(--common2);
    }

    &_purple {
      background-color: var(--purple100);
    }

    &_cyan {
      background-color: var(--cyan120);
    }

    &_red {
      background-color: var(--red60);
    }

    &_green {
      background-color: var(--green100);
    }

    &_deep-red {
      background-color: var(--red80);
    }

    &_orange {
      background-color: var(--orange80);
    }

    &_light-blue {
      background-color: var(--blue100);
    }

    &_lightest-blue {
      background-color: var(--blue30);
      color: var(--blue100);
    }

    &_small {
      width: 16px;
      height: 16px;
    }

    &_tiny {
      width: 12px;
    }
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 6px;

    &-text {
      display: flex;
      flex-direction: column;
    }
  }

  &__circle {
    &_hide {
      display: none;
    }
  }

  &__title {
    font-weight: 500;
    font-size: $sm-text;
    color: var(--grey200);
    white-space: nowrap;
    margin-bottom: 2px;
  }

  &__text {
    font-size: $xs-text;
    color: var(--grey120);
    white-space: nowrap;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    &_multi {
      justify-content: flex-start;
      grid-gap: $indent-md;
    }
  }

  &__ticket-link {
    white-space: nowrap;
    font-size: $sm-text;
    color: var(--blue120);

    &_list {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80px;
    }

    &_fake {
      font-size: $sm-text;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__time {
    white-space: nowrap;
    margin-left: $indent-sm;
    font-size: $xs-text;

    &-icon {
      width: 12px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      color: var(--grey90);
    }
  }

  &__close-toast {
    margin-left: 10px;
  }

  @media screen and (max-width: 768px) {
    &__text {
      max-width: 220px;
    }
  }

  @media screen and (max-width: 378px) {
    &__title {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__text {
      max-width: 190px;
    }
  }
}

[data-theme|='dark'] {
  .notification__icon {
    color: var(--grey180);
  }
}

[data-theme='light-contrast'] {
  .notification {
    &__ticket-link {
      color: var(--blue170);
    }

    &__text {
      color: var(--grey180);
    }
  }
}
