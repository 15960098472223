@use "./src/shared/assets/scss/variables/index.scss" as *;
.notification-info-block {
  &__block {
    &:not(:last-child) {
      margin-right: 64px;

      &::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 20px;
        background-color: var(--grey30);
        position: absolute;
        right: -$indent-xxxl;
      }
    }
  }

  &__icon {
    height: 32px;
    min-width: 32px;
    color: var(--grey100);
  }
}
