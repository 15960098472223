@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-chip {
  display: flex;
  align-items: center;
  grid-gap: $indent-xxs;
  height: 22px;
  padding: 0 $indent-xs;
  background-color: var(--grey20);
  width: fit-content;
  border-radius: $border-radius-sm;

  &__icon {
    width: 16px;
    height: 16px;
    color: var(--grey120);
  }

  &__number {
    font-weight: 400;
    color: var(--grey200);
    font-size: $xs-text;
    white-space: nowrap;
  }
}
