@use "./src/shared/assets/scss/variables/index.scss" as *;
.multi-value-item {
  &__option {
    position: relative;

    &_chip {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: $indent-xs;
      padding: 6px $indent-sm;
      border-radius: 60px;
      border: 1px solid var(--grey20);
    }
  }

  &__search {
    margin-right: $indent-xs;
    width: 60%;

    &_disabled {
      cursor: not-allowed;
    }
  }

  &__title {
    &_chip {
      padding-right: $indent-xl;
    }
  }

  &__comma {
    padding-right: $indent-xxs;
  }

  &__delete {
    &-button {
      position: absolute;
      right: 10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: var(--grey20);
      color: var(--grey100);

      &:hover {
        opacity: 0.8;
      }
    }

    &-icon {
      width: 10px;
      height: 10px;
      display: flex;
      margin: 0 auto;
    }
  }
}
