@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-info-drop-content {
  padding: $indent-md $indent-xl;
  background-color: var(--common2);
  box-shadow: var(--box-shadow-lvl-2);
  border-radius: $border-radius-sm;

  &__icon {
    width: 16px;
    height: 16px;
  }
}
