@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket {
  display: flex;
  margin-top: $indent-sm;
  grid-gap: $indent-sm;

  &__left-wrapper {
    flex-basis: 80%;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;
  }

  &__right-wrapper {
    flex-basis: 20%;
    display: flex;
    min-width: 437px;
    flex-direction: column;
    grid-gap: $indent-sm;
  }

  &__history-changes-block {
    flex-basis: 100%;
  }

  &__info-block {
    grid-gap: $indent-xl;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    flex-grow: 1;
    overflow-y: hidden;
    flex-direction: column;
    margin-top: 0;
    grid-gap: 0;

    &__left-wrapper {
      flex-grow: 1;
      grid-gap: 0;
    }

    &__right-wrapper {
      display: none;
    }
  }
}
