@use "./src/shared/assets/scss/variables/index.scss" as *;
.attach-files {
  display: flex;
  flex-direction: column;
  gap: var(--indent-16pt);

  &__drag-zone {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: var(--indent-40pt);
    border-radius: var(--border-radius-6);
    background-color: var(--common-white);
    transition: all var(--transition-delay);

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      border: 2px dashed var(--grey-60);
      border-radius: var(--border-radius-6);
      transition: all var(--transition-delay);
      pointer-events: none;
    }

    &_drag-over {
      background-color: var(--blue-10);

      &::after {
        border-color: var(--blue-60);
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__icon {
    margin-bottom: var(--indent-16pt);
    color: var(--grey-100);
    transform: rotate(100deg);
  }

  &__spinner {
    margin-bottom: var(--indent-16pt);
  }

  &__legend {
    text-align: center;
    color: var(--grey-120);
  }

  &__text {
    margin-top: var(--indent-8pt);
    color: var(--grey-120);
  }

  &__cancel-button {
    margin-top: var(--indent-16pt);
  }

  &__label {
    color: var(--grey-140);
    cursor: pointer;
  }

  &__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &__title {
    margin-bottom: var(--indent-12pt);
  }

  &__uploaded-files {
    display: flex;
    flex-direction: column;
    gap: var(--indent-8pt);
  }
}
