@use "./src/shared/assets/scss/variables/index.scss" as *;
.navigation-tab {
  height: 40px;
  display: flex;
  align-items: center;
  transition: background-color $transition, opacity $transition, border $transition, justify-content $transition;
  width: 100%;
  border-radius: $border-radius;
  opacity: 0.6;
  padding-left: $indent-md;

  &_hidden {
    display: none;
  }

  &_skeleton {
    padding-left: 0;
    opacity: 1;
  }

  &__icon {
    line-height: 0;
    width: 24px;
    height: 24px;

    & svg {
      width: 24px;
      height: 24px;
      color: var(--common2);
    }
  }

  &:hover {
    background-color: var(--blue170);
    opacity: 1;
  }

  &:focus {
    border: 1px solid var(--blue70);
  }

  &__title {
    color: var(--common2);
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity $transition, color $transition, max-width $transition;
    max-width: 0;

    &_expanded {
      margin-left: $indent-sm;
      visibility: visible;
      opacity: 1;
      max-width: 140px;
    }

    &_skeleton {
      width: 56px;
      height: 40px;
      border-radius: $border-radius;
      padding-left: 0;

      &-expanded {
        width: 100%;
      }
    }
  }

  &_active {
    background-color: var(--blue120);
    opacity: 1;
  }

  &__tooltip {
    margin-left: $indent-lg !important;
  }

  @media screen and (max-width: 768px) {
    border-radius: $border-radius;
    min-width: 56px;
    height: 52px;
    padding-left: 0;
    justify-content: center;
    flex-direction: column;

    &_skeleton {
      width: fit-content;
      height: auto;
    }

    &__title {
      font-size: $xxs-text;
      font-weight: 600;
      margin-top: $indent-xxs;

      &_expanded {
        margin-left: 0;
        max-width: none;
      }
    }

    &_expanded {
      margin-left: 0;
      max-width: none;
    }
  }

  @media screen and (max-width: 500px) {
    &__title {
      max-width: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

[data-theme|='dark'] {
  .navigation-tab {
    &__icon {
      & svg {
        color: var(--grey200);
      }
    }

    &_active {
      background-color: var(--blue60);
      opacity: 1;
    }

    &:hover {
      background-color: var(--blue20);
    }

    &__title {
      color: var(--grey200);
    }
  }
}
