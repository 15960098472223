@use "./src/shared/assets/scss/variables/index.scss" as *;
.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: fit-content;
  font-weight: 500;
  font-size: $xs-text;
  padding: 0 $indent-sm;
  border-radius: 2px;
  height: 20px;
  text-transform: uppercase;
  background: var(--grey20);
  color: var(--grey100);
  letter-spacing: 1px;

  &_solution {
    color: var(--green120);
    background-color: var(--green20);
  }

  @media screen and (max-width: 768px) {
    font-size: $xxs-text;
  }
}

[data-theme|='dark'] {
  .condition-block {
    color: var(--grey180);
  }
}

[data-theme='light-contrast'] {
  .condition-block {
    color: var(--grey180);

    &_solution {
      color: var(--green180);
    }
  }
}
