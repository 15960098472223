@use "./src/shared/assets/scss/variables/index.scss" as *;
.admins {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 52px);
  overflow: hidden;

  &__tab {
    padding-right: 0;

    &_hidden {
      display: none;
    }

    &:not(:first-child) {
      margin-left: $indent-xxxl;
    }
  }

  &__tab-settings {
    width: 200px;
    margin-left: $indent-xl;
  }

  &__info {
    justify-content: center;
    margin-left: 0;
  }

  &__icon {
    width: 14px;
    height: 14px;
    display: none;
  }

  @media screen and (max-width: 1200px) {
    max-height: none;

    &_builder {
      max-height: 100vh;
    }
  }

  @media screen and (max-width: 768px) {
    background-color: var(--common2);

    &__tab {
      padding-right: $indent-xs;
      padding-left: $indent-xs;

      &:not(:first-child) {
        margin-left: $indent-xs;
      }
    }

    &__tab-settings {
      width: 89px;
      height: 32px;
    }

    &__text {
      display: none;
    }

    &__icon {
      display: flex;
    }

    &__block {
      color: var(--grey100);
    }
  }
}
