@use "./src/shared/assets/scss/variables/index.scss" as *;
.notification-information-layout {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  min-width: 320px;
  overflow-y: auto;
  border-radius: $border-radius;
  background: var(--common2);
  row-gap: $indent-xxxl;
  flex-basis: 26%;

  &__header {
    display: flex;
    min-height: 78px;
    color: var(--grey200);
    box-shadow: inset 0 -2px 0 0 var(--grey20);
    padding: $indent-xl;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: $indent-xl;
    padding: 0 $indent-xl $indent-xl $indent-xl;
    box-shadow: inset 0 -2px 0 0 var(--grey20);
    border-radius: initial;
  }

  &__description {
    color: var(--grey120);
    line-height: var(--line-height-23);
  }
}
