@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integrations-list-element {
  transition: background-color $transition;
  $this: &;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey20);
  }

  &:hover {
    background-color: var(--grey10);
    #{$this}__buttons {
      visibility: visible;
      opacity: 1;
    }
    #{$this}__mobile-buttons {
      display: block;
    }
  }

  &_edit {
    background-color: var(--grey20);

    &:hover {
      background-color: var(--grey20);
    }
  }

  &__wrapper {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $indent-sm;
  }

  &__right {
    &-content-wrapper {
      display: flex;
      align-items: center;
    }

    &-wrapper {
      flex-basis: 58%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
    margin-left: $indent-sm;

    &_edit {
      visibility: visible;
      opacity: 1;
    }
  }

  &__button {
    &_delete {
      margin-right: $indent-xs;
      background-color: var(--red70);

      &:hover {
        background-color: var(--red50);
      }
    }

    &_edit {
      background-color: var(--blue120);

      &:hover {
        background-color: var(--blue100);
      }
    }

    &_close {
      margin-right: $indent-xs;
      background-color: var(--grey80);

      &:hover {
        background-color: var(--grey60);
      }
    }
  }

  &__arrow-icon {
    fill: var(--grey100);
    stroke: var(--grey100);
    margin: 0 30px;
    min-width: 20px;
  }

  &__mobile {
    &-buttons {
      display: none;
      flex-direction: column;
      width: 100%;

      &_edit {
        display: block;
      }
    }

    &-button {
      font-size: $sm-text;
      width: 100%;

      &_delete {
        color: var(--red120);
      }

      &:not(:last-child) {
        margin-bottom: $indent-xxs;
      }
    }
  }

  @media screen and (max-width: 360px) {
    &__right {
      &-wrapper {
        flex-basis: 65%;
      }
    }
  }
}
