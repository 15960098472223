@use "./src/shared/assets/scss/variables/index.scss" as *;
.profile-drop {
  border-radius: $border-radius-sm;
  width: 375px;
  max-height: 574px;
  overflow: auto;

  &__tab-group {
    display: flex;
    flex-direction: column;
    font-size: $sm-text;
    font-weight: 400;
    color: var(--grey200);
  }

  &__icon {
    color: var(--blue120);
  }

  &__link {
    color: var(--grey200);
    padding: $indent-sm 0;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;

    &_active {
      color: var(--blue120);
    }
  }

  &__switch {
    &-wrapper {
      &:hover {
        cursor: pointer;
        background-color: var(--grey10);
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__link {
      width: 100%;

      &-wrapper {
        border-bottom: 1px solid var(--grey20);
      }
    }

    &__top {
      max-height: calc(100vh - 250px);
      overflow: auto;
    }
  }
}
