@use "./src/shared/assets/scss/variables/index.scss" as *;
.export-fields {
  height: calc(100vh - 65px);
  overflow: auto;

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: $indent-md;
    margin-right: $indent-md;

    &:first-child {
      margin-top: $indent-xl;
    }

    &:last-child {
      margin-bottom: $indent-lg;
    }

    &__mobile-description {
      display: none;
    }

    &_checkbox {
      margin-right: $indent-lg;

      &-all {
        grid-gap: $indent-lg;
      }
    }

    &_select {
      width: 287px;
    }

    &_padding {
      padding-right: 50px;
    }

    &_date {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: $indent-xs;
      margin-right: 16px;
    }

    &_date-text {
      margin-top: $indent-md;
      margin-left: 38px;
      font-weight: 400;
      font-size: $sm-text;
      line-height: 18px;
      color: var(--grey100);
    }

    &_date-input {
      margin-left: $indent-xxs;
    }
  }

  &__date {
    &-input {
      width: 100%;
    }
  }

  &__container-modal-portal {
    z-index: $z-index-modal-portal;
  }

  &__container-modal-portal-cancel {
    z-index: $z-index-modal-portal;
  }

  @media screen and (max-width: 1200px) {
    height: calc(100vh - 55px);

    &__container {
      &_select {
        width: 200px;
      }
    }

    &__date-input {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    height: auto;
    overflow: visible;

    &__container {
      margin-right: $indent-sm;

      &__mobile-description {
        margin-top: $indent-xxl;
        margin-left: $indent-xxs;
        margin-right: $indent-xxl;
        display: grid;
        font-weight: 400;
        font-size: $sm-text;
        line-height: 18px;
        text-align: center;
        color: var(--grey100);
      }

      &_date {
        margin-right: $indent-sm;
      }
    }

    &__date-input {
      width: 100%;
    }
  }
}
