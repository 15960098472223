@use "./src/shared/assets/scss/variables/index.scss" as *;
.supplementary-agreement-create {
  max-width: 443px;
  width: 97%;
  min-height: 514px;

  &__no-result {
    font-size: $sm-text;
    line-height: 18px;
    padding: $indent-sm;
    color: var(--grey60);
  }

  &__loader {
    width: 30px;
    height: 30px;
    margin: 0 auto;
  }

  &__buttons-block-add {
    margin: 0;
    padding: $indent-sm $indent-xl $indent-xl;
  }

  & form {
    padding: 0;
    overflow: hidden;
  }

  &__form {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    padding: $indent-xl $indent-xxl $indent-lg $indent-lg;
  }

  &__systems {
    &-search {
      margin-bottom: $indent-sm;
    }

    &-header {
      display: block;
      margin-bottom: $indent-lg;
    }

    &-list {
      overflow-y: auto;
      height: 250px;
    }
  }

  &__system {
    &-item {
      color: var(--grey200);
      font-weight: normal;
      font-size: $sm-text;
      line-height: 18px;
      border-bottom: 1px solid var(--grey20);
      padding: $indent-sm;
      transition: background-color $transition;

      &:hover {
        background-color: var(--grey10);
        cursor: pointer;
        transition: background-color $transition;
      }

      &_active,
      &_active:hover {
        background-color: var(--blue20);
        color: var(--blue120);
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;

    &__form {
      flex-direction: column;
      height: calc(100vh - 200px);
    }

    &__systems {
      &-list {
        height: auto;
      }
    }
  }
}
