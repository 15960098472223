@use "./src/shared/assets/scss/variables/index.scss" as *;
.similar-solutions-list {
  padding: 28px $indent-xl $indent-sm $indent-xl;
  background-color: var(--grey10);
  flex-basis: 100%;
  overflow: hidden;
  display: flex;

  &__start-content {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-icon {
      color: var(--grey50);
      width: 64px;
      height: 64px;
      margin-bottom: $indent-xl;
    }

    &-text {
      color: var(--grey100);
      text-align: center;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header {
    box-shadow: inset 0 -2px 0 0 var(--grey20);
    display: flex;
    grid-gap: $indent-xxxl;
  }

  &__tab {
    padding-bottom: $indent-xs;
    transition: border-color $transition, color $transition;
    color: var(--grey100);
    font-size: $md-text;
    border-bottom: 2px solid var(--grey20);

    &_active {
      border-color: var(--blue120);
      font-weight: 500;
      color: var(--grey200);
    }

    &:hover {
      border-color: var(--blue120);
      cursor: pointer;
      font-weight: 500;
      color: var(--grey200);
    }
  }

  &__list-wrapper {
    margin: $indent-sm 0;
    overflow-y: auto;
    flex-basis: 100%;

    &_loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__element {
    &:not(:last-child) {
      margin-bottom: $indent-sm;
    }
  }

  &__empty-title {
    color: var(--grey200);
    margin-bottom: $indent-sm;
  }

  @media screen and (max-width: 768px) {
    padding: 18px 20px 0 20px;

    &__header {
      padding-left: $indent-md;
    }
  }
}
