@use "./src/shared/assets/scss/variables/index.scss" as *;
.mobile-dropdown-wrapper {
  @media screen and (max-width: 768px) {
    &__search {
      &-input {
        margin-bottom: 30px;
      }
    }

    &__mobile {
      &-content {
        display: block;
        left: 0;
        right: 0;
        position: fixed;
        background-color: var(--common2);
        z-index: $z-index-dropdown;
        top: 0;
        bottom: 0;
        cursor: initial;
        transition: left $transition, right $transition;
      }

      &-header {
        padding: $indent-md 0 $indent-md 17px;
        display: flex;
        align-items: center;
        background-color: var(--grey10);

        &-title {
          font-size: $md-text;
          font-weight: 500;
          color: var(--grey200);
          margin-left: $indent-xl;
        }
      }

      &-arrow {
        transform: rotate(90deg);
        color: var(--grey120);
      }

      &-body {
        padding: $indent-lg;
        max-height: calc(100vh - 53px);
        overflow-y: auto;
      }
    }
  }
}
