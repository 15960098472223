@use "./src/shared/assets/scss/variables/index.scss" as *;
.alerts-layout {
  position: fixed;
  top: 44px;
  right: 50%;
  transform: translateX(50%);
  z-index: $z-index-alert;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;

  &__toast {
    position: fixed;

    &-enter {
      opacity: 0;
      transform: scale(0.9);

      &-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    bottom: 84px;
    top: auto;
  }
}
