@use "./src/shared/assets/scss/variables/index.scss" as *;
.setting-field {
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: -30px;

  &_disabled {
    cursor: not-allowed;
    margin-left: 0;
  }

  &__drag {
    margin-right: $indent-xs;
    color: var(--grey100);
    cursor: grab;
  }
}
