@use "./src/shared/assets/scss/variables/index.scss" as *;
.contract {
  &__icon {
    width: 16px;
    height: 16px;
    color: var(--grey100);

    &-status {
      border-radius: $border-radius-sm;
      min-width: inherit;
      min-height: inherit;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--grey10);
      color: var(--grey100);
    }

    &-main {
      color: var(--common2);
    }

    &-wrapper {
      background-color: var(--purple120);
    }

    &-delete {
      color: var(--red100);
    }
  }

  &__status {
    &_active {
      background-color: var(--green120);
      color: var(--common2);
    }

    &_closed {
      background-color: var(--grey50);
      color: var(--common2);
    }

    &_planned {
      background-color: var(--blue120);
      color: var(--common2);
    }

    &_not-signed {
      background-color: var(--yellow120);
      color: var(--common2);
    }
  }
}

[data-theme|='dark'] {
  .contract__icon {
    color: var(--grey180);
  }
}
