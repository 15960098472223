@use "./src/shared/assets/scss/variables/index.scss" as *;
.notification-system-information {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: $indent-xl;
  }

  &__infoBlocks {
    display: flex;
    flex-wrap: wrap;
    row-gap: $indent-xl;
  }

  &__icon {
    width: 16px;
  }
}
