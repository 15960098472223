@use "./src/shared/assets/scss/variables/index.scss" as *;
.file-item {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: var(--indent-8pt) var(--indent-12pt);
  border-radius: var(--border-radius-4);
  background-color: var(--grey-10);

  $this: &;

  &_error {
    background-color: var(--red-10);
  }

  &__icon {
    --size: 16px;

    min-width: var(--size);
    max-width: var(--size);
    margin-right: var(--indent-8pt);
    color: var(--grey-100);
    transform: rotate(100deg);
  }

  &__name {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__size {
    margin: 0 auto 0 var(--indent-4pt);
    color: var(--grey-120);
    white-space: nowrap;
  }

  &__delete-button {
    margin-left: var(--indent-12pt);
  }

  &__status {
    width: 16px;
    height: 16px;

    &_error {
      color: var(--red-120);
    }

    &_success {
      color: var(--grey-120);
    }
  }
}
