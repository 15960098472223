@use "./src/shared/assets/scss/variables/index.scss" as *;
.sla-progress-bar {
  position: relative;
  height: 4px;
  background-color: var(--blue10);
  overflow: hidden;

  &__loaded {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 100%;
    background-color: var(--blue120);
    transition: right $transition;
  }
}
