@use "./src/shared/assets/scss/variables/index.scss" as *;
.sla-cell {
  align-items: center;
  display: flex;
  min-width: 230px;
  max-width: 300px;
  margin-right: 20px;
  grid-gap: 12px;

  &__input {
    width: 82px;

    &-text {
      color: var(--grey100);
      margin-left: 0;
    }
  }

  &__radio {
    display: flex;
    align-items: center;

    &_disabled {
      opacity: 0.65;
    }
  }

  &__time {
    &-wrapper {
      display: flex;
      flex-direction: column;
      font-size: $sm-text;
    }

    &-switch {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $sm-text;
      grid-gap: $indent-xxs;
    }
  }

  &__warning {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 5px;
  }
}
