@use "./src/shared/assets/scss/variables/index.scss" as *;
.ripple {
  z-index: 0;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;
  inset: 0;

  &__item {
    position: absolute;
    transform: scale(0);
    border-radius: 100%;
    opacity: 0.25;
    animation-name: ripple;
    animation-duration: 800ms;

    &_default {
      background-color: var(--common-white);
    }

    &_outline {
      background-color: var(--blue-120);
    }

    &_flat {
      background-color: var(--blue-120);
    }
  }

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
}
