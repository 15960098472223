@use "./src/shared/assets/scss/variables/index.scss" as *;
.sla-percent {
  margin-right: $indent-xxxl;
  padding: $indent-xxxl 20px;

  &__title {
    color: var(--grey120);
  }

  &__value {
    display: inline-block;
  }

  &__percent {
    display: inline-block;
  }

  &__no-data {
    margin-right: $indent-xxxl;
    color: var(--grey120);
    text-transform: uppercase;
    font-size: $xxs-text;
    font-weight: 500;
    letter-spacing: 1px;
  }

  &-icon {
    color: var(--common2);

    &__container {
      width: 40px;
      height: 40px;
      margin-bottom: $indent-xxxl;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius-sm;
      background-color: var(--green80);
    }
  }

  &__chart {
    width: 50%;
  }

  @media screen and (max-width: 1600px) {
    &__chart {
      flex-basis: auto;
      width: 100%;
    }
  }
}

[data-theme|='dark'] {
  .sla-percent {
    &-icon {
      color: var(--grey200);
    }
  }
}
