@use "./src/shared/assets/scss/variables/index.scss" as *;
.news-modal-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 200px;

  &__container {
    padding: $indent-lg $indent-xl;
    border-bottom: 1px solid var(--grey20);
    display: flex;
    flex-direction: column;
    row-gap: $indent-xs;
  }

  &__header {
    display: flex;
  }

  &__organization-selects,
  &__time-selects {
    display: grid;
    grid-template-columns: 160px 1fr 1fr;
    grid-gap: $indent-xs;
  }

  &__select {
    flex-basis: 28%;
  }

  &__content {
    flex-basis: 100%;
    overflow-y: auto;
    min-height: 200px;
    padding: $indent-lg $indent-xl $indent-xl $indent-xl;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    align-items: center;
    grid-gap: $indent-xs;
    margin-top: $indent-sm;
  }

  @media screen and (max-width: 768px) {
    &__time-selects {
      grid-template-columns: 160px 1fr;
    }

    &__content {
      padding: $indent-xl $indent-lg;
    }

    &__buttons {
      flex-direction: column-reverse;
      grid-gap: $indent-xxs;
    }

    &__button {
      width: 100%;
    }
  }
}
