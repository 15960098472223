@use "./src/shared/assets/scss/variables/index.scss" as *;
.tickets-page {
  max-height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 68px);
  }
}
