@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-create-article-header-form {
  display: flex;
  flex-direction: row;
  grid-gap: $indent-md;
  padding-right: $indent-lg;
  width: 100%;

  &__selects {
    display: flex;
    grid-gap: $indent-md;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  &__is-solution {
    display: none;
  }

  &__select {
    max-width: 240px;
    min-width: 190px;

    &-slide {
      max-width: 500px;
      min-width: 500px;
    }

    &-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 1670px) {
    &__select {
      &-slide {
        min-width: 400px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
    padding-right: 0;

    &__selects {
      width: 100%;
      flex-direction: column;
    }

    &__select {
      width: 100;
      max-width: none;
      min-width: auto;

      &-slide {
        width: 100%;
        max-width: none;
        min-width: auto;
      }
    }

    &__is-solution {
      display: flex;
    }
  }
}
