@use "./src/shared/assets/scss/variables/index.scss" as *;
.tickets-from-desktop-history-table {
  &__cell {
    white-space: nowrap;
    display: flex;
    align-items: center;

    &-item {
      margin-right: 8px;
    }
  }

  &__sla-cell {
    display: flex;
    align-items: center;
  }

  &__clock {
    color: var(--red70);
    margin-right: $indent-xs;
    width: 18px;
    height: 18px;
  }
}
