@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integrations-statuses {
  &__text {
    color: var(--grey200);
  }

  &__content {
    padding-top: $indent-md;
  }

  &__status-block {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40%;
  }

  @media screen and (max-width: 576px) {
    &__status-block {
      max-width: 41%;
    }
  }

  @media screen and (max-width: 360px) {
    &__status-block {
      max-width: 34%;
    }
  }
}
