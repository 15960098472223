@use "./src/shared/assets/scss/variables/index.scss" as *;
.custom-node {
  display: flex;
  align-items: center;
  height: 34px;
  padding-left: $indent-sm;
  padding-right: $indent-md;
  border: 1px solid var(--grey30);
  border-radius: $border-radius-sm;
  background-color: var(--common2);
  transition: border-color $transition, background-color $transition;

  $this: &;

  &:hover {
    &:not(#{$this}_selected) {
      border-color: var(--grey120);
    }
    & #{$this}__handle {
      opacity: 1;
      visibility: visible;
    }
  }

  &_selected {
    border: 2px solid var(--blue90);
    background-color: var(--blue10);
    & #{$this} {
      &__handle {
        opacity: 1;
        visibility: visible;
      }

      &__label {
        font-weight: 500;
      }
    }
  }

  &__icon {
    width: 8px;
    height: 8px;
    border-radius: $border-radius-xs;
  }

  &__label {
    margin-left: $indent-xs;
    color: var(--grey180);
  }

  &__handle {
    background-repeat: no-repeat;
    min-width: 16px;
    min-height: 16px;
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition;
  }

  @media screen and (max-width: 1024px) {
    &__handle {
      opacity: 1;
      visibility: visible;
    }
  }
}

[data-theme='light'] {
  .custom-node {
    &__handle {
      background-image: url("icons/arrow-light.svg");

      &:hover {
        background-image: url("icons/arrow-active-light.svg");
      }
    }
  }
}

[data-theme='light-contrast'] {
  .custom-node {
    &__handle {
      background-image: url("icons/arrow-light-contrast.svg");

      &:hover {
        background-image: url("icons/arrow-active-light-contrast.svg");
      }
    }
  }
}

[data-theme='dark'] {
  .custom-node {
    &__handle {
      background-image: url("icons/arrow-dark.svg");

      &:hover {
        background-image: url("icons/arrow-active-dark.svg");
      }
    }
  }
}

[data-theme='dark-contrast'] {
  .custom-node {
    &__handle {
      background-image: url("icons/arrow-dark-contrast.svg");

      &:hover {
        background-image: url("icons/arrow-active-dark-contrast.svg");
      }
    }
  }
}
