@use "./src/shared/assets/scss/variables/index.scss" as *;
.pie-chart-block {
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  //height: 100%;
  //transition: height $transition ease-in-out;
  width: calc(50% - 6px);

  &__content {
    height: 100%;
    overflow: auto;
    min-width: 100px;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background-color: var(--common2);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $indent-xl;
  }

  &__switch {
    display: flex;
    flex-direction: row;
    grid-gap: $indent-sm;

    &-item {
      display: flex;
      align-items: center;
      padding: $indent-xs;
      border-radius: $border-radius;
      background-color: var(--common2);
      color: var(--blue120);
      transition:
        color $transition ease-in-out,
        background-color $transition ease-in-out;

      &:hover:not(&_active) {
        background-color: var(--blue10);
        color: var(--blue120);
        transition: all $transition ease-in-out;
      }

      &_active {
        background-color: var(--blue120);
        color: var(--common2);
        transition:
          color $transition ease-in-out,
          background-color $transition ease-in-out;

        &:hover {
          opacity: 0.8;
          transition: opacity $transition ease-in-out;
        }
      }
    }
  }

  &__pie-chart {
    width: 400px;
  }

  &_close {
    height: $table-header-height;
    overflow: hidden;
  }

  @media screen and (max-width: 1200px) {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
    border-bottom: 2px solid var(--grey20);
    min-width: 0;

    &__content {
      position: relative;
      border-radius: 0;
      padding: 0 $indent-sm;
      align-items: center;
    }

    &__footer {
      justify-content: center;
      padding: 0;
      min-height: 48px;
    }

    &__header {
      min-height: auto;
    }
  }
}
