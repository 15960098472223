@use "./src/shared/assets/scss/variables/index.scss" as *;
.current-user-adaptive {
  &__icon-wrapper {
    min-width: 32px;
    height: 32px;
    border-radius: $border-radius;
  }

  &__info {
    font-size: $xs-text;

    &-block {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    color: var(--grey100),;
  }
}
