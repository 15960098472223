@use "./src/shared/assets/scss/variables/index.scss" as *;
.card {
  background: var(--common2);
  border-radius: $border-radius;
  padding: $indent-xl;

  @media screen and (max-width: 768px) {
    padding: 18px;
  }
}
