@use "./src/shared/assets/scss/variables/index.scss" as *;
.status-chain {
  &__wrapper {
    background-color: var(--blue10);
    border-radius: $border-radius-md;
    min-height: 70px;
    padding: 0 $indent-md;
    display: flex;
    align-items: center;
    margin-bottom: $indent-md;
  }

  &__icon {
    color: var(--blue120);
    margin-right: $indent-sm;
    min-width: 24px;
  }

  &__description {
    color: var(--grey200);
    margin-right: $indent-md;

    &_text {
      margin-right: $indent-xxs;
    }
  }

  &__button {
    min-width: 100px;
  }
}
