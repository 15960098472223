@use "./src/shared/assets/scss/variables/index.scss" as *;
.bread-crumbs {
  display: flex;
  align-items: center;
  user-select: none;

  &__item {
    margin-right: $indent-md;

    &-btn {
      transform: rotate(-90deg);
      margin-right: $indent-md;
      color: var(--grey200);
    }
  }

  &__link {
    color: var(--grey120);
    white-space: nowrap;
    transition: color $transition;

    &_clickable {
      &:hover {
        color: var(--blue120);
        opacity: 1;
      }
    }
  }
}
