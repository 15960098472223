@use "./src/shared/assets/scss/variables/index.scss" as *;
.tickets-filter {
  &__cell {
    overflow: inherit;

    &:first-child {
      position: sticky;
      left: 0;
      z-index: $z-index-filter;
      background-color: var(--grey20);
    }
  }
}
