@use "./src/shared/assets/scss/variables/index.scss" as *;
.sla-table-header {
  display: flex;
  min-height: 58px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-left: 26px;
  font-weight: 500;
  font-size: $md-text;
  box-shadow: inset 0 -2px 0 0 var(--grey20);
  transition: box-shadow $transition ease-in-out;

  &__title {
    margin-right: $indent-md;
  }

  &__data {
    display: flex;
    align-items: center;
    grid-gap: $indent-xs;
    flex-wrap: wrap;
  }

  &__auto-closing {
    background-color: var(--purple20);
    color: var(--purple120);
  }

  &__tag {
    grid-gap: $indent-xs;
    height: 24px;
  }

  @media screen and (max-width: 768px) {
    &__change {
      svg {
        margin-right: 0;
        width: 20px;
        height: 20px;
      }

      &-title {
        display: none;
      }
    }
  }
}
