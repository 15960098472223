@use "./src/shared/assets/scss/variables/index.scss" as *;
.text-area {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  $this: &;

  &__container {
    display: inline-block;
    position: relative;
    height: 14rem;
    width: 100%;
  }

  &__item {
    color: var(--grey200);
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid var(--grey30);
    border-radius: $border-radius-sm;
    font-family: inherit;
    font-size: $sm-text;
    outline: none;
    padding-left: 0.95rem;
    background: none;
    z-index: $z-index-10;
    resize: none;
    transition: border-color 0.3s;
    padding-top: 1.5rem;

    &:focus ~ #{$this}__label, &:not(:placeholder-shown)#{$this}__item:not(:focus) ~ #{$this}__label {
      top: 0.25rem;
      font-size: $xs-text;
    }
    // TODO: узнать, где это используется
    // &:focus,
    // &:not(:placeholder-shown)#{$this}__item:not(:focus) {
    //   padding-top: 1.5rem;
    // }

    &:hover:not(#{$this}__item_error):not(:disabled) {
      border-color: var(--grey100);
    }

    &:focus:not(#{$this}__item_error):not(:disabled) {
      border-color: var(--blue120);
    }

    &_error {
      border-color: var(--red120);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--grey130);
      background: var(--grey10);
      border-color: var(--grey10);
      &~ #{$this}__label,
      &:not(:placeholder-shown):disabled#{$this}__item:not(:focus):disabled ~ #{$this}__label {
        color: var(--grey80);
        z-index: $z-index-20;
      }
    }
  }

  &__label {
    z-index: $z-index-10;
    position: absolute;
    left: 0.95rem;
    top: 1rem;
    color: var(--grey100);
    cursor: text;
    font-style: normal;
    font-weight: 350;
    font-size: $md-text;
    line-height: 18px;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  }

  &__error-message {
    color: var(--red120);
    font-size: $sm-text;
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    &__container {
      height: 9rem;
    }
  }
}
