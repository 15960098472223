@use "./src/shared/assets/scss/variables/index.scss" as *;
.phone-input {
  position: relative;

  [id=phoneNumber] {
    padding-left: 110px;
    padding-top: 0;
  }
}

.select {
  position: absolute;
  left: 0;
  z-index: $z-index-20;

  &::after {
    content: "";
    background-color: var(--grey30);
    width: 1px;
    height: 24px;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
  }
}
