@use "./src/shared/assets/scss/variables/index.scss" as *;
.date-picker {
  display: flex;
  box-shadow: var(--box-shadow-lvl-2);
  flex-direction: column;
  background: var(--common2);
  border-radius: $border-radius;
  width: 272px;
  min-height: 300px;

  &_range {
    column-gap: $indent-xl;
    width: 720px;
    background: var(--common2);
    border-radius: $border-radius;
  }

  &__main {
    display: flex;
    row-gap: $indent-sm;
    justify-content: space-around;
    width: 272px;

    &_range {
      column-gap: $indent-xl;
      width: 720px;
      background: var(--common2);
      border-radius: $border-radius;
    }

    &-wrapper {
      width: 100%;
      row-gap: $indent-sm;
      display: flex;
      flex-direction: column;
      padding: $indent-lg;

      &_range {
        padding: $indent-lg 0 $indent-lg $indent-xl;
      }
    }

    &-content {
      display: flex;
      row-gap: $indent-sm;
      width: inherit;
      justify-content: center;

      &_range {
        column-gap: $indent-xl;
        width: auto;
      }
    }
  }

  &__typography-link {
    text-align: left;
    width: max-content;
  }

  &__sidebar {
    width: inherit;
    padding: $indent-lg $indent-xl;
    border-left: 1px solid var(--grey20);
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    box-shadow: 0 0 55px rgba(33, 39, 44, 0.1);
    border-radius: $border-radius $border-radius 0 0;
    animation-name: slide-in;
    justify-content: space-between;
    animation-duration: 0.5s;
    width: 100%;
    height: 100vh;

    &_range {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      padding: 0;
      width: 100%;
      flex-direction: column;
      border-radius: 0;

      &:first-child {
        margin-top: 0;
      }
    }

    &__main {
      width: 100%;
      height: auto;
      overflow: hidden;
      padding: $indent-lg $indent-lg 0 $indent-lg;

      &_range {
        display: flex;
        height: 100%;
        padding: 0;
        width: 100%;
        flex-direction: column;
        border-radius: 0;

        &:first-child {
          margin-top: 0;
        }
      }

      &-wrapper {
        &_range {
          padding: 0;
        }
      }

      &-content {
        &_range {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          column-gap: $indent-xl;
          width: auto;
          height: 100%;
          overflow: hidden;
          overflow-y: scroll;
          padding: $indent-xl $indent-lg 0 $indent-lg;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    &__typography-link {
      padding-left: $indent-xl;
    }

    &__sidebar {
      border-left: 0;
      border-top: 1px solid var(--grey20);
    }
  }

  @keyframes slide-in {
    from {
      transform: translateY(170px);
    }

    to {
      transform: translateY(0);
    }
  }
}
