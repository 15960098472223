@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-history {
  display: flex;
  flex-direction: column;

  &__title {
    position: relative;
    height: 60px;
    min-height: 60px;
    font-weight: 500;
    font-size: $md-text;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 $indent-lg;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: $indent-sm;
    border-bottom: 2px solid var(--grey20);
  }

  &__tabs {
    box-shadow: none;
  }

  &__tab {
    margin-bottom: -2px;

    &_no-hover {
      &:hover {
        border-bottom: 2px solid transparent;
      }
    }
  }

  &__plus-icon {
    width: 16px;
  }

  &__wrapper {
    flex-basis: 100%;
    padding: $indent-lg;
    background-color: var(--grey10);
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    overflow-y: auto;

    &_notes {
      background-color: var(--common2);
    }

    &_loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__history-tab {
    position: relative;

    &:not(:last-child) {
      margin-bottom: $indent-xxxl;
    }

    &::after {
      content: '';
      display: block;
      border: 2px dashed var(--grey90);
      width: 1px;
      height: 50px;
      position: absolute;
      top: 65px;
      left: 25px;
      border-bottom: none;
      border-left: none;
      border-top: none;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &_with-sub-status {
      &::after {
        top: 65px;
        height: 30px;
      }
    }
  }
}
