@use "./src/shared/assets/scss/variables/index.scss" as *;
.popover-button {
  transition: background-color $transition;
  position: relative;
  font-weight: 400;
  font-size: $sm-text;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 $indent-xl;
  overflow: hidden;
  min-height: 32px;
  width: 100%;

  $this: &;

  &-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: $indent-xs;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--grey10);
  }

  &:focus {
    outline: 2px solid var(--blue180);
  }

  &_disabled {
    opacity: 0.5;
    cursor: not-allowed;

    #{$this}-icon {
      svg {
        color: var(--grey60);
      }
    }
  }
}

[data-theme|='dark'] {
  .popover-button {
    color: white;
  }
}
