@use "./src/shared/assets/scss/variables/index.scss" as *;
.storages {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  border-radius: $border-radius;
  transition: height $transition ease-in-out;
  overflow: auto;

  &-list {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    padding: $indent-lg;
    background-color: var(--grey10);
    overflow-y: auto;
  }
}
