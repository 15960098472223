@use "./src/shared/assets/scss/variables/index.scss" as *;
.rule-modal-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  grid-gap: $indent-sm;
  padding: $indent-lg $indent-xl;
  justify-content: space-between;
  overflow-y: auto;

  &__title-input {
    margin-bottom: $indent-xxxl;
  }

  &__sub-title {
    margin-bottom: $indent-lg;
  }

  &__row {
    display: flex;
    grid-gap: $indent-sm;
    justify-content: space-between;
    margin-bottom: $indent-lg;
  }

  &__select {
    &-attribute {
      max-width: 148px;
    }

    &-condition {
      min-width: 280px;
    }
  }

  &__close-button {
    line-height: 0;

    &-icon {
      width: 24px;
      height: 24px;
      color: var(--grey50);
    }
  }

  &__separator {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $md-text;
    line-height: 20px;
    background-color: var(--grey10);
    position: relative;
    margin: $indent-lg auto;

    &::after,
    &::before {
      content: " ";
      background-color: var(--grey20);
      width: 155px;
      height: 1px;
      vertical-align: middle;
      position: absolute;
    }

    &::after {
      left: 42px;
    }

    &::before {
      right: 42px;
    }
  }

  &__radio {
    margin-bottom: $indent-lg;
  }

  &__footer {
    display: flex;
    grid-gap: $indent-xs;
  }

  @media screen and (max-width: 768px) {
    padding: $indent-xl $indent-lg;

    &__separator {
      &::after,
      &::before {
        width: 35vw;
      }
    }

    &__footer {
      flex-direction: column-reverse;
      grid-gap: $indent-xxs;
    }

    &__button {
      width: 100%;
    }
  }
}
