@use "./src/shared/assets/scss/variables/index.scss" as *;
.typography {
  font-weight: 400;

  &_d1,
  &_d2 {
    font-family: $ALS-Hauss;
    font-weight: 700;
  }

  &_d1 {
    font-size: 48px;
    line-height: 72px;
  }

  &_d2 {
    font-size: 34px;
    line-height: 51px;
  }

  &_h1,
  &_h2,
  &_h3,
  &_h4,
  &_h5,
  &_h6 {
    font-weight: 500;
  }

  &_h1 {
    font-size: 28px;
    line-height: 35px;
  }

  &_h2 {
    font-size: $xl-text;
    line-height: 30px;
  }

  &_h3 {
    font-size: $lg-text;
    line-height: 24px;
  }

  &_h4 {
    font-size: $md-text;
    line-height: 20px;
  }

  &_h5 {
    font-size: $sm-text;
    line-height: 18px;
  }

  &_h6 {
    font-size: $xs-text;
    line-height: 15px;
  }

  &_b1 {
    font-size: $lg-text;
    line-height: 24px;
  }

  &_b2 {
    font-size: $md-text;
    line-height: 20px;
  }

  &_b3 {
    font-size: $sm-text;
    line-height: 18px;
  }

  &_s2 {
    font-size: $xs-text;
    line-height: 15px;
  }

  &_o {
    font-weight: 500;
    font-size: $xs-text;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &_other {
    font-weight: 500;
    font-size: $xxs-text;
    line-height: 14px;
  }
}
