@use "./src/shared/assets/scss/variables/index.scss" as *;
.footer {
  align-items: center;
  border-top: 1px solid var(--grey20);
  background-color: var(--common2);
  display: flex;
  justify-content: space-between;
  padding: $indent-sm $indent-xl;
  border-radius: 0 0 $border-radius $border-radius;

  &__btns {
    display: flex;
    gap: 0 $indent-md;

    &_no-range {
      width: 100%;
      gap: 0 $indent-xs;
    }
  }

  &__btn {
    &_no-range {
      flex-basis: 50%;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: $indent-lg;
    max-height: 159px;
    justify-content: center;

    &__btns {
      display: block;
      width: 100%;
    }

    &__btn {
      width: 100%;
      margin-top: 4px;
    }
  }
}
