@use "./src/shared/assets/scss/variables/index.scss" as *;
.info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: $z-index-modal;
  box-shadow: var(--box-shadow-lvl-4);
  background-color: var(--transparent1);
  animation-name: appear;
  animation-duration: 300ms;

  &__content {
    width: 368px;
    position: relative;
    max-height: 90vh;
    background: var(--common2);
    display: flex;
    flex-direction: column;
    animation-name: slide-in;
    animation-duration: 0.5s;
    border-radius: $border-radius;
  }

  &__header {
    background-color: var(--grey10);
    padding: $indent-xl $indent-xl $indent-lg $indent-xl;
    border-radius: $border-radius $border-radius 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 500;
    font-size: $lg-text;
    color: var(--grey200);
  }

  &__buttons-block {
    margin: auto $indent-md $indent-md;
    display: flex;
    justify-content: flex-end;
  }

  &__cancel-button {
    margin-right: 5px;
  }

  &__cross {
    color: var(--grey100);
  }

  &__button {
    position: absolute;

    &_left {
      display: none;
    }

    &_right {
      right: 30px;
    }
  }

  @keyframes appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes slide-in {
    from {
      transform: translateY(-70px);
    }

    to {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 768px) {
    animation-name: none;
    align-items: flex-end;

    &__content {
      width: 100%;
      max-height: none;
      border-radius: $border-radius $border-radius 0 0;
      animation-name: none;
    }

    &__form-list {
      padding: $indent-lg;
      flex-basis: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }

    &__buttons-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__cancel-button {
      margin-right: 0;
      margin-bottom: $indent-xxs;
    }

    &__header {
      justify-content: space-between;
      background-color: var(--common2);
    }

    &__title {
      font-weight: 500;
      font-size: $md-text;
      color: var(--grey100);
    }

    &__button {
      &_left {
        display: block;
        left: $indent-lg;
      }

      &_right {
        display: none;
      }
    }
  }
}
