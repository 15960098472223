@use "./src/shared/assets/scss/variables/index.scss" as *;
.tickets-full-table {
  &__cell {
    white-space: nowrap;
    display: flex;
    align-items: center;

    &-item {
      margin-right: 8px;
    }
  }

  &__icon {
    min-width: 25px;

    &_grey {
      color: var(--grey80);
    }
  }

  @media screen and (max-width: 768px) {
    &__current-ticket {
      border-top: none;
      background-color: var(--grey10);

      &-cell {
        padding: $indent-sm;
      }
    }
  }
}
