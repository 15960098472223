@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integration-create {
  width: 1154px;

  &__wrapper {
    display: flex;
    border-bottom: 1px solid var(--grey20);
  }

  @media screen and (max-width: 1200px) {
    width: 90%;

    &__wrapper {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
