@use "./src/shared/assets/scss/variables/index.scss" as *;
.group-specialist-form {
  padding: $indent-xl $indent-xs 0 0;
  border-top: 1px solid var(--grey20);
  display: flex;
  flex-direction: column;
  grid-gap: $indent-md;

  &__switch {
    &-wrapper {
      display: flex;
      align-items: center;
      grid-gap: $indent-xs;
      padding: 0 $indent-xxs;
    }

    &-text {
      color: var(--grey200);
      font-weight: 500;
      font-size: $md-text;
      line-height: 20px;
    }
  }

  &__sub-title {
    color: var(--grey120);
  }

  &__select-menu {
    transform: translate(0, 0%);
    bottom: 48px;
    top: auto;
    border-radius: $border-radius-sm $border-radius-sm 0 0;
  }
}
