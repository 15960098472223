@use "./src/shared/assets/scss/variables/index.scss" as *;
.storage-header {
  margin-bottom: $indent-sm;
  padding-left: $indent-lg;
  height: 78px;
  display: flex;
  grid-gap: $indent-xxs;
  align-items: center;
  border-radius: $border-radius;
  background-color: var(--common2);

  &__select {
    width: 250px;
  }
}
