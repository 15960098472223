@use "./src/shared/assets/scss/variables/index.scss" as *;
.row {
  display: table-row;

  &:not(:last-child) {
    box-shadow: inset 0 -1px 0 0 var(--grey20);
  }

  &_disabled {
    opacity: 0.65;
  }
}
