@use "./src/shared/assets/scss/variables/index.scss" as *;
.news {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &__icon {
    white-space: nowrap;
    margin-top: 6px;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-sm;
    background-color: var(--yellow120);
    margin-right: $indent-xs;
    color: var(--common2);

    & svg {
      width: 16px;
      height: 16px;
    }
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: $indent-sm;

    &-text {
      display: flex;
      flex-direction: column;
      max-width: 80%;
    }
  }

  &__circle {
    margin-right: $indent-xxs;
    min-width: 8px;
    background-color: var(--yellow100);

    &::after,
    &::before {
      background-color: var(--yellow100);
    }

    &_hide {
      display: none;
    }
  }

  &__title {
    color: var(--grey200);
    white-space: nowrap;
    margin-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__text {
    font-size: $sm-text;
    color: var(--grey120);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 15px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: $indent-sm;
  }

  &__time {
    white-space: nowrap;
    margin-left: $indent-xs;
    font-size: $xs-text;

    &-icon {
      width: 12px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      color: var(--grey90);
    }
  }

  &__popover {
    & svg {
      color: var(--grey80);
    }
  }

  &__right-wrapper {
    display: flex;
    grid-gap: $indent-xs;
    align-items: center;
  }

  &__icon-delete {
    color: var(--red100);
  }
}

[data-theme|='dark'] {
  .news__icon {
    color: var(--grey180);
  }
}

[data-theme='light-contrast'] {
  .news {
    &__text {
      color: var(--grey180);
    }
  }
}
