@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-categories-list {
  display: grid;
  grid-gap: 50px;
  padding: 58px;
  justify-content: center;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 300px);

  &_loading {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    padding: 58px $indent-xs;
  }
}
