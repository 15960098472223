@use "./src/shared/assets/scss/variables/index.scss" as *;
.country-select {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    position: relative;
    display: inline-block;
  }

  &__toggle {
    cursor: pointer;
    user-select: none;
  }

  &__caret {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 2px;
      display: block;
      width: 9px;
      height: 2px;
      transition: all 0.2s ease-out;
      background-color: var(--grey100);
      transform: translate(-2px, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(3px, -50%) rotate(-45deg);
    }

    &_open {
      &::before {
        transform: translate(-2px, -50%) rotate(-45deg);
      }

      &::after {
        transform: translate(3px, -50%) rotate(45deg);
      }
    }
  }

  &__menu {
    padding: 0;
    margin: 0;
    overscroll-behavior: none;
    display: block;
    position: absolute;
    max-height: 0;
    left: 0;
    top: 100%;
    background-color: var(--common2);
    transition: max-height 0.2s ease-in, padding 0.2s ease-in, overflow 1.5s ease-in,;
    overflow: hidden;
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: var(--box-shadow-lvl-2);
    z-index: $z-index-menu;

    &_open {
      overflow: auto;
      padding: $indent-xs 0;
      transition: max-height 0.2s ease-in, padding 0.2s ease-in, overflow 1.5s ease-in;

      &_xs {
        max-height: 176px;
      }

      &_s {
        max-height: 216px;
      }

      &_m {
        max-height: 256px;
      }

      &_l {
        max-height: 296px;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    white-space: inherit;
  }

  &__flag {
    min-width: 76px;
  }

  &__label {
    color: var(--grey120);
  }

  &__separator {
    width: 100%;
    height: 1px;
    background-color: var(--grey30);
    margin: $indent-xxs 0;
  }

  &_selected {
    display: flex;
    align-items: center;
    padding: 0 $indent-sm;
    outline: none;
    user-select: none;
    width: 88px;
    height: 48px;
  }

  &__img {
    width: 20px;
    height: 14px;
    margin-right: $indent-xs;
  }
}
