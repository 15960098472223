@use "./src/shared/assets/scss/variables/index.scss" as *;
.lazy-loader {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-grow: 1;
  }
}
