@use "./src/shared/assets/scss/variables/index.scss" as *;
.date-content {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: $indent-xs;
    color: var(--grey80);
  }
}
