@use "./src/shared/assets/scss/variables/index.scss" as *;
.desktop-header {
  height: 78px;
  display: flex;
  padding: 0 $indent-xl;
  border-radius: $border-radius;
  width: 100%;
  background-color: var(--common2);

  &__left-container {
    display: flex;
    align-items: center;
    border-right: 1px solid var(--grey20);
  }

  &__title {
    color: var(--grey200);
    margin-right: $indent-xxxl;
    white-space: nowrap;
  }

  &__tabs {
    display: flex;
    margin-top: $indent-xxxl;

    &-wrapper {
      flex-basis: 100%;
      overflow: auto;
      padding-right: 20px;

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
    }
  }

  &__tab {
    padding-right: $indent-xs;
    padding-left: $indent-xs;
    height: 46px;

    &_hidden {
      display: none;
    }
  }

  &__right-container {
    padding-left: 48px;
    flex-basis: 100%;
    display: flex;
    align-items: center;
  }

  &__radio {
    min-width: 256px;
    max-width: 320px;
    margin-right: $indent-sm;
  }

  &__select {
    min-width: 100px;
    max-width: 320px;

    &:not(:last-child) {
      margin-right: $indent-sm;
    }

    &:hover:not(&_open):not(&_disabled) {
      border-color: var(--grey100);
    }

    &:focus {
      border-color: var(--blue120);
      outline: none;
    }
  }

  &__multi-select {
    min-width: 100px;
    max-width: 320px;

    &:not(:last-child) {
      margin-right: $indent-sm;
    }

    &:hover:not(&_open):not(&_disabled) {
      border-color: var(--grey100);
    }

    &:focus {
      border-color: var(--blue120);
      outline: none;
    }
  }

  &__drop {
    display: none;
  }

  @media screen and (max-width: 2150px) {
    &__multi-select {
      min-width: 100px;
      max-width: 200px;
    }
  }

  @media screen and (max-width: 1900px) {
    &__multi-select {
      min-width: 100px;
      max-width: 150px;
    }
  }

  @media screen and (max-width: 1750px) {
    &__multi-select {
      min-width: 100px;
      max-width: 110px;
    }
  }

  @media screen and (max-width: 1600px) {
    height: auto;
    flex-direction: column;

    &__multi-select {
      min-width: 100px;
      max-width: 320px;
    }

    &__left-container {
      border-right: none;
      box-shadow: inset 0 -2px 0 0 var(--grey20);
    }

    &__right-container {
      padding: $indent-md 0;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    flex-direction: row;

    &__left-container {
      flex-direction: column;
      padding-right: 0;
      width: 100%;
      box-shadow: none;
    }

    &__radio {
      max-width: none;
      min-width: auto;
      margin-right: 0;
    }

    &__right-container {
      display: none;
    }

    &__title {
      font-size: $lg-text;
      width: 100%;
      text-align: center;
      margin-right: 0;
      padding: $indent-md 0;
      border-bottom: 1px solid var(--grey20);
    }

    &__drop {
      display: block;
      position: absolute;
      right: 24px;
      top: 12px;

      &-content-wrapper {
        padding: $indent-xxxl $indent-xl;
      }
    }

    &__tabs {
      overflow: auto;
      width: 100%;
      margin-top: $indent-sm;
      box-shadow: inset 0 -2px 0 0 var(--grey20);

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }

      &-wrapper {
        width: 100%;
        padding: 0 $indent-sm;
      }
    }

    &__tab {
      padding: 0 $indent-xs $indent-sm $indent-xs;
    }

    &__multi-select {
      min-width: auto;
      max-width: none;
      width: 100%;
      margin-right: 0;
      margin-top: 10px;
    }

    &__select {
      min-width: auto;
      max-width: none;
      width: 100%;
      margin-right: 0;
      margin-top: 10px;
    }
  }
}
