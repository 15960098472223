@use "./src/shared/assets/scss/variables/index.scss" as *;
.info-header {
  --header-height: 102px;

  padding: 0;
  display: flex;
  height: var(--header-height);

  &__title {
    white-space: nowrap;
    margin-bottom: $indent-sm;

    &-wrapper {
      display: flex;
      align-items: center;
      column-gap: $indent-xxs;
    }

    &_no-mb {
      margin-bottom: 0;
    }

    &-info-block {
      background-color: var(--blue120);
      min-width: 28px;
      height: 28px;

      &-wrapper {
        margin-right: $indent-sm;
      }
    }

    &-block {
      border-left: $border-radius-sm solid transparent;
      padding-left: $indent-lg;
      padding-right: $indent-xl;
      border-radius: $border-radius-sm;
      height: 100%;
      display: flex;
      align-items: center;

      &-wrapper {
        border-right: 1px solid var(--grey20);
        padding: $indent-xxs;

        &-mobile {
          display: flex;
          justify-content: center;
        }
      }

      &-sub-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-left: $indent-md;
      }

      &-mobile {
        width: 100%;
        padding: $indent-md $indent-md;
        display: flex;
        align-items: center;
      }

      &_center {
        align-items: center;
      }
    }
  }

  &__comments-modal {
    position: absolute;
    right: 20px;

    &-active {
      display: none;
    }
  }

  &__title-mobile {
    margin-bottom: 0;
  }

  &__sub-title {
    margin-left: $indent-xxs;
    font-size: $xs-text;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--grey120);
    white-space: nowrap;
    letter-spacing: 1px;

    &-active {
      display: none;
    }

    &_button {
      color: var(--blue120);
      transition: opacity $transition;

      &:hover {
        opacity: 0.8;
      }

      &:focus {
        outline: 2px solid var(--blue180);
        border-radius: $border-radius-sm;
      }
    }
  }

  &__arrow {
    height: fit-content;
    margin-top: 6px;
    padding: 3px;
    border-radius: $border-radius-sm;
    color: var(--grey90);

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      outline: 2px solid var(--blue180);
    }
  }

  &__info {
    &-block {
      display: flex;

      &-wrapper {
        padding-right: $indent-xxl;
        padding-left: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-basis: 100%;
        overflow-x: auto;
      }
    }

    &-item {
      &:not(:last-child) {
        margin-right: 64px;

        &::after {
          content: '';
          display: inline-block;
          width: 1px;
          height: 20px;
          background-color: var(--grey30);
          position: absolute;
          right: -$indent-xxxl;
        }
      }

      &-wrapper {
        padding: $indent-sm $indent-md;
        display: flex;
        align-items: center;
        position: relative;
        border-radius: $border-radius-sm;
        transition: background-color $transition;

        &:hover {
          cursor: pointer;
          background-color: var(--grey10);
        }
      }

      &-arrow {
        color: var(--grey100);
        margin-left: 30px;
        width: 12px;
        height: 12px;
        transition: transform $transition;

        &_open {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__adaptive-info {
    display: none;
  }

  &__bread-crumbs {
    margin-top: $indent-sm;
  }

  &__popover {
    margin: $indent-xxxl $indent-xl;
  }

  &__status-popover {
    display: flex;
    align-items: center;
  }

  &__loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-wrapper {
      height: var(--header-height);
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    height: auto;
    border-radius: 0;

    &__title {
      font-size: $lg-text;
      text-overflow: ellipsis;
      max-width: 54vw;
      overflow: hidden;

      &-label {
        margin-bottom: 0;
      }

      &-block {
        padding: 18px;
        align-items: center;
        justify-content: center;
        border-left: none;

        &-sub-wrapper {
          margin-left: 0;
          flex-basis: 100%;
          justify-content: center;
          align-items: center;
        }
      }

      &-info {
        &-block {
          min-height: 20px;
          min-width: 20px;
          height: auto;

          & svg {
            width: 14px;

            & path {
              stroke-width: 2.5;
            }
          }
        }
      }
    }

    &__bread-crumbs {
      display: none;
    }

    &__info-block-wrapper {
      height: 48px;
      padding: 0 $indent-lg;
      display: flex;
      align-items: center;
      border-top: 2px solid var(--grey20);
      border-bottom: 2px solid var(--grey20);

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
    }

    &__info-item {
      margin-right: 48px;

      &:last-child {
        padding-right: $indent-md;
        margin-right: 0;
      }

      &:not(:last-child)::after {
        right: -$indent-xl;
      }

      &-text {
        font-size: $xs-text;
      }

      &-wrapper {
        padding: $indent-xxs $indent-xs;
      }
    }

    &__arrow {
      margin-top: $indent-xxs;
      padding: 2px;

      & svg {
        width: 20px;
      }
    }
  }
}
