@use "./src/shared/assets/scss/variables/index.scss" as *;
.number-block {
  display: flex;
  align-items: center;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--purple10);
    border-radius: $border-radius-sm;
    width: 22px;
    height: 22px;
    background-color: var(--orange80);

    &_purple {
      background-color: var(--purple80);
    }

    &_round {
      border-radius: 50%;
    }
  }

  @media screen and (max-width: 768px) {
    &__wrapper {
      font-size: $xs-text;
      width: 20px;
      height: 20px;
    }
  }
}

[data-theme|='dark'] {
  .number-block {
    &__wrapper {
      color: var(--grey200);
    }
  }
}
