@use "./src/shared/assets/scss/variables/index.scss" as *;
.tickets {
  position: relative;
  width: 50%;
  margin-top: $indent-sm;
  border-radius: $border-radius;
  background: var(--common2);

  &__chart {
    width: 50%;

    &-container {
      padding-top: 40px;
    }
  }

  &__header-element {
    width: 279px;
    margin-right: 50px;
  }

  @media screen and (max-width: 1600px) {
    &__chart {
      flex-basis: auto;
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    &__header-element {
      position: absolute;
      margin-top: 50px;
      margin-left: -$indent-sm;
      z-index: $z-index-10;
    }
  }
}
