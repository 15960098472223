@use "./src/shared/assets/scss/variables/index.scss" as *;
.user-assistance-articles-list {
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &__article {
    &:not(:last-child) {
      margin-bottom: $indent-sm;
    }
  }
}
