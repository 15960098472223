@use "./src/shared/assets/scss/variables/index.scss" as *;
.users-table-container {
  &_full {
    height: 100%;
  }

  &_from-group {
    height: 374px;
  }

  &_from-desktop {
    height: 834px;
  }

  &__icon-delete {
    color: var(--red100);
  }

  @media screen and (max-width: 1200px) {
    &_full {
      height: 768px;
    }
  }

  @media screen and (max-width: 1024px) {
    &_from-desktop {
      height: 540px;
    }
  }

  @media screen and (max-width: 768px) {
    &_full {
      height: 354px;
    }

    &_from-group {
      height: 354px;
    }

    &_from-desktop {
      height: 360px;
    }
  }
}
