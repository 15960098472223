@use "./src/shared/assets/scss/variables/index.scss" as *;
.rating-stars {
  display: flex;
  align-items: center;
  user-select: none;

  &__button {
    line-height: 0;
    padding: 0 9px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__icon {
    color: var(--grey60);
    transition: color, fill, $transition;

    &_disabled {
      fill: var(--grey30);
    }

    &_active {
      color: var(--yellow100);
      fill: var(--yellow100);
    }
  }
}
