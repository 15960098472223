@use "./src/shared/assets/scss/variables/index.scss" as *;
.not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &__title {
    font-weight: 700;
    font-size: 144px;
    font-family: $ALS-Hauss;
    color: var(--grey30);
    background-color: var(--common2);
  }

  &__icon {
    position: absolute;
    z-index: -1;
    top: 48px;
    left: 50%;
    transform: translate(-50%, -100%);

    &-wrapper {
      z-index: 1;
      position: relative;
    }
  }

  &__sub-title {
    color: var(--grey200);
    margin-top: 18px;
    text-align: center;
  }

  &__text {
    margin-top: $indent-xl;
    color: var(--grey120);
    width: 353px;
    text-align: center;
  }

  &__buttons {
    margin-top: 40px;
    display: flex;
    align-items: center;
  }

  &__go-back-button {
    margin-right: $indent-xl;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
    padding: 30px 12px;
    justify-content: flex-end;

    &__title {
      font-size: 100px;
    }

    &__sub-title {
      font-size: $xl-text;
    }

    &__text {
      width: auto;
    }

    &__buttons {
      margin-top: 32px;
      flex-direction: column-reverse;
      width: 100%;
    }

    &__icon {
      width: 178px;
      height: 178px;
    }

    &__go {
      &-back-button {
        margin-right: 0;
        width: 100%;
        margin-top: $indent-md;
      }

      &-tickets-button {
        width: 100%;
      }
    }
  }
}
