@use "./src/shared/assets/scss/variables/index.scss" as *;
.triggers-full-table {
  &__title {
    border: none;
    color: var(--blue120);
    transition: opacity $transition;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;

    &:hover {
      opacity: 0.8;
    }
  }
}

[data-theme='light-contrast'] {
  .triggers-full-table {
    &__title {
      color: var(--blue170);
    }
  }
}

[data-theme|='dark'] {
  .triggers-full-table {
    &__title {
      color: #3392ff;
    }
  }
}
