@use "./src/shared/assets/scss/variables/index.scss" as *;
.user-assistance-search {
  padding: $indent-xl $indent-xl 36px $indent-xl;
  background-color: var(--common2);
  display: flex;
  flex-direction: column;

  &__title {
    color: var(--grey160);
    margin-bottom: $indent-xs;
  }

  &__arrow-button {
    color: var(--grey100);
    display: flex;
    align-items: center;
    grid-gap: $indent-xxs;
    font-size: $md-text;
    margin-bottom: $indent-xl;
    transition: opacity $transition;
    width: fit-content;

    &:hover {
      opacity: 0.8;
    }
  }

  &__search-input {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: $indent-xl;
  }
}
