@use "./src/shared/assets/scss/variables/index.scss" as *;
.similar-solutions-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);

  &__header {
    padding: 28px $indent-xl 26px $indent-xl;
  }

  &__search-input {
    width: 100%;
  }

  &__switch {
    &-wrapper {
      display: flex;
      align-items: center;
      margin-top: $indent-md;
    }
  }

  @media screen and (max-width: 768px) {
    height: calc(100% - 52px);
    flex-direction: column-reverse;

    &__header {
      padding: $indent-lg;
    }
  }
}
