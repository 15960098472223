@use "./src/shared/assets/scss/variables/index.scss" as *;
.alert-item {
  min-height: 40px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  max-width: 573px;
  min-width: 573px;
  padding: $indent-xxs 48px $indent-xxs $indent-xxs;
  box-shadow: 1px 1px 4px 0 rgba(105, 48, 43, 0.2);

  &__message {
    padding: $indent-xs 0  $indent-xs $indent-md;
    display: block;
  }

  &__border {
    min-width: 4px;
    height: calc(100% - 8px);
    position: absolute;
    border-radius: 2px;
    background-color: black;
    margin-right: $indent-sm;

    &_success {
      background-color: var(--green120);
    }

    &_error {
      background-color: var(--red120);
    }

    &_warning {
      background-color: var(--yellow120);
    }
  }

  &_success {
    background-color: var(--green20);
    color: var(--green140);
  }

  &_error {
    background-color: var(--red20);
    color: var(--red140);
  }

  &_warning {
    background-color: var(--yellow20);
    color: var(--yellow180);
  }

  &__icon {
    position: absolute;
    top: $indent-xs;
    right: $indent-xs;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    z-index: $z-index-20;
    cursor: pointer;
    transition: background-color $transition;

    &_success {
      color: var(--green120);
    }

    &_error {
      color: var(--red120);
    }

    &_warning {
      color: var(--red120);
    }

    &:hover {
      opacity: 0.6;
    }
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    max-width: 380px;
    min-width: auto;
    margin: 0 auto;
  }
}
