@use "./src/shared/assets/scss/variables/index.scss" as *;
.system-selection {
  border-right: 2px solid var(--grey10);
  border-bottom: 2px solid var(--grey10);
  padding: $indent-xl $indent-xxl $indent-lg $indent-lg;

  &__input {
    margin-bottom: $indent-sm;
  }

  &__header {
    display: block;
    margin-bottom: $indent-lg;
  }

  &__system {
    font-weight: normal;
    font-size: $sm-text;
    line-height: 18px;
    border-bottom: 1px solid var(--grey20);
    padding: $indent-sm;
    transition: background-color $transition;

    &:hover {
      background-color: var(--grey10);
      cursor: pointer;
      transition: background-color $transition;
    }

    &_active,
    &_active:hover {
      background-color: var(--blue20);
      color: var(--blue120);
    }
  }

  &__loader {
    width: 30px;
    height: 30px;
    margin: 0 auto;
  }

  &__no-result {
    font-size: $sm-text;
    line-height: 18px;
    padding: $indent-sm;
    color: var(--grey60);
  }
}
