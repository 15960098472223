@use "./src/shared/assets/scss/variables/index.scss" as *;
.popover-content {
  position: relative;
  box-shadow: var(--box-shadow-lvl-2);
  border-radius: $border-radius-sm;
  background-color: var(--common2);
  font-weight: 400;
  font-size: $sm-text;
  line-height: $lg-text;

  &__icon-check-mark {
    margin-right: 11.33px;
    width: 16px;
    height: 16px;
    margin-left: 2px;
    color: var(--blue120);

    & path {
      stroke-width: 2;
    }

    &_disabled {
      color: var(--grey80);
    }
  }

  &__icon-close {
    margin-left: -2px;
    margin-right: 11.33px;
    width: 20px;
    height: 20px;
    color: var(--blue120);

    & path {
      stroke-width: 2;
    }
  }

  &__button-check-mark {
    display: flex;
    align-items: center;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__button-close {
    display: flex;
    align-items: center;
  }

  &__input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: $indent-md;
    padding: 0 $indent-xl;
  }

  &__line {
    margin-bottom: $indent-xl;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-xxs;
  }
}
