@use "./src/shared/assets/scss/variables/index.scss" as *;
.systems-table-sup-ag {
  display: flex;
  width: 100%;
  height: 785px;
  flex-direction: row;
  grid-gap: 2px;
  transition: height $transition ease-in-out;

  &_short {
    height: 500px;
  }

  &_close {
    height: 58px;
    overflow: hidden;
    transition: height $transition ease-in-out;
  }

  &__systems {
    display: flex;
    flex-direction: column;
    opacity: 1;
    min-width: 414px;

    &-content {
      height: 100%;
      display: flex;
      border-bottom-left-radius: $border-radius;
      background-color: var(--common2);
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
      padding: 0 18px;
    }
  }

  &__header {
    border-radius: $border-radius 0 0 0;

    &_close {
      border-bottom-left-radius: $border-radius;
    }
  }

  &__sla {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
    flex-grow: 1;
    padding: 0;
    height: 100%;
    max-height: 100%;
    overflow: auto;

    &_close {
      overflow: hidden;
    }
  }

  &__auto-closing {
    padding: $indent-lg $indent-xl;
    border-top: 2px solid var(--grey10);
    flex-direction: column;

    &-toggle {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 1500px) {
    flex-direction: column;
    height: 935px;
    grid-gap: 0;
    transition: height $transition ease-in-out;

    &_short {
      height: 570px;
    }

    &_close {
      height: 58px;
      overflow: hidden;
      transition: height $transition ease-in-out;
    }

    &__header {
      border-radius: $border-radius $border-radius 0 0;

      &_close {
        border-radius: $border-radius;
      }
    }

    &__systems {
      &-content {
        border-radius: 0;
      }
    }

    &__sla {
      border-radius: 0 0 $border-radius $border-radius;
    }
  }

  @media screen and (max-width: 768px) {
    &__systems {
      min-width: auto;
    }

    &__header {
      border-radius: 0;

      &_close {
        border-radius: 0;
      }
    }
  }
}
