@use "./src/shared/assets/scss/variables/index.scss" as *;
.similar-solution {
  z-index: $z-index-drawer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  box-shadow: var(--box-shadow-lvl-4);
  background-color: var(--transparent1);
  animation-name: appear;
  animation-duration: 300ms;

  &__container {
    background-color: var(--common2);
    box-shadow: var(--box-shadow-lvl-2);
    border-radius: $border-radius 0 0 $border-radius;
    animation-name: slide-in;
    animation-duration: 1s;
    height: 100%;
    width: 762px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 $indent-xl;
    height: 64px;
    background-color: var(--grey10);
    border-radius: $border-radius $border-radius 0 0;
    border-bottom: 1px solid var(--grey20);

    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: $lg-text;
      color: var(--grey200);
    }

    &-close {
      &-button {
        height: 24px;

        &-mobile {
          display: none;
        }
      }

      &-icon {
        color: var(--grey100);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 52px);

    &_loading {
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    &__container {
      width: 100%;
      border-radius: 0;
    }

    &__header {
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 0 $indent-md;
      height: 52px;
      border-bottom: none;
      border-radius: 0;

      &-title {
        font-size: $md-text;
        margin-right: 60px;
      }

      &-close {
        &-icon {
          color: var(--grey120);
        }

        &-button {
          display: none;

          &-mobile {
            display: block;
            height: 16px;
            margin-right: $indent-lg;
          }
        }
      }
    }
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(650px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-in-adaptive {
  from {
    transform: translateX(170px);
  }

  to {
    transform: translateX(0);
  }
}
