@use "./src/shared/assets/scss/variables/index.scss" as *;
.system {
  margin-top: $indent-sm;
  height: 100%;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    background-color: var(--common2);
    margin-bottom: $indent-xl;
  }
}
