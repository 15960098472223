@use "./src/shared/assets/scss/variables/index.scss" as *;
.contract {
  &__icon-status {
    border-radius: $border-radius-sm;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey10);
    color: var(--grey100);
  }

  &__status {
    &_active {
      background-color: var(--green120);
      color: var(--common2);
    }

    &_closed {
      background-color: var(--grey50);
      color: var(--common2);
    }

    &_planned {
      background-color: var(--blue120);
      color: var(--common2);
    }

    &_not-signed {
      background-color: var(--yellow120);
      color: var(--common2);
    }
  }

  &__icon {
    width: $indent-md;
    height: $indent-md;
    color: var(--grey100);

    &_main {
      color: var(--common2);
    }

    &-wrapper {
      background-color: var(--purple120);
    }

    &-delete {
      color: var(--red100);
    }
  }

  &__buttons {
    padding: $indent-xs;
    gap: unset;
  }

  &__button {
    display: flex;
    align-items: center;
    padding: $indent-sm;
    column-gap: $indent-sm;

    &_refresh {
      color: var(--grey100);
    }

    &_delete {
      color: var(--red80);
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: $indent-md;
  }

  @media screen and (max-width: 768px) {
    &__icon-status {
      min-width: $indent-xxxl;
      height: $indent-xxxl;
    }
  }
}

[data-theme|='dark'] {
  .contract {
    &__icon {
      color: var(--grey180);

      &-status {
        color: var(--grey180);
      }
    }
  }
}
