@use "./src/shared/assets/scss/variables/index.scss" as *;
.employees-form {
  &__select {
    min-width: 100px;
    max-width: 320px;

    &:not(:last-child) {
      margin-right: $indent-sm;
    }
  }

  @media screen and (max-width: 768px) {
    &__select {
      min-width: auto;
      max-width: none;
      width: 100%;
      margin-right: 0;
      margin-top: 10px;
    }
  }
}
