@use "./src/shared/assets/scss/variables/index.scss" as *;
.button {
  transition: background-color var(--transition-delay);
  position: relative;
  border-radius: var(--border-radius-4);
  font-weight: var(--font-weight-500);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;

  $this: &;

  & svg {
    vertical-align: middle;
    transition: color var(--transition-delay);
  }

  &_xs,
  &_s,
  &_m,
  &_l {
    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &_xs,
  &_s {
    svg {
      margin-right: var(--indent-4pt);
    }
  }

  &_s,
  &_m,
  &_l {
    svg {
      margin-right: var(--indent-8pt);
    }
  }

  &_xxs {
    min-width: 60px;
    font-size: var(--font-size-14);
    line-height: var(--line-height-18);
    height: 24px;
    min-height: 24px;
    padding: 0 var(--indent-8pt);

    & svg {
      width: 16px;
      height: 16px;
    }
  }

  &_xs {
    min-width: 70px;
    font-size: var(--font-size-14);
    line-height: var(--line-height-18);
    height: 32px;
    min-height: 32px;
    padding: 0 var(--indent-8pt);
  }

  &_s {
    min-width: 80px;
    font-size: var(--font-size-14);
    line-height: var(--line-height-18);
    height: 40px;
    min-height: 40px;
    padding: 0 var(--indent-12pt);
  }

  &_m {
    min-width: 90px;
    font-size: var(--font-size-14);
    line-height: var(--line-height-18);
    height: 48px;
    min-height: 48px;
    padding: 0 var(--indent-16pt);
  }

  &_l {
    min-width: 100px;
    font-size: var(--font-size-16);
    line-height: var(--line-height-21);
    height: 56px;
    min-height: 56px;
    padding: 0 var(--indent-20pt);
  }

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &_default {
    &#{$this} {
      &_primary {
        background-color: var(--blue-120);
        color: var(--common-white);

        &:hover:not(:disabled) {
          background-color: var(--blue-100);
        }

        &:active:not(:disabled) {
          background-color: var(--blue-140);
        }
      }

      &_secondary {
        background-color: var(--grey-20);
        color: var(--grey-140);

        &:hover:not(:disabled) {
          background-color: var(--grey-30);
        }

        &:active:not(:disabled) {
          background-color: var(--grey-60);
        }
      }

      &_danger {
        background-color: var(--red-120);
        color: var(--common-white);

        &:hover:not(:disabled) {
          background-color: var(--red-100);
        }

        &:active:not(:disabled) {
          background-color: var(--red-140);
        }
      }

      &_success {
        background-color: var(--green-120);
        color: var(--common-white);

        &:hover:not(:disabled) {
          background-color: var(--green-100);
        }

        &:active:not(:disabled) {
          background-color: var(--green-140);
        }
      }

      &:disabled:not(#{$this}_loading) {
        background-color: var(--grey-10);
        color: var(--grey-80);
      }
    }
  }

  &_outline {
    --border-color: var(--grey-30);

    background-color: transparent;

    &::before {
      border: 1px solid var(--border-color);
      transition: border var(--transition-delay);
      border-radius: var(--border-radius-4);
      position: absolute;
      content: '';
      inset: 0;
    }

    &#{$this} {
      &_primary {
        color: var(--blue-120);

        &:hover:not(:disabled) {
          --border-color: var(--blue-120);
        }

        &:active:not(:disabled) {
          --border-color: var(--blue-140);
        }
      }

      &_secondary {
        color: var(--grey-140);

        &:hover:not(:disabled) {
          --border-color: var(--grey-120);
        }

        &:active:not(:disabled) {
          --border-color: var(--grey-140);
        }
      }

      &_danger {
        color: var(--red-120);

        &:hover:not(:disabled) {
          --border-color: var(--red-120);
        }

        &:active:not(:disabled) {
          --border-color: var(--red-140);
        }
      }

      &_success {
        color: var(--green-120);

        &:hover:not(:disabled) {
          --border-color: var(--green-120);
        }

        &:active:not(:disabled) {
          --border-color: var(--green-140);
        }
      }

      &:disabled:not(#{$this}_loading) {
        --border-color: var(--grey-30);

        color: var(--grey-80);
      }
    }
  }

  &_flat {
    background-color: transparent;
    &#{$this} {
      &_primary {
        color: var(--blue-120);

        &:hover:not(:disabled) {
          background-color: var(--blue-10);
        }

        &:active:not(:disabled) {
          background-color: var(--blue-20);
        }
      }

      &_secondary {
        color: var(--grey-140);

        &:hover:not(:disabled) {
          background-color: var(--grey-10);
        }

        &:active:not(:disabled) {
          background-color: var(--grey-20);
        }
      }

      &_danger {
        color: var(--red-120);

        &:hover:not(:disabled) {
          background-color: var(--red-10);
        }

        &:active:not(:disabled) {
          background-color: var(--red-20);
        }
      }

      &_success {
        color: var(--green-120);

        &:hover:not(:disabled) {
          background-color: var(--green-10);
        }

        &:active:not(:disabled) {
          background-color: var(--green-20);
        }
      }

      &:disabled:not(#{$this}_loading) {
        color: var(--grey-80);
        background-color: transparent;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &_loading {
    & svg,
    & span {
      opacity: 0;
    }
  }

  &__spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  // TODO: Не макет
  &:focus {
    outline: 2px solid var(--blue180);
  }
}
