@use "./src/shared/assets/scss/variables/index.scss" as *;
.supplementary-agreements-and-attachments {
  background-color: var(--common2);
  border-radius: $border-radius;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 $indent-md;
    height: 60px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color: var(--common2);
    font-weight: 500;
    font-size: $md-text;
    color: var(--grey200);
    box-shadow: inset 0 -2px 0 0 var(--grey20);

    &-start {
      display: flex;
      flex-direction: row;
      column-gap: $indent-lg;
      overflow-x: auto;
    }
  }

  &__title-button {
    padding: 19px 0;
    color: var(--grey100);
    font-size: $md-text;
    border-bottom: 2px solid transparent;
    white-space: nowrap;

    &_active {
      border-bottom: 2px solid var(--blue120);
      font-weight: 500;
      color: var(--grey200);
      line-height: 20px;
    }
  }

  &__list {
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    height: 365px;
    margin: $indent-md 0;
  }

  &__plus-icon {
    width: 20px;
  }

  &__empty {
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
    border-top: 1px solid var(--grey20);

    &__header {
      font-size: $sm-text;
    }

    &__button {
      font-size: $sm-text;
    }

    &__list {
      height: auto;
      max-height: 296px;
      padding: 0 $indent-sm;
    }
  }

  @media screen and (max-width: 500px) {
    &__button {
      svg {
        margin-right: 0;
      }

      &-title {
        display: none;
      }
    }
  }
}
