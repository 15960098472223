@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-category-main-block {
  padding: 0;
  color: var(--grey200);

  &__loader {
    margin: 0 auto;
    flex-basis: 100%;
    display: flex;
  }

  &__no-data {
    margin: 40px 0;
  }
}
