@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-client-info {
  padding: 0;

  &__info {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__name-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $indent-lg auto;
  }

  &__title {
    font-weight: 500;
    max-width: 100vh;
    border-bottom: 1px solid var(--grey20);

    &-text {
      font-weight: 500;
      font-size: $md-text;
      padding: $indent-lg;
    }
  }

  &__fio {
    margin-top: $indent-xl;
    margin-bottom: $indent-xl;
    font-weight: 500;
    max-width: 182px;
    text-align: center;
    font-size: $md-text;
    line-height: $indent-xl;
    color: var(--grey200);
    white-space: pre-wrap;
  }

  &__organization {
    background-color: var(--purple20);
    color: var(--purple120);
    padding: $indent-xxs $indent-sm;
  }

  &__icons {
    display: flex;
    padding: $indent-sm auto;
    justify-content: center;
    align-items: center;
    background-color: var(--grey10);
    height: 64px;
    position: relative;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &__tooltip {
    margin-right: $indent-sm;

    &-container {
      background-color: var(--common2);
    }

    &-icon {
      width: $indent-md;
      color: var(--blue120);
    }
  }

  &__system-info {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    &-wrapper {
      padding: $indent-sm;
      border-bottom: 1px solid var(--grey20);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__block-info {
    font-size: $sm-text;
  }

  &__loader-wrapper {
    height: 164px;
    display: flex;
    align-items: center;
  }
}

[data-theme='light-contrast'] {
  .ticket-client-info {
    &__organization {
      color: var(--purple180);
    }
  }
}
