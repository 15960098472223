@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-change-field-drop {
  display: flex;
  flex-direction: column;
  grid-gap: $indent-md;

  &__inner {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-md;
    width: 100%;
  }

  &__buttons {
    grid-gap: $indent-xxs;
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: var(--blue120);
  }

  &__popover {
    width: 100%;
  }

  &__input {
    padding: 0 $indent-xl;
  }
}
