@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integrations-full-table {
  &__cell {
    white-space: nowrap;
    display: flex;
    align-items: center;

    &-item {
      margin-right: 8px;
    }
  }

  &__round {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--grey40);

    &_active {
      background-color: var(--green80);
    }
  }
}
