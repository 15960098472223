@use "./src/shared/assets/scss/variables/index.scss" as *;
.edit-status-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    margin-bottom: $indent-md;
  }

  &__title {
    color: var(--grey200);
    margin-bottom: $indent-md;
    text-align: center;
  }

  &__text-area {
    height: 140px;
  }

  &__arrow {
    color: var(--grey50);
  }

  @media screen and (max-width: 768px) {
    &__header {
      flex-direction: column;
      grid-gap: $indent-md;
    }

    &__arrow {
      transform: rotate(90deg);
    }
  }
}
