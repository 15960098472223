@use "./src/shared/assets/scss/variables/index.scss" as *;
.add-custom-fields-modal {
  &__content {
    height: 373px;
    display: flex;
  }

  &__left-container {
    flex-basis: 50%;
    border-right: 1px solid var(--grey20);
    border-bottom: 1px solid var(--grey20);
    padding-right: $indent-lg;
    padding-bottom: $indent-md;
    display: flex;
    flex-direction: column;
  }

  &__right-container {
    flex-basis: 50%;
    border-bottom: 1px solid var(--grey20);
    padding-left: $indent-xxxl;
    padding-bottom: $indent-md;
    display: flex;
    flex-direction: column;
  }

  &__title {
    color: var(--grey200);
  }

  &__input {
    margin-top: $indent-lg;
  }

  &__systems-list {
    display: flex;
    flex-direction: column;
    margin-top: $indent-sm;
    padding-right: $indent-sm;
    overflow-y: auto;
    flex-basis: 100%;

    &_loading {
      align-items: center;
      justify-content: center;
    }

    &-element {
      display: flex;
      align-items: center;
      padding: 0 $indent-sm;
      min-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--grey200);
      font-size: $sm-text;
      font-weight: 400;
      transition: background-color $transition, color $transition;

      &:not(:last-child) {
        border-bottom: 1px solid var(--grey20);
      }

      &_active,
      &:hover {
        color: var(--blue120);
        background-color: var(--blue10);
        cursor: pointer;
      }
    }
  }

  &__checkbox {
    grid-gap: $indent-lg;
    margin-right: $indent-md;

    &-container {
      display: flex;
      align-items: center;

      &_check-all {
        margin-top: $indent-xxxl;
        margin-bottom: $indent-md;
      }

      &:not(:last-child) {
        margin-bottom: $indent-sm;
      }
    }
  }

  &__fields-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: $indent-sm;
    flex-basis: 100%;

    &_loading {
      align-items: center;
      justify-content: center;
    }
  }

  &__footer-title {
    color: var(--grey120);
    font-size: $sm-text;
  }

  &__buttons-block {
    margin-top: $indent-sm;
  }

  &__no-search {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-basis: 100%;
    }

    &-icon {
      width: 64px;
      height: 64px;
      margin-bottom: 40px;
    }

    &-title {
      color: var(--grey200);
      margin-bottom: $indent-xs;
    }

    &-text {
      color: var(--grey120);
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      flex-direction: column;
      height: auto;
    }

    &__left-container {
      border-right: none;
      padding-right: 0;
      height: 435px;
      overflow-y: auto;
      flex-basis: auto;
    }

    &__right-container {
      padding-left: 0;
      flex-basis: auto;
      height: 435px;
      overflow-y: auto;
      padding-top: $indent-md;
    }
  }
}
