@use "./src/shared/assets/scss/variables/index.scss" as *;
.specialist-form {
  display: flex;
  align-items: center;

  &__title {
    word-wrap: break-word;
    font-weight: 500;
    font-size: $lg-text;
    margin-top: $indent-md;
  }

  &__text {
    word-break: break-all;
    margin-top: $indent-sm;
    line-height: 23px;
    color: var(--grey120);
    font-size: $sm-text;
    max-height: 540px;
    overflow-y: auto;
  }

  &__files {
    margin-top: $indent-lg;
    display: flex;
    overflow-x: auto;
    padding: $indent-xxs;
  }

  &__file {
    margin-right: $indent-md;
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    margin-top: 44px;
  }

  &__sub-title {
    color: var(--grey120);
    margin-right: $indent-xl;
  }

  &__take-in-process {
    margin-right: 68px;
    padding: $indent-md $indent-md;
  }

  &__to-appoint {
    margin-left: $indent-sm;
    padding: $indent-md $indent-md;
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__select {
    width: 340px;
  }

  &__solution {
    margin-top: $indent-md;
  }

  @media screen and (max-width: 1350px) {
    display: block;

    &__buttons-wrapper {
      display: block;
    }

    &__take-in-process {
      margin-bottom: $indent-sm;
    }

    &__form {
      margin-top: $indent-sm;
    }
  }

  @media screen and (max-width: 992px) {
    &__form {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &__select {
      width: 100%;
      max-width: 300px;
    }

    &__to-appoint {
      margin-left: 0;
      margin-top: $indent-sm;
    }
  }
}
