@font-face {
  font-family: 'Roboto';
  src:
    url('../../../../../public/assets/fonts/roboto/Roboto-Bold.woff') format('woff'),
    url('../../../../../public/assets/fonts/roboto/Roboto-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('../../../../../public/assets/fonts/roboto/Roboto-Medium.woff') format('woff'),
    url('../../../../../public/assets/fonts/roboto/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('../../../../../public/assets/fonts/roboto/Roboto-Regular.woff') format('woff'),
    url('../../../../../public/assets/fonts/roboto/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ALS Hauss';
  src:
    url('../../../../../public/assets/fonts/als_hauss/ALSHauss-Bold.woff') format('woff'),
    url('../../../../../public/assets/fonts/als_hauss/ALSHauss-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ALS Hauss';
  src:
    url('../../../../../public/assets/fonts/als_hauss/ALSHauss-Regular.woff') format('woff'),
    url('../../../../../public/assets/fonts/als_hauss/ALSHauss-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
