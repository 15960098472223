@use "./src/shared/assets/scss/variables/index.scss" as *;
.responsible-for-contract {
  border-radius: $border-radius;
  background-color: var(--grey10);

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 $indent-md;
    min-height: $table-header-height;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color: var(--common2);
  }

  &__user {
    margin: 0 $indent-xl;
  }

  &__users-list {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-xs;
    margin: $indent-md 0;
    height: 365px;
    overflow-y: auto;
  }

  &__icon-plus {
    width: 20px;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;

    &__header {
      border-radius: 0;
      font-size: $sm-text;
    }

    &__users-list {
      height: auto;
      max-height: 296px;
    }

    &__button {
      font-size: $sm-text;
    }

    &__user {
      margin: 0 $indent-sm;
    }
  }
}
