@use "./src/shared/assets/scss/variables/index.scss" as *;
.go-back-button {
  display: flex;
  align-items: center;

  &:focus {
    outline: 2px solid var(--blue180);
  }

  &__icon {
    margin-right: $indent-xs;
    color: var(--grey90);

    & path {
      transition: $transition;
    }
  }

  &__text {
    font-size: $xs-text;
    color: var(--grey120);
    line-height: 14px;
    transition: color $transition;
  }

  &:hover {
    & .go-back-button {
      &__text {
        color: var(--grey200);
      }

      &__icon path {
        stroke: var(--grey200);
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__icon {
      margin-right: 0;
    }
  }
}
