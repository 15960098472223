@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-layout-drop-content {
  &__jira-icon {
    width: 16px;
    height: 16px;
  }

  &__specialist {
    width: 100%;

    &-form {
      width: 100%;
    }

    &-select {
      max-width: 100%;
    }

    &-button {
      width: 100%;
      margin-left: 0;
    }
  }

  &__close-icon {
    width: 16px;
    height: 16px;
    color: var(--blue120);
  }
}
