@use "./src/shared/assets/scss/variables/index.scss" as *;
.radio-slide {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--grey20);
  padding: $indent-xxs;
  border-radius: $border-radius-sm;
  grid-gap: $indent-xxs;
  user-select: none;

  $this: &;

  &_xs {
    height: 32px;

    #{$this}__label {
      & span {
        font-size: $sm-text;
      }
    }
  }

  &_s {
    height: 40px;

    #{$this}__label {
      & span {
        font-size: $sm-text;
      }
    }
  }

  &_m {
    height: 48px;

    #{$this}__label {
      & span {
        font-size: $sm-text;
      }
    }
  }

  &_l {
    height: 56px;

    #{$this}__label {
      & span {
        font-size: $lg-text;
      }
    }
  }

  &__label {
    height: 100%;
    flex-basis: 100%;
    position: relative;
    overflow: hidden;

    & span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 $indent-sm;
      font-weight: 500;
      border-radius: $border-radius-sm;
      height: 100%;
      transition: background-color $transition;
      white-space: nowrap;
    }

    &_disabled {
      cursor: not-allowed;
    }
  }

  &__title {
    font-weight: 400;
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:not(:disabled) {
      &:not(:checked) + span:hover {
        cursor: pointer;
        background-color: var(--grey30);
      }
    }

    &:checked {
      & + span {
        background-color: var(--common2);

        & > div {
          font-weight: 500;
        }
      }
    }

    &:focus {
      & + span {
        border: 1px solid var(--blue120);
      }

      &:not(:checked) + span {
        border: 1px solid var(--blue120);
      }

      &:not(:focus-visible) + span {
        border: none;
      }
    }

    &:disabled {
      &:not(:checked) {
        & + span {
          & > div {
            color: var(--grey60);
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__label {
      & span {
        font-size: $sm-text;
      }
    }
  }
}
