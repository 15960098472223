@use "./src/shared/assets/scss/variables/index.scss" as *;
.double-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: $z-index-modal;
  box-shadow: var(--box-shadow-lvl-4);
  background-color: var(--transparent1);
  animation-name: appear;
  animation-duration: 300ms;

  @keyframes appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes slide-in {
    from {
      transform: translateY(170px);
    }

    to {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 768px) {
    display: block;

    &__content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      animation-name: slide-in;
      animation-duration: 0.5s;

      &-top,
      &-bottom {
        padding: 0;
      }

      &-bottom {
        margin-top: $indent-xs;
        border-radius: $border-radius $border-radius 0 0;
      }
    }
  }
}
