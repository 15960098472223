@use "./src/shared/assets/scss/variables/index.scss" as *;
.date {
  $this: &;

  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &_in {
    &-range {
      background: var(--blue10);
    }

    &-approximate-range {
      position: relative;

      &::before {
        z-index: 1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-top: 1px dashed var(--grey40);
        border-bottom: 1px dashed var(--grey40);
      }

      &_first {
        &::before {
          right: 0;
          width: 50%;
        }
      }

      &_last {
        &::before {
          left: 0;
          width: 50%;
        }
      }
    }
  }

  &_first-in-range {
    border-radius: 50% 0 0 50%;
  }

  &_last-in-range {
    border-radius: 0 50% 50% 0;
  }

  &:disabled {
    #{$this}__typography {
      color: var(--grey60);
    }
  }

  &-wrapper {
    position: relative;
    border-radius: 50%;
    z-index: 1;
    height: 30px;
    width: 30px;

    &_picked {
      background: var(--blue120);
      z-index: 2;
    }

    &_today {
      border: 1px solid var(--grey200);
    }

    &:not(&_picked):hover {
      background-color: var(--grey10);
    }
  }

  &__typography {
    align-items: center;
    border-radius: 50%;
    color: var(--grey60);
    font-weight: 500;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    &_current-month {
      color: var(--grey200);
    }

    &_weekend {
      color: var(--grey100);
    }

    &_picked {
      color: var(--common2);
    }
  }

  @media screen and (max-width: 768px) {
    width: auto;
  }
}
