@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-tags {
  display: flex;
  align-items: center;
  min-height: 76px;
  bottom: 0;
  width: 100%;
  border-top: 1px solid var(--grey20);
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: $indent-xs;
  padding: $indent-sm 0 0;

  &__loader {
    width: 25px;
    height: 25px;
  }

  &_hidden {
    display: none;
  }

  &__edit {
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: $indent-xs;

    &-buttons {
      display: flex;
      width: fit-content;
      height: 48px;
      flex-direction: row;
      background-color: var(--grey20);
      align-items: center;
      grid-gap: $indent-xs;
      border-radius: $border-radius-sm;
      padding: $indent-sm $indent-md;
    }

    &-close {
      background-color: var(--grey80);

      &:hover {
        background-color: var(--grey60);
      }
    }
  }

  &__add {
    &-btn {
      display: flex;
      flex-direction: row;
      color: var(--blue120);
      font-weight: 400;
      align-items: center;

      &:hover {
        background-color: var(--common2);
      }
    }

    &-icon {
      color: inherit;

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin-right: $indent-md;
        background-color: var(--blue10);

        &:hover {
          background-color: var(--blue20);
        }
      }
    }
  }

  @media screen and (max-width: 390px) {
    &__edit {
      flex-direction: column;
    }
  }
}
