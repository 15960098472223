@use "./src/shared/assets/scss/variables/index.scss" as *;
.assessment-interaction-process {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-sm;
  width: 70%;
  background-color: var(--common2);

  &__top-wrapper {
    padding: 44px 0 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__title {
    color: var(--grey120);
    max-width: 40%;
    text-align: center;
  }

  &__stars-rating {
    margin-top: $indent-xxl;
  }

  &__bottom-wrapper {
    padding: $indent-xl 44px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--grey20);
  }

  &__sub-title {
    color: var(--grey120);
    margin-bottom: $indent-md;
  }

  &__checkbox {
    &-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
    }
  }

  &__button {
    margin-top: $indent-md;
    width: 188px;
  }

  @media screen and (max-width: 1400px) {
    width: 90%;
  }

  @media screen and (max-width: 1200px) {
    width: 95%;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
