@use "./src/shared/assets/scss/variables/index.scss" as *;
.drop-content {
  &__icon {
    color: var(--blue120);
  }

  &__loader {
    width: 20px;
    height: 20px;
  }
}
