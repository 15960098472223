@use "./src/shared/assets/scss/variables/index.scss" as *;
.sidebar {
  display: flex;
  flex-direction: column;
  row-gap: $indent-md;

  &__typography {
    display: flex;
    color: var(--blue120);

    &_active {
      color: var(--blue100);
    }
  }

  &:hover {
    color: var(--blue100);
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    overflow-x: auto;
    padding: 0 $indent-lg;
    height: 58px;

    &__typography {
      width: 150px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
