@use "./src/shared/assets/scss/variables/index.scss" as *;
.sla-table {
  overflow: auto;
  padding: $indent-xl $indent-xl 0;
  width: 100%;

  &__table {
    width: 100%;
    display: table;
  }

  &__wrapper {
    width: 100%;
    overflow: auto;
  }

  &__cell {
    &-head {
      font-weight: 500;
      font-size: $sm-text;
    }
  }

  &__head::before {
    content: '';
  }

  &__switch-header {
    display: flex;
    height: 36px;
    flex-direction: row;
    max-width: 100%;
    overflow-x: auto;
    box-shadow: inset 0 -2px 0 0 var(--grey20);

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    &-container {
      display: flex;
      margin-right: $indent-md;
    }

    &-item {
      font-size: $md-text;
      color: var(--grey100);
      border-bottom: 2px solid transparent;
      transition: font-size 0.3s;
      transition: color 0.3s;
      transition: border-bottom 0.2s;
      transition: opacity 0.3s;

      &:hover {
        transition: opacity 0.3s;
      }
    }
  }
}
