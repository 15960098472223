@use "./src/shared/assets/scss/variables/index.scss" as *;
.priority-block {
  display: block;
  white-space: nowrap;
  min-width: 100px;
  padding-top: $indent-xxs;
  padding-bottom: $indent-xxs;
  padding-right: $indent-xs;
  width: fit-content;

  &::before {
    content: "";
    display: inline-block;
    position: relative;
    width: 4px;
    height: 20px;
    border-radius: $border-radius-sm;
    margin-right: 10px;
    vertical-align: middle;
  }

  &_none {
    &::before {
      background: var(--grey20);
    }
  }

  &_low {
    &::before {
      background: var(--green120);
    }
  }

  &_middle {
    &::before {
      background: var(--yellow90);
    }
  }

  &_high {
    &::before {
      background: var(--orange110);
    }
  }

  &_very-high {
    &::before {
      background: var(--red130);
    }
  }
}
