@use "./src/shared/assets/scss/variables/index.scss" as *;
.round-button {
  min-width: 24px;
  height: 24px;
  transition: background-color $transition;
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--green120);
  user-select: none;

  &:hover {
    cursor: pointer;
    background-color: var(--green100);
  }

  &:disabled {
    background-color: var(--grey50);
    cursor: not-allowed;
  }

  & svg {
    color: var(--common2);
    width: 18px;
    height: 18px;
  }

  &:focus {
    outline: 2px solid var(--blue180);
  }
}
