@use "./src/shared/assets/scss/variables/index.scss" as *;
.employees-sla {
  border-radius: $border-radius;

  &__header {
    border-radius: $border-radius $border-radius 0 0;
    background-color: var(--common2);
    padding: 20px $indent-xl;
    display: flex;
    align-items: center;
    transition: border-radius 0.8s;

    &_close {
      border-radius: $border-radius;
      transition: border-radius 0.8s;
    }
  }

  &__close-button {
    color: var(--grey100);
    margin-right: $indent-sm;
  }

  &__arrow {
    transform: rotate(-90deg);
    transition: transform $transition;

    &_close {
      transform: rotate(90deg);
    }
  }

  &__content {
    padding: $indent-sm 20px;
    overflow: hidden;
    background-color: var(--grey10);
    border-top: 1px solid var(--grey20);
    border-radius: 0 0 $border-radius $border-radius;
    transition: max-height $transition cubic-bezier(1, 0, 1, 0), padding 0.8s;
    max-height: 9999px;

    &_close {
      padding: 0 20px;
      max-height: 0;
      transition: max-height $transition cubic-bezier(0, 1, 0, 1), padding 0.8s;
      border-top: none;
    }
  }

  &__sla-timer {
    margin-bottom: $indent-sm;
  }

  @media screen and (max-width: 768px) {
    &__header {
      padding: 20px 31px;
    }

    &__content {
      padding: $indent-sm $indent-sm;

      &_close {
        padding: 0 $indent-sm;
        border-top: 1px solid var(--grey20);
      }
    }

    &__title {
      font-size: $sm-text;
    }
  }
}
