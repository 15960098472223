@use "./src/shared/assets/scss/variables/index.scss" as *;
.toggle-button {
  min-width: 32px;
  height: 32px;
  transition: background-color $transition;
  position: relative;
  border-radius: $border-radius-sm;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  user-select: none;

  & svg {
    width: 28px;

    & path,
    circle {
      stroke: var(--blue120);
    }
  }

  &:hover {
    background-color: var(--blue10);

    & svg path,
    svg circle {
      stroke: var(--blue120);
    }
  }

  &_active {
    background-color: var(--blue120);

    & svg path,
    svg circle {
      stroke: var(--common2);
    }
  }

  &:disabled {
    cursor: not-allowed;

    & svg path,
    svg circle {
      stroke: var(--grey50);
    }
  }

  &:focus {
    outline: 2px solid var(--blue180);
  }
}

[data-theme|='dark'] {
  .toggle-button {
    & svg {
      width: 28px;

      & path,
      circle {
        stroke: #3392ff;
      }
    }

    &:hover {
      & svg path,
      svg circle {
        stroke: var(--blue120);
      }
    }

    &_active {
      & svg path,
      svg circle {
        stroke: var(--common2);
      }
    }

    &:disabled {
      & svg path,
      svg circle {
        stroke: var(--grey50);
      }
    }
  }
}
