@use "./src/shared/assets/scss/variables/index.scss" as *;
.card-table-header {
  display: flex;
  justify-content: space-between;
  background: var(--common2);
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  padding-right: 10px;
  min-height: $table-header-height;
  box-shadow: inset 0 -1px 0 0 var(--grey20);
  transition: all $transition ease-in-out;

  &_with-title {
    align-items: center;
    padding-left: 26px;
  }

  &_close {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    box-shadow: none;
    transition: all $transition ease-in-out;
  }

  &_no-tabs {
    justify-content: flex-end;
  }

  &_with-bold-line {
    box-shadow: inset 0 -2px 0 0 var(--grey20);
  }

  &__plus-icon {
    width: 20px;
    height: 20px;
  }

  &__title-block-container {
    width: 97%;
    display: flex;
    justify-content: space-between;
  }

  &__title-block {
    padding: 5px;
    display: flex;
    flex-direction: row;
    grid-gap: $indent-sm;
    align-items: center;
    border-radius: $border-radius-sm;

    &:focus-within {
      outline: 2px solid var(--blue180);
    }
  }

  &__title-btn {
    color: var(--grey100);
    margin-right: 0;
  }

  &__table-title {
    font-weight: 500;
    font-size: $md-text;
    color: var(--grey200);
  }

  &__tabs {
    display: flex;
    padding-top: 29px;
    padding-left: $indent-xl;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      border: none;

      &:active {
        border: none;
      }
    }
  }

  &__tools {
    display: flex;
    align-items: center;
  }

  &__icons {
    margin-right: 30px;
    display: flex;
    width: 100px;
    justify-content: space-around;
    align-items: center;
  }

  &__search {
    margin-left: 10px;

    &_mr {
      margin-right: 10px;
    }
  }

  &__drop {
    margin: 0 10px;
  }

  &__table {
    &_open {
      transition: all 0.5s;
      transform: rotate(-90deg);
    }

    &_close {
      transition: all 0.5s;
      transform: rotate(90deg);
    }
  }

  &__modal {
    display: none;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;

    &__table-title {
      font-size: $sm-text;
      white-space: nowrap;
      overflow: hidden;
      max-width: 34vw;
      text-overflow: ellipsis;
    }

    &__additional-component {
      display: none;
    }

    &__search {
      margin-left: $indent-xxs;
    }

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    &__create-button {
      font-size: $sm-text;
    }

    &_close {
      box-shadow: inset 0 -2px 0 0 var(--grey20);
    }

    &__icons {
      display: none;
    }

    &__tabs {
      padding-top: $indent-md;
      padding-left: 14px;
      margin-right: $indent-sm;
    }

    &__modal {
      display: block;
      margin-left: $indent-xs;
    }
  }

  @media screen and (max-width: 368px) {
    &__table-title {
      max-width: 21vw;
    }
  }
}
