@use "./src/shared/assets/scss/variables/index.scss" as *;
.table {
  margin-top: $indent-md;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  flex-basis: 100%;

  $this: &;

  &__wrapper {
    position: relative;
    border-collapse: collapse;
    display: table;
    text-indent: initial;
    width: 100%;
    background-color: var(--common2);
  }

  &__checkbox {
    display: table-cell;
    vertical-align: middle;
    padding: 0 $indent-sm;
    width: 40px;
  }

  &__cell {
    &:first-child {
      position: sticky;
      left: 0;
      z-index: $z-index-10;
      background-color: var(--common2);
      transition: background-color 0.3s;

      @media screen and (min-width: 769px) {
        &:hover {
          background-color: var(--grey10);
        }
      }
    }

    &_active:first-child {
      background-color: var(--blue20);

      @media screen and (min-width: 769px) {
        &:hover {
          background-color: var(--blue20);
          cursor: auto;
        }
      }
    }
  }

  &__row {
    @media screen and (min-width: 769px) {
      &:hover #{$this}__cell {
        &:first-child {
          background-color: var(--grey10);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-grow: 1;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      border: none;

      &:active {
        border: none;
      }
    }
  }
}

[data-theme|='dark'] {
  .table {
    &__cell {
      &:first-child {
        @media screen and (min-width: 769px) {
          &:hover {
            background-color: var(--blue20);
          }
        }
      }

      &_active:first-child {
        background-color: #152132;

        @media screen and (min-width: 769px) {
          &:hover {
            background-color: #152132;
          }
        }
      }
    }

    &__row {
      @media screen and (min-width: 769px) {
        &:hover .table__cell {
          &:first-child {
            background-color: var(--blue20);
          }
        }
      }
    }
  }
}
