@use "./src/shared/assets/scss/variables/index.scss" as *;
:global {
  /* this gets exported as style.css and can be used for the default theming */

  /* these are the necessary styles for React Flow, they get used by base.css and style.css */
  .react-flow__container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .react-flow__pane {
    z-index: 1;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .react-flow__pane.dragging {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .react-flow__viewport {
    transform-origin: 0 0;
    z-index: 2;
    pointer-events: none;
  }

  .react-flow__renderer {
    z-index: 4;
  }

  .react-flow__selectionpane {
    z-index: 5;
  }

  .react-flow__nodesselection-rect:focus,
  .react-flow__nodesselection-rect:focus-visible {
    outline: none;
  }

  .react-flow .react-flow__edges {
    pointer-events: none;
    overflow: visible;
  }

  .react-flow__edge-path,
  .react-flow__connection-path {
    fill: none;
    stroke: var(--grey50);
    stroke-width: 2px;
  }

  .react-flow__edge {
    pointer-events: visibleStroke;
    cursor: pointer;
  }

  .react-flow__edge.animated path {
    stroke-dasharray: 5;
    -webkit-animation: dashdraw 0.5s linear infinite;
    animation: dashdraw 0.5s linear infinite;
  }

  .react-flow__edge.animated path.react-flow__edge-interaction {
    stroke-dasharray: none;
    -webkit-animation: none;
    animation: none;
  }

  .react-flow__edge.inactive {
    pointer-events: none;
  }

  .react-flow__edge.selected,
  .react-flow__edge:focus,
  .react-flow__edge:focus-visible {
    outline: none;
  }

  .react-flow__edge.selected .react-flow__edge-path,
  .react-flow__edge:focus-visible .react-flow__edge-path {
    stroke: var(--blue120);
  }

  .react-flow__edge-textwrapper {
    pointer-events: all;
  }

  .react-flow__edge-textbg {
    fill: white;
  }

  .react-flow__edge .react-flow__edge-text {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  .react-flow__connection {
    pointer-events: none;
  }

  .react-flow__connection.animated {
    stroke-dasharray: 5;
    -webkit-animation: dashdraw 0.5s linear infinite;
    animation: dashdraw 0.5s linear infinite;
  }

  .react-flow__connectionline {
    z-index: 1001;
  }

  .react-flow__nodes {
    pointer-events: none;
    transform-origin: 0 0;
  }

  .react-flow__node {
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: all;
    transform-origin: 0 0;
    box-sizing: border-box;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .react-flow__node.dragging {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .react-flow__nodesselection {
    z-index: 3;
    transform-origin: left top;
    pointer-events: none;
  }

  .react-flow__nodesselection-rect {
    position: absolute;
    pointer-events: all;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .react-flow__handle {
    position: absolute;
    pointer-events: none;
  }

  .react-flow__handle.connectable {
    pointer-events: all;
    cursor: crosshair;
  }

  .react-flow__handle-bottom {
    top: auto;
    left: 50%;
    bottom: -15px;
    transform: translate(-50%, 0) rotate(90deg);
  }

  .react-flow__handle-top {
    left: 50%;
    top: -15px;
    transform: translate(-50%, 0) rotate(90deg);
  }

  .react-flow__handle-left {
    top: 50%;
    left: -15px;
    transform: translate(0, -50%);
  }

  .react-flow__handle-right {
    right: -15px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .react-flow__edgeupdater {
    cursor: move;
    pointer-events: all;
  }

  .react-flow__panel {
    position: absolute;
    z-index: 5;
    margin: 15px;
  }

  .react-flow__panel.top {
    top: 0;
  }

  .react-flow__panel.bottom {
    bottom: 0;
  }

  .react-flow__panel.left {
    left: 0;
  }

  .react-flow__panel.right {
    right: 0;
  }

  .react-flow__panel.center {
    left: 50%;
    transform: translateX(-50%);
  }

  .react-flow__attribution {
    display: none;
    font-size: 10px;
    background: rgba(255, 255, 255, 0.5);
    padding: 2px 3px;
    margin: 0;
  }

  .react-flow__attribution a {
    text-decoration: none;
    color: #999;
  }

  @-webkit-keyframes dashdraw {
    from {
      stroke-dashoffset: 10;
    }
  }

  @keyframes dashdraw {
    from {
      stroke-dashoffset: 10;
    }
  }

  .react-flow__edgelabel-renderer {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .react-flow__edge-text {
    font-size: 10px;
  }

  .react-flow__node-default,
  .react-flow__node-input,
  .react-flow__node-output,
  .react-flow__node-group {
    padding: 10px;
    border-radius: 3px;
    width: 150px;
    font-size: 12px;
    color: #222;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: #1a192b;
    background-color: white;
  }

  .react-flow__node-default.selectable:hover,
  .react-flow__node-input.selectable:hover,
  .react-flow__node-output.selectable:hover,
  .react-flow__node-group.selectable:hover {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
  }

  .react-flow__node-default.selectable.selected,
  .react-flow__node-default.selectable:focus,
  .react-flow__node-default.selectable:focus-visible,
  .react-flow__node-input.selectable.selected,
  .react-flow__node-input.selectable:focus,
  .react-flow__node-input.selectable:focus-visible,
  .react-flow__node-output.selectable.selected,
  .react-flow__node-output.selectable:focus,
  .react-flow__node-output.selectable:focus-visible,
  .react-flow__node-group.selectable.selected,
  .react-flow__node-group.selectable:focus,
  .react-flow__node-group.selectable:focus-visible {
    box-shadow: 0 0 0 0.5px #1a192b;
    outline: none;
  }

  .react-flow__node-group {
    background-color: rgba(240, 240, 240, 0.25);
  }

  .react-flow__nodesselection-rect,
  .react-flow__selection {
    background: rgba(0, 89, 220, 0.08);
    border: 1px dotted rgba(0, 89, 220, 0.8);
  }

  .react-flow__nodesselection-rect:focus,
  .react-flow__nodesselection-rect:focus-visible,
  .react-flow__selection:focus,
  .react-flow__selection:focus-visible {
    outline: none;
  }

  .react-flow__controls {
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);
  }

  .react-flow__controls-button {
    border: none;
    background: #fefefe;
    border-bottom: 1px solid #eee;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    padding: 5px;
  }

  .react-flow__controls-button:hover {
    background: #f4f4f4;
  }

  .react-flow__controls-button svg {
    width: 100%;
    max-width: 12px;
    max-height: 12px;
  }

  .react-flow__minimap {
    background-color: #fff;
  }

  [data-theme='light'] {
    .react-flow__handle-connecting {
      &:not(.react-flow__handle-valid) {
        background-image: url("../CustomNode/icons/arrow-no-valid-light.svg") !important;
      }
    }
  }

  [data-theme='light-contrast'] {
    .react-flow__handle-connecting {
      &:not(.react-flow__handle-valid) {
        background-image: url("../CustomNode/icons/arrow-no-valid-light-contrast.svg") !important;
      }
    }
  }

  [data-theme='dark'] {
    .react-flow__handle-connecting {
      &:not(.react-flow__handle-valid) {
        background-image: url("../CustomNode/icons/arrow-no-valid-dark.svg") !important;
      }
    }
  }

  [data-theme='dark-contrast'] {
    .react-flow__handle-connecting {
      &:not(.react-flow__handle-valid) {
        background-image: url("../CustomNode/icons/arrow-no-valid-dark-contrast.svg") !important;
      }
    }
  }
}
