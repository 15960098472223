@use "./src/shared/assets/scss/variables/index.scss" as *;
.table-head {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;

  &__checkbox {
    display: table-cell;
    vertical-align: middle;
    padding: 0 $indent-sm;
    width: 40px;
  }

  &__row {
    border-bottom: 1px solid var(--grey60);

    &:hover {
      background-color: var(--common2);
      cursor: auto;
    }
  }

  &__cell:first-child {
    position: sticky;
    left: 0;
    z-index: $z-index-10;
    background-color: var(--common2);
  }
}

[data-theme|='dark'] {
  .table-head__row:hover {
    background-color: var(--common2);
  }
}
