@use "./src/shared/assets/scss/variables/index.scss" as *;
.contracts {
  flex-direction: row;
  min-height: auto;
  max-height: none;
  flex-basis: 100%;
  overflow: auto;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    height: 100%;
    border-radius: 0;
  }
}
