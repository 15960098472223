@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-categories-header {
  display: flex;
  width: 100%;
  padding: 0 $indent-xxl $indent-sm;
  margin-top: $indent-sm;
  justify-content: space-between;
  grid-gap: $indent-lg;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0 -2px 0 0 var(--grey20);
  position: relative;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-basis: 100%;
    grid-gap: $indent-lg;

    &_with-drop {
      padding-right: 44px;
    }
  }

  &__popover {
    position: absolute;
    right: 28px;
  }

  @media screen and (max-width: 768px) {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      padding-right: 0;
    }

    padding: 0 $indent-lg $indent-lg;

    &__select {
      max-width: none;
    }

    &__popover {
      &_adaptive {
        position: absolute;
        right: 28px;
        top: 0;
      }
    }
  }
}
