@use "./src/shared/assets/scss/variables/index.scss" as *;
.roles-full-table {
  &-type {
    &_group {
      background-color: var(--green20);
      color: var(--green120);
    }

    &_system {
      color: var(--cyan120);
      background-color: var(--cyan20);
    }
  }

  &_manager {
    display: flex;
    width: 32px;
    border-radius: 2px;
    background-color: var(--green20);
    color: var(--green120);
    padding: 6px 10px;
  }

  &__title {
    border: none;
    color: var(--blue120);
    transition: opacity $transition;

    &:hover {
      opacity: 0.8;
    }
  }
}

[data-theme|='dark'] {
  .roles-full-table__title {
    color: #3392ff;
  }
}

[data-theme='light-contrast'] {
  .roles-full-table {
    &-type {
      &_group {
        color: var(--green180);
      }

      &_system {
        color: var(--cyan180);
      }
    }

    &__title {
      color: var(--blue170);
    }
  }
}
