@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integrations-ticket-types {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $indent-md;
  }

  &__select {
    width: 239px;

    &-type {
      width: 215px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    margin-right: $indent-lg;
    fill: var(--grey100);
    stroke: var(--grey100);
  }
}
