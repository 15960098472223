@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-history-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px $indent-sm;

  &__left-block {
    display: flex;
    align-items: center;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: $indent-sm;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: $indent-xs;
  }

  &__date {
    color: var(--grey100);
    font-weight: 400;
    font-size: $xs-text;
  }

  &__creator {
    color: var(--grey100);
    text-align: right;
  }

  &__sub-status {
    color: var(--grey100);
    margin-bottom: $indent-xxs;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: $border-radius-sm;
    color: var(--common2);

    &_create {
      background-color: var(--orange90);
    }

    &_appoint {
      background-color: var(--blue20);
      color: var(--blue80);
    }

    &_in-work {
      background-color: var(--blue30);
      color: var(--blue100);
    }

    &_waiting-info {
      background-color: var(--cyan30);
      color: var(--cyan100);
    }

    &_waiting-to-close {
      background-color: var(--green30);
      color: var(--green100);
    }

    &_closed {
      background-color: var(--green100);
    }

    &_reopen {
      background-color: var(--orange90);
    }

    &_new-appoint {
      background-color: var(--yellow30);
      color: var(--yellow120);
    }
  }
}

[data-theme|='dark'] {
  .ticket-history-tab__icon-wrapper {
    color: var(--grey200);

    &_in-work {
      color: var(--grey180);
    }

    &_waiting-to-close {
      color: var(--grey180);
    }

    &_waiting-info {
      color: var(--grey180);
    }

    &_appoint {
      color: var(--grey180);
    }

    &_create {
      color: var(--grey200);
    }

    &_closed {
      background-color: var(--green100);
      color: var(--grey200);
    }

    &_new-appoint {
      color: var(--yellow120);
    }
  }
}

[data-theme='light-contrast'] {
  .ticket-history-tab {
    &__icon-wrapper {
      &_appoint {
        color: var(--blue160);
      }

      &_in-work {
        color: var(--blue180);
      }

      &_waiting-to-close {
        color: var(--green180);
      }
    }
  }
}
