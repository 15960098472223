@use "./src/shared/assets/scss/variables/index.scss" as *;
.news-list {
  min-height: 93px;
  display: flex;
  flex-basis: 100%;
  padding: $indent-xs 0;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar:horizontal {
    height: 0;
    width: 0;
  }

  &__item {
    padding-top: $indent-sm;
    padding-bottom: $indent-lg;
    min-height: 93px;
    border-bottom: 1px solid var(--grey20);

    $this: &;

    &:hover {
      cursor: pointer;
    }

    &_disabled {
      &:hover {
        cursor: not-allowed;
      }
    }

    &-wrapper {
      padding: 0 $indent-xl;
      transition: background-color $transition ease-in-out;

      $this: &;

      &:hover:not(#{$this}_active) {
        background-color: var(--blue10);
      }

      &_active {
        background-color: var(--blue20);
        transition: all $transition ease-in-out;
      }
    }
  }

  &__content {
    width: 100%;
  }

  &__no-data {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--grey120);
    font-size: $md-text;
  }

  @media screen and (max-width: 768px) {
    padding: $indent-md 0;

    &__item {
      &-wrapper {
        padding: 0 $indent-md;
      }
    }
  }
}
