@use "./src/shared/assets/scss/variables/index.scss" as *;
.attachments-contract {
  background-color: var(--common2);
  border-radius: $border-radius;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 $indent-md;
    min-height: $table-header-height;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color: var(--common2);
    color: var(--grey200);
    box-shadow: inset 0 -2px 0 0 var(--grey20);
  }

  &__list {
    overflow-y: auto;
    height: 365px;
    margin: $indent-md 0;
  }

  &__empty {
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
    border-top: 1px solid var(--grey20);

    &__header {
      font-size: $sm-text;
    }

    &__list {
      height: auto;
      max-height: 296px;
    }
  }
}
