@use "./src/shared/assets/scss/variables/index.scss" as *;
.header {
  margin-bottom: $indent-sm;

  &_with-tabs {
    padding-bottom: 0;
  }

  &__sub-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    margin-right: $indent-lg;
  }

  &__button {
    margin-top: 7px;

    &:focus {
      outline: 2px solid var(--blue180);
      border-radius: $border-radius-sm;
    }
  }

  &__tabs {
    margin-top: 18px;
    display: flex;
    overflow-x: auto;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: -1px;
    border-radius: 0;
    padding-left: $indent-sm;
    padding-right: $indent-sm;

    &__button {
      top: 16px;
      position: absolute;
      margin-top: 0;
    }

    &__create-button {
      display: none;
    }

    &__exit-button {
      display: none;
    }

    &__sub-wrapper {
      justify-content: center;
    }

    &__title {
      margin-right: 0;
      font-size: $lg-text;
    }

    &__search {
      margin-right: 6px;
    }

    &__icons {
      display: flex;
      position: absolute;
      align-items: center;
      right: 15px;
      top: 13px;
    }

    &__tabs {
      margin-top: $indent-xl;
      box-shadow: inset 0 -2px 0 0 var(--grey20);

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
    }
  }
}
