@use "./src/shared/assets/scss/variables/index.scss" as *;
.roles-create {
  &__form {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 18px;
  }

  &__text-area {
    height: 110px;
  }

  &__type {
    margin: 0 auto;

    &_in-system {
      color: var(--cyan120);
      background-color: var(--cyan20);
    }

    &_in-group {
      background-color: var(--green20);
      color: var(--green120);
    }
  }

  &__is-manager {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: $indent-xxl 0;
  }
}

[data-theme='light-contrast'] {
  .roles-create {
    &__type {
      &_in-system {
        color: var(--cyan180);
      }

      &_in-group {
        color: var(--green180);
      }
    }
  }
}
