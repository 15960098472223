@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-tab {
  &__tab {
    color: var(--grey100);
    font-size: $md-text;
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: color $transition, border-color $transition;

    &:hover,
    &_active {
      border-bottom: 2px solid var(--blue120);
      color: var(--grey200);
      font-weight: 500;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
