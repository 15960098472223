@use "./src/shared/assets/scss/variables/index.scss" as *;
.circular-progress-bar {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;

  &-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    stroke: var(--green100);
    stroke-width: 20px;
    fill: transparent;
    transform-origin: center;
    transform: rotate(-90deg);
    transition: stroke-dashoffset 0.3s;
  }
}

.rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-title {
    display: flex;
    align-items: center;
    height: 10px;
    margin-bottom: $indent-lg;
    color: var(--grey120);
  }

  &-icon {
    min-width: 16px;
    height: 16px;
    margin-left: $indent-md;
    outline: none;
  }

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 136px;
    height: 136px;
    border-radius: 50%;
    background-color: var(--grey10);
  }

  &-star {
    width: 16px;
    height: 16px;
    color: var(--yellow100);
    fill: var(--yellow100);
  }

  &-star-center {
    margin-bottom: $indent-xxs;
    width: 24px;
    height: 24px;
    color: var(--yellow100);
    fill: var(--yellow100);
  }
}

.tooltip {
  width: 270px;
  padding-top: $indent-sm;
  padding-bottom: $indent-xs;

  &-title {
    margin-left: $indent-lg;
    margin-bottom: $indent-md;
    color: var(--grey40);
  }

  &-problem {
    display: flex;
    align-items: center;
    margin-left: $indent-xl;
    margin-bottom: $indent-xs;
    font-weight: 400;
    font-size: $sm-text;
    line-height: 18px;
    color: var(--grey10);
  }

  &-problem-icon {
    margin-right: $indent-sm;
    min-width: 24px;
    height: 24px;
    color: var(--yellow120);
  }
}

[data-theme|='dark'] {
  .tooltip {
    &-title {
      color: var(--common1);
    }

    &-problem {
      color: var(--common1);
    }
  }
}
