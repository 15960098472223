@use "./src/shared/assets/scss/variables/index.scss" as *;
.sla-timer {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_small {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    &_small {
      margin-right: $indent-xs;
    }
  }

  &__element {
    font-family: $ALS-Hauss;
    font-weight: 700;
    font-size: 34px;

    &_small {
      font-family: $Roboto;
      font-size: $xl-text;
    }

    &_number {
      color: var(--grey200);
    }

    &_colon {
      color: var(--grey100);
      margin: 0 6px;
    }
  }

  &__all-time {
    font-family: $ALS-Hauss;
    font-size: 20px;
    white-space: nowrap;
    color: var(--grey100);
    margin-top: $indent-xxs;
    line-height: 51px;

    &_small {
      font-size: $md-text;
      line-height: 21.5px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 768px) {
    &__element {
      font-size: $sm-text;
    }

    &__all-time {
      &_small {
        margin-top: 0;
        font-size: $xs-text;
      }
    }

    &_small {
      align-items: center;
    }
  }
}
