@use "./src/shared/assets/scss/variables/index.scss" as *;
.storage-info-block {
  --header-height: 64px;

  height: 100%;
  min-width: 420px;

  &__info-button {
    position: absolute;
    right: 40px;
    bottom: 100px;
    width: 40px;
    height: 40px;
    color: var(--grey140);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color $transition;
    overflow: hidden;
    border-radius: 50%;
    background-color: var(--grey20);

    & svg {
      width: 24px;
      height: 24px;
    }
  }

  &__header {
    align-items: center;
    background-color: var(--grey10);
    border-bottom: 1px solid var(--grey20);
    border-radius: $border-radius $border-radius 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: var(--header-height);
    padding: 0 $indent-xl;

    &-close {
      &-button {
        height: 24px;

        &-mobile {
          display: none;
        }
      }

      &-icon {
        color: var(--grey100);
      }
    }
  }

  &__container {
    padding: $indent-lg 42px;
    display: flex;
    height: calc(100% - var(--header-height));
    justify-content: center;
  }

  &__content {
    background-color: var(--common2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: $indent-xl;

    &-icon {
      color: var(--grey60);
    }
  }
}
