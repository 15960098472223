@use "./src/shared/assets/scss/variables/index.scss" as *;
.notification-information {
  &__ticket {
    max-width: 450px;
    display: flex;
    overflow-y: auto;
  }

  &_empty {
    display: flex;
    min-width: 450px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-basis: 26%;

    &__message {
      text-align: center;
      margin-top: 34px;
      color: var(--grey120);
    }
  }

  &__empty-icon {
    & path {
      stroke: var(--grey20);
    }
  }

  @media screen and (max-width: 1300px) {
    min-width: 320px;

    &_empty {
      min-width: 320px;
    }
  }

  @media screen and (max-width: 1200px) {
    &_empty {
      flex-basis: 35%;
      max-height: 272px;
    }
  }

  @media screen and (max-width: 768px) {
    &_empty {
      display: none;
    }
  }
}
