@use "./src/shared/assets/scss/variables/index.scss" as *;
.time-picker-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-height: 32px;
  cursor: grab;
  border-radius: $border-radius-sm;

  &_active {
    background-color: var(--blue10);
  }

  &:hover {
    background-color: var(--grey10);
  }

  @media screen and (max-width: 768px) {
    width: auto;
    display: flex;
    padding: $indent-xs;

    &:focus {
      background-color: var(--blue10);
      color: var(--blue120);
    }
  }
}
