@use "./src/shared/assets/scss/variables/index.scss" as *;
.toggle-switch {
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: $indent-sm;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  $this: &;

  &_disabled {
    #{$this}__input {
      pointer-events: none;

      &:checked + #{$this}__slider {
        background-color: var(--blue50);
      }
    }

    #{$this}__slider {
      background-color: var(--grey50);
    }

    #{$this}__label {
      color: var(--grey80);
    }

    &:hover {
      cursor: not-allowed;
    }
  }

  &__input {
    opacity: 0;
    width: 32px;
    height: 20px;

    &:checked + #{$this}__slider {
      background-color: var(--blue120);

      &::before {
        -webkit-transform: translateX(12px);
        -ms-transform: translateX(12px);
        transform: translateX(12px);
      }
    }

    &:hover:not(:disabled) {
      &:not(:checked) {
        + #{$this}__slider {
          background-color: var(--grey60);
        }
      }
      & + #{$this}__slider {
        background-color: var(--blue100);
      }
    }

    &:focus {
      &+ #{$this}__slider {
        border-color: var(--blue180);
      }

      &:not(:focus-visible) + #{$this}__slider {
        border-color: transparent;
      }
    }
  }

  &__slider {
    width: 32px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--grey80);
    transition: background-color $transition;
    border-radius: 100px;
    border: 2px solid transparent;

    &::before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: var(--common2);
      -webkit-transition: $transition;
      transition: $transition;
      border-radius: 50%;
    }
  }

  &__label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
