@use "./src/shared/assets/scss/variables/index.scss" as *;
.desktop-employees {
  display: flex;
  grid-gap: $indent-sm;

  &__left-container {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;
    flex-basis: 25%;
  }

  &__table {
    flex-basis: 75%;
    min-width: 300px;
  }

  &__progress-bars {
    display: none;
  }

  &__vl:nth-child(6) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    &__left-container {
      flex-basis: auto;
    }

    &__table {
      flex-basis: auto;
    }
  }

  @media screen and (max-width: 768px) {
    grid-gap: 0;

    &__left-container {
      grid-gap: 0;
    }
  }
}
