@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integration-create-left-form {
  &__left-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: $indent-4xl;
    width: 100%;
  }

  &__left-container {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--grey20);
    padding-right: $indent-xxxl;
    padding-bottom: $indent-md;
    align-items: center;
    row-gap: $indent-xl;
  }

  &__connection-form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__login-block {
    display: flex;
    grid-gap: $indent-sm;
  }

  &__element {
    &_mb {
      margin-bottom: $indent-lg;
    }

    &_one-of-two {
      flex-basis: 50%;
    }
  }

  &__check-connection {
    &-block {
      display: flex;
      align-items: center;
    }

    &-button {
      margin-right: $indent-xxl;
      min-width: 190px;
    }

    &-title-block {
      display: flex;
      align-items: center;
    }

    &-text {
      margin-left: $indent-md;
      color: var(--grey120);
    }

    &-icon {
      min-width: 24px;
      height: 24px;
      color: var(--red70);

      &_success {
        min-width: 16px;
        height: 16px;
        color: var(--green120);
      }
    }
  }

  &__select-connection-block {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-title {
      color: var(--grey200);
    }
  }

  @media screen and (max-width: 1200px) {
    &__left-container {
      border-right: none;
      padding-right: 0;
      padding-bottom: $indent-md;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    &__check-connection {
      &-block {
        flex-direction: column;
        align-items: flex-start;
      }

      &-button {
        margin-bottom: $indent-sm;
      }
    }
  }
}
