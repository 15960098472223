@use "./src/shared/assets/scss/variables/index.scss" as *;
.status-detail {
  &__input {
    margin-bottom: $indent-md;
  }

  &__sub-title {
    color: var(--grey200);
    margin-bottom: $indent-xs;
  }

  &__slide-radio {
    margin-bottom: $indent-md;
  }

  &__radio {
    &-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: $indent-lg;
    }

    &-icon {
      outline: none;
      color: var(--blue120);
      height: 24px;
      width: 24px;
    }
  }

  &__button {
    width: 100px;
  }

  &__proposed-status {
    &-wrapper {
      background-color: var(--blue10);
      border-radius: $border-radius-md;
      min-height: 70px;
      padding: 0 $indent-md;
      display: flex;
      align-items: center;
      margin-bottom: $indent-md;
    }

    &-icon {
      color: var(--blue120);
      margin-right: $indent-sm;
      min-width: 24px;
    }

    &-text {
      color: var(--grey200);
      margin-right: $indent-md;
    }

    &-button {
      min-width: 100px;
    }
  }

  &__colors-radio {
    margin-bottom: $indent-md;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__delete-button {
    color: var(--red120) !important;
  }

  &__info-block {
    margin-bottom: $indent-md;

    &-icon {
      color: var(--grey100);
    }

    &-info {
      white-space: normal;
    }
  }

  &__switch {
    margin-right: $indent-xs;
  }

  @media screen and (max-width: 768px) {
    &__footer {
      flex-direction: column-reverse;
      align-items: flex-start;
      grid-gap: $indent-lg;
    }

    &__proposed-status {
      &-wrapper {
        min-height: auto;
        padding: $indent-sm $indent-md;
      }
    }

    &__buttons-wrapper {
      display: flex;
      flex-direction: column-reverse;
      grid-gap: $indent-xxs;
      width: 100%;
    }

    &__button {
      width: 100%;
    }
  }
}
