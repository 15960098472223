@use "./src/shared/assets/scss/variables/index.scss" as *;
.current-ticket-adaptive {
  padding: $indent-md;
  border-radius: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  grid-gap: $indent-xs;

  &__ticket-attachments {
    overflow-x: auto;
  }

  &_with-priority {
    border-left: $indent-xxs solid var(--green120);
    border-radius: $border-radius-sm;
  }

  &_none {
    border-color: transparent;
  }

  &_low {
    border-color: var(--green120);
  }

  &_middle {
    border-color: var(--yellow90);
  }

  &_high {
    border-color: var(--orange110);
  }

  &_very-high {
    border-color: var(--red130);
  }

  &__header {
    display: flex;
    align-items: center;

    &-tag {
      margin-right: $indent-md;
    }
  }

  &__priority {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      color: var(--grey120);

      &_button {
        color: var(--blue120);
      }
    }
  }

  &__tooltip-system {
    max-width: 280px;

    &-version {
      font-weight: 500;
      margin-bottom: $indent-xs;
    }
  }

  &__title {
    overflow: hidden;
  }

  &__description {
    word-wrap: break-word;
    font-size: $xs-text;
    line-height: 18px;
    color: var(--grey120);
    max-width: 700px;
  }

  &__solution {
    margin-top: $indent-sm;
  }

  &__sla {
    padding-top: $indent-sm;
    border-top: 1px solid var(--grey20);
  }

  &__buttons-wrapper {
    margin-bottom: 8px;
    display: flex;
  }

  &__take-in-process {
    width: 100%;
    max-width: 600px;
  }

  &__system-info {
    display: flex;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    &-item {
      position: relative;
      margin-right: 64px;

      &:not(:first-child)::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: $indent-lg;
        background-color: var(--grey30);
        position: absolute;
        left: -$indent-xxxl;
        bottom: 4px;
      }
    }
  }

  &__icon-inner {
    color: var(--grey100);
    width: 16px;
    height: 16px;
  }

  &__block-info {
    font-size: $sm-text;
  }

  &__similar-solutions {
    display: flex;
    align-items: center;
    transition: opacity $transition;

    &:hover {
      opacity: 0.8;
    }

    &-icon {
      color: var(--blue120);
      width: 20px;
      height: 20px;
    }

    &-title {
      color: var(--blue120);
      margin-left: $indent-xs;
    }
  }
}
