@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-main-welcome {
  padding: 40px;

  &__bookIcon {
    width: 64px;
    height: 64px;
    color: var(--blue60);
    margin-bottom: 40px;
  }

  &__title {
    color: var(--grey200);
    margin-bottom: $indent-sm;
  }

  &__sub-title {
    color: var(--grey120);
    text-align: center;
    margin-bottom: 53px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1027px;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    padding: $indent-lg;
  }
}
