@use "./src/shared/assets/scss/variables/index.scss" as *;
.user-assistance-start-block {
  padding: $indent-sm $indent-xl $indent-xl $indent-xl;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    color: var(--blue60);
    margin-bottom: $indent-sm;
  }

  &__title {
    color: var(--grey170);
    margin-bottom: $indent-sm;
    text-align: center;
  }

  &__text {
    color: var(--grey120);
    text-align: center;
  }

  &__button {
    margin-top: $indent-sm;
  }

  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;

    &__button {
      width: 100%;
    }

    &__title {
      width: 80%;
    }

    &__text {
      width: 80%;
    }
  }
}
