@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-category-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-width: 300px;
  max-width: 300px;
  grid-gap: $indent-sm;
  height: fit-content;
  transition: grid-gap $transition cubic-bezier(-0.1, 1, 0, 1);

  &__roll-up {
    grid-gap: 0;
    transition: grid-gap $transition cubic-bezier(1, -0.1, 1, 0);
  }

  &__articles {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-xs;
    width: 100%;
    max-height: 9999px;
    height: auto;
    overflow: hidden;
    transition: max-height $transition cubic-bezier(1, 0, 1, 0);

    &_with-margin {
      margin-left: 30px;
      max-width: 270px;
      min-width: 270px;
    }

    &_hidden {
      max-height: 0;
      transition: max-height $transition cubic-bezier(0, 1, 0, 1);
    }

    &-item {
      color: var(--grey120);
    }
  }

  &__icon {
    color: var(--grey60);
  }

  &__title {
    font-weight: 500;
    color: var(--grey150);
    font-size: $md-text;
    max-width: 100%;
    flex-basis: 100%;

    &_full {
      max-width: calc(100% - 31px);
    }

    &-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 55px);
    }

    &_sub-category {
      font-size: $sm-text;
      font-weight: 400;
    }
  }

  &__show-all-button {
    display: flex;
    align-self: center;
    font-weight: 500;
    color: var(--blue120);
    cursor: pointer;
    opacity: 1;
    transition: 0.2s opacity;

    &:hover {
      transition: 0.2s opacity;
      opacity: 0.8;
    }
  }

  &__category {
    &-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      &_with-padding {
        padding-right: 50px;
      }
    }
  }

  &__popover {
    position: absolute;
    right: 0;

    &-button {
      &:hover {
        background: none;
        opacity: 0.8;
      }
    }
  }

  &_is-sub-category {
    min-width: 270px;
    padding-left: 30px;
  }

  &_empty {
    grid-gap: 0;
  }
}
