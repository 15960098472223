@use "./src/shared/assets/scss/variables/index.scss" as *;
.typography {
  font-weight: var(--font-weight-400);

  &_h1,
  &_h2,
  &_h3,
  &_h4,
  &_h5,
  &_h6,
  &_h7,
  &_h8 {
    font-weight: var(--font-weight-500);
  }

  &_h1 {
    font-size: var(--font-size-28);
    line-height: var(--line-height-35);
  }

  &_h2 {
    font-size: var(--font-size-24);
    line-height: var(--line-height-30);
  }

  &_h3 {
    font-size: var(--font-size-20);
    line-height: var(--line-height-25);
  }

  &_h4 {
    font-size: var(--font-size-18);
    line-height: var(--line-height-23);
  }

  &_h5 {
    font-size: var(--font-size-16);
    line-height: var(--line-height-21);
  }

  &_h6 {
    font-size: var(--font-size-14);
    line-height: var(--line-height-18);
  }

  &_h7 {
    font-size: var(--font-size-12);
    line-height: var(--line-height-15);
  }

  &_h8 {
    font-size: var(--font-size-9);
    line-height: var(--line-height-12);
  }

  &_b1 {
    font-size: var(--font-size-18);
    line-height: var(--line-height-24);
  }

  &_b2 {
    font-size: var(--font-size-16);
    line-height: var(--line-height-21);
  }

  &_b3 {
    font-size: var(--font-size-14);
    line-height: var(--line-height-18);
  }

  &_b4 {
    font-size: var(--font-size-12);
    line-height: var(--line-height-16);
  }

  &_s2 {
    font-size: var(--font-size-12);
    line-height: var(--line-height-15);
  }

  &_o1 {
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-12);
    line-height: var(--line-height-15);
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &_mono {
    font-family: var(--roboto-mono);
    font-size: var(--font-size-14);
    line-height: var(--line-height-22);
  }

  &_menu {
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-11);
    line-height: var(--line-height-14);
  }
}
