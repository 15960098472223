@use "./src/shared/assets/scss/variables/index.scss" as *;
.systems-table-container {
  height: 100%;

  @media screen and (max-width: 1200px) {
    height: 768px;
  }

  @media screen and (max-width: 768px) {
    height: 354px;
  }
}
