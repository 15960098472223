@use "./src/shared/assets/scss/variables/index.scss" as *;
.current-ticket-header {
  padding: 0;
  display: flex;
  align-items: flex-start;

  &__title {
    margin-bottom: $indent-sm;

    &-block {
      padding: $indent-xl 0 $indent-xl 1px;
      border-radius: $border-radius-sm;
      display: flex;
      margin-left: -18px;

      &_none {
        border-left: $border-radius-sm solid var(--common2);
      }

      &_low {
        border-left: $border-radius-sm solid var(--green120);
      }

      &_middle {
        border-left: $border-radius-sm solid var(--yellow90);
      }

      &_high {
        border-left: $border-radius-sm solid var(--orange110);
      }

      &_very-high {
        border-left: $border-radius-sm solid var(--red130);
      }

      &-wrapper {
        display: flex;
        justify-content: space-between;
      }

      &-sub-wrapper {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-left: 17px;
      }
    }
  }

  &__priority {
    color: var(--grey120);
    margin-right: 10px;

    &-block {
      margin-left: 2px;
      display: flex;
      align-items: center;
      color: var(--grey100);
      letter-spacing: 1px;
    }
  }

  &__arrow {
    height: fit-content;
    margin-top: 6px;

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      box-shadow: none;

      & svg {
        filter: drop-shadow($focused-svg-shadow var(--blue120));
      }
    }
  }

  &__ticket-info {
    &-block {
      display: flex;

      &-wrapper {
        padding: 31px $indent-xxl 31px 40px;
        display: flex;
        justify-content: space-between;
        flex-basis: 84%;
      }
    }

    &-item {
      margin-right: 64px;

      &::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 20px;
        background-color: var(--grey30);
        position: absolute;
        right: -$indent-xxxl;
        top: 10px;
      }

      &_new {
        background-color: var(--orange110);
      }

      &_in-process {
        background-color: var(--blue120);
      }

      &_closed {
        background-color: var(--green100);
      }

      &_waiting-to-close {
        background-color: var(--green50);
      }
    }
  }

  &__three-dots {
    display: none;
    height: fit-content;
    position: relative;
    top: 25px;

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      box-shadow: none;

      & svg {
        filter: drop-shadow($focused-svg-shadow var(--blue120));
      }
    }
  }
}
