@use "./src/shared/assets/scss/variables/index.scss" as *;
.users-from-desktop-table {
  &__cell {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &__avatar {
    margin-right: $indent-xs;
  }

  &__label {
    margin-right: 10px;

    &_in-work {
      color: var(--blue120);
    }

    &_closed {
      color: var(--green100);
    }

    &_expired {
      color: var(--red100);
    }
  }

  &__sla {
    display: flex;
    flex-direction: column;
  }

  &__progress-bar {
    width: 250px;
  }

  &__timer {
    margin-top: $indent-xxs;
    margin-left: 100px;
    color: var(--grey120);
  }
}
