@use "./src/shared/assets/scss/variables/index.scss" as *;
.icon-button-alt {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  height: fit-content;

  & svg {
    transition: color var(--transition-delay);
  }

  $this: &;

  &_3xs {
    & svg {
      min-width: 12px;
      width: 12px;
      height: 12px;
    }
  }

  &_xxs {
    & svg {
      min-width: 16px;
      width: 16px;
      height: 16px;
    }
  }

  &_xs {
    & svg {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &_blue {
    & svg {
      color: var(--blue-140);
    }

    &:hover {
      & svg {
        color: var(--blue-100);
      }
    }

    &:active {
      & svg {
        color: var(--blue-140);
      }
    }
  }

  &_grey {
    & svg {
      color: var(--grey-140);
    }

    &:hover {
      & svg {
        color: var(--grey-100);
      }
    }

    &:active {
      & svg {
        color: var(--grey-140);
      }
    }
  }

  &_red {
    & svg {
      color: var(--red-140);
    }

    &:hover {
      & svg {
        color: var(--red-100);
      }
    }

    &:active {
      & svg {
        color: var(--red-140);
      }
    }
  }

  &_yellow {
    & svg {
      color: var(--yellow-180);
    }

    &:hover {
      & svg {
        color: var(--yellow-160);
      }
    }

    &:active {
      & svg {
        color: var(--yellow-180);
      }
    }
  }

  &_green {
    & svg {
      color: var(--green-140);
    }

    &:hover {
      & svg {
        color: var(--green-100);
      }
    }

    &:active {
      & svg {
        color: var(--green-140);
      }
    }
  }

  &:disabled {
    cursor: not-allowed;

    & svg {
      color: var(--grey-60);
    }
  }

  // TODO: Не макет
  &:focus {
    outline: 2px solid var(--blue-180);
  }
}
