@use "./src/shared/assets/scss/variables/index.scss" as *;
.typography-link {
  font-weight: var(--font-weight-400);
  text-decoration: underline;
  transition: color var(--transition-delay);

  &_grey {
    color: var(--grey-160);

    &:hover {
      color: var(--blue-120);
    }
  }

  &_red {
    color: var(--red-120);

    &:hover {
      color: var(--red-100);
    }
  }

  &_green {
    color: var(--green-120);

    &:hover {
      color: var(--green-100);
    }
  }

  &_yellow {
    color: var(--yellow-160);

    &:hover {
      color: var(--yellow-140);
    }
  }

  &_disabled {
    pointer-events: none;
    color: var(--grey-80);
  }

  &_l1 {
    font-size: var(--font-size-16);
    line-height: var(--line-height-21);
  }

  &_l2 {
    font-size: var(--font-size-14);
    line-height: var(--line-height-18);
  }

  &_sl {
    font-size: var(--font-size-12);
    line-height: var(--line-height-16);
  }
}
