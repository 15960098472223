@use "./src/shared/assets/scss/variables/index.scss" as *;
.more-tag {
  &__container {
    display: flex;
    align-items: center;
    grid-gap: $indent-xs;
  }

  &__data {
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--grey120);
  }
}
