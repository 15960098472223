@use "./src/shared/assets/scss/variables/index.scss" as *;
.organization-layout {
  &__icon-delete {
    color: var(--red100);
  }

  &__modal {
    &-delete {
      max-width: 522px;
      line-height: 1.25;
    }

    &-text {
      padding: $indent-xl;
      text-align: center;
      line-height: 1.25;
    }
  }

  @media screen and (max-width: 768px) {
    &__modal {
      &-text {
        padding: 0;
      }

      &-delete {
        max-width: none;
      }
    }
  }
}
