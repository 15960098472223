@use "./src/shared/assets/scss/variables/index.scss" as *;
.create-SLA {
  max-width: 994px;
  width: 97%;

  &__buttons-block-add {
    margin: 0;
    padding: $indent-sm $indent-xl $indent-xl 0;
  }

  &__sla {
    flex-grow: 1;
    width: 150px;

    &_modal {
      border-bottom: 2px solid var(--grey10);
    }
  }

  &__form {
    display: flex;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__header {
    padding: $indent-xl $indent-xl $indent-sm;
  }

  &__search {
    border-right: 2px solid var(--grey10);
    border-top: 2px solid var(--grey10);
    border-bottom: 2px solid var(--grey10);
    padding: $indent-xl $indent-xxl $indent-lg $indent-lg;

    &-header {
      display: block;
      margin-bottom: $indent-lg;
    }
  }

  &__auto-closing {
    padding: $indent-lg $indent-xl;
    border-top: 2px solid var(--grey10);
  }

  & form {
    padding: 0;
    border-radius: 0 0 $border-radius $border-radius;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    &__buttons-block-add {
      padding: $indent-sm $indent-xl;
    }

    &__sla {
      width: auto;
    }

    &__form {
      flex-direction: column;
    }
  }
}
