@use "./src/shared/assets/scss/variables/index.scss" as *;
.file-input {
  &__wrapper {
    width: 100%;
    text-align: center;
    padding: 22px 0;
    border: 2px dashed var(--grey30);
    border-radius: $border-radius-sm;
    margin-bottom: $indent-lg;

    &:focus {
      outline: 2px solid var(--blue180);
      border-color: transparent;
    }
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    grid-gap: 1rem;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &-span {
      color: var(--grey120);
      margin: 0 44px;
    }

    &-icon {
      margin-right: $indent-xxxl;
      color: var(--blue120);
    }
  }

  &__warning {
    color: var(--grey120);
  }

  @media screen and (max-width: 1200px) {
    &__content {
      &-span {
        margin: 0 $indent-md;
      }

      &-icon {
        margin-right: $indent-md;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      &-span {
        color: var(--common1);
        margin: 0 14px;
      }

      &-icon {
        margin-right: 0;
      }
    }

    &__list {
      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
    }
  }
}
