@use "./src/shared/assets/scss/variables/index.scss" as *;
.work-groups {
  display: flex;
  flex-direction: row;
  min-height: auto;
  max-height: none;
  flex-basis: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &__sub-wrapper {
    overflow-y: initial;
  }

  &__main-wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }

  @media screen and (max-width: 1200px) {
    display: block;
  }

  @media screen and (max-width: 768px) {
    overflow: visible;
    min-height: auto;
    height: 100%;

    &__main-wrapper {
      grid-gap: 0;
    }
  }
}
