@use "./src/shared/assets/scss/variables/index.scss" as *;
.loader {
  &_xs {
    width: 30px;
    height: 30px;
  }

  &_s {
    width: 38px;
    height: 38px;
  }

  &_m {
    width: 46px;
    height: 46px;
  }

  &_l {
    width: 54px;
    height: 54px;
  }
}
