@use "./src/shared/assets/scss/variables/index.scss" as *;
.ticket-spoiler {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  grid-gap: 4px;
  flex-wrap: wrap;
  padding: 44px 0;
  font-size: $sm-text;
  color: var(--grey120);

  &__title-spoiler {
    color: var(--blue120);

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &__modal {
    padding: $indent-xl;
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    line-height: 18px;
  }
}
