@use "./src/shared/assets/scss/variables/index.scss" as *;
.addAttachmentsModal {
  &__fileInput {
    &-content {
      flex-direction: column;
      grid-gap: $indent-md;

      svg {
        margin: 0;
      }
    }

    &-wrapper {
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
