@use "./src/shared/assets/scss/variables/index.scss" as *;
.sla-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  border-top: 1px solid var(--grey20);

  &__icons {
    position: relative;
    width: 100%;
    padding-top: 70px;
    display: flex;
    justify-content: center;

    &-nav {
      width: 45px;
      height: 45px;
      color: var(--grey20);
    }
  }

  &__text {
    color: var(--grey120);
    width: 143px;
    margin-top: 40px;
    margin-bottom: 122px;
    text-align: center;
  }
}
