@use "./src/shared/assets/scss/variables/index.scss" as *;
.responsibilities {
  display: flex;
  flex-direction: row;
  min-height: auto;
  max-height: none;
  flex-basis: 100%;
  overflow: auto;

  @media screen and (max-width: 1200px) {
    display: block;
  }

  @media screen and (max-width: 768px) {
    min-height: auto;
    height: 100%;
  }
}
