@use "./src/shared/assets/scss/variables/index.scss" as *;
.toast {
  display: flex;
  align-items: center;
  padding: $indent-lg $indent-xl;
  border-radius: 8px;
  position: fixed;
  top: 75px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  min-width: 362px;
  max-width: 526px;
  height: 64px;
  justify-content: space-between;
  animation: appearance $transition forwards;

  &_error {
    background: var(--red30);
  }

  &_success {
    background: var(--green30);
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__title {
    &_error {
      color: var(--red150);
    }

    &_success {
      color: var(--green160);
    }
  }

  &__button-close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    line-height: 0;
    transition: opacity $transition;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &:focus {
      outline: 2px solid var(--blue180);
      border-radius: $border-radius-sm;
    }
  }

  &__icon {
    margin-right: 10px;
    color: var(--red150);

    &-close {
      width: 12px;
      height: 12px;

      &_error {
        color: var(--red150);
      }

      &_success {
        color: var(--green160);
      }

      & path {
        stroke-width: 4;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 300px;
    max-width: none;
    min-width: 0;
    padding: $indent-xs $indent-md;
    top: 24px;

    &__title {
      font-size: $xs-text;
    }
  }
}

@keyframes
  appearance {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
}
