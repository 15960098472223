@use "./src/shared/assets/scss/variables/index.scss" as *;
.sla-auto-closing {
  display: flex;
  flex-direction: row;
  font-size: $sm-text;
  width: 100%;
  grid-gap: $indent-sm;

  &__input {
    min-width: 106px;
    width: 175px;
  }

  &__time {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: $indent-sm;
    flex-basis: 100%;
    max-width: 0;
    overflow: hidden;
    transition: overflow $transition cubic-bezier(0, 1, 0, 1), max-width $transition cubic-bezier(0, 1, 0, 1);

    &_visible {
      max-width: 100%;
      transition: overflow $transition cubic-bezier(1, 0, 1, 0), max-width $transition cubic-bezier(1, 0, 1, 0);
    }
  }

  &__slide {
    max-width: 300px;
    min-width: 150px;
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: $indent-sm;

    &-icon {
      color: var(--blue120);
      outline: none;
    }

    &-container {
      display: flex;
      flex-direction: row;
      grid-gap: $indent-sm;
      align-items: center;
    }
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;

    &__time {
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 630px) {
    &__toggle {
      &-container {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  @media screen and (max-width: 360px) {
    &__time {
      flex-wrap: wrap;
    }
  }
}
