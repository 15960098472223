@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-article-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.2s opacity;
  cursor: pointer;
  position: relative;

  &__icon {
    color: var(--blue60);
  }

  &__title {
    color: inherit;
    white-space: nowrap;
    max-width: 100%;

    &-text {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 36px);
    }

    &:hover {
      opacity: 0.8;
      transition: 0.2s opacity;
    }
  }

  &_outlined {
    border: 1px solid var(--grey30);
    border-radius: $border-radius-sm;
    padding: $indent-xxs $indent-xs;
  }

  &_with-drop {
    padding-right: 50px;
    padding-left: 30px;
    position: relative;
  }

  &__popover {
    position: absolute;
    right: 0;

    &-button {
      &:hover {
        background: none;
        opacity: 0.8;
      }
    }
  }

  &__article {
    &-info {
      display: inline-block;
      padding-left: $indent-md;
      white-space: nowrap;
      color: var(--grey100);
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-organization {
      display: inline-block;
    }
  }
}
