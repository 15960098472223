@use "./src/shared/assets/scss/variables/index.scss" as *;
.kpi {
  display: flex;
  flex-direction: column;
  grid-gap: $indent-sm;

  &__container {
    display: flex;
    grid-gap: $indent-sm;
  }

  @media screen and (max-width: 1600px) {
    &__container {
      flex-direction: column;
    }
  }
}
