@use "./src/shared/assets/scss/variables/index.scss" as *;
.SLA-table-short {
  display: flex;
  height: 434px;
  flex-direction: column;
  padding: 0;

  &__header {
    box-shadow: none;
  }

  &__table {
    padding-top: 0;
  }

  &__empty {
    height: calc(100% - 58px);
  }
}
