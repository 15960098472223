@use "./src/shared/assets/scss/variables/index.scss" as *;
.KB-category {
  &__popover {
    position: absolute;
    right: 0;

    &-button {
      &:hover {
        background: none;
        opacity: 0.8;
      }
    }
  }

  &__articles {
    display: flex;
    flex-direction: column;
    grid-gap: $indent-xs;
    width: 100%;
    max-height: 9999px;
    height: auto;
    overflow: hidden;
    transition: max-height $transition cubic-bezier(1, 0, 1, 0);

    &-item {
      color: var(--grey120);
    }
  }
}
