@use "./src/shared/assets/scss/variables/index.scss" as *;
.main-layout {
  flex-basis: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: $indent-sm $indent-xl $indent-xl $indent-xl;

  &__sticky-block {
    position: sticky;
    bottom: 0;
    z-index: $z-index-sticky-block;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    background-color: var(--common2);
    flex-grow: 1;

    &__sticky-block {
      bottom: 82px;
    }
  }
}
