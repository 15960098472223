@use "./src/shared/assets/scss/variables/index.scss" as *;
.user-layout {
  &__title-info-block {
    background-color: var(--green120);
  }

  &__icon {
    color: var(--grey100);
    width: 20px;
    min-width: 20px;

    &-delete {
      color: var(--red100);
    }
  }

  &__user-icon {
    color: var(--common2);
    width: 20px;
  }
}

[data-theme|='dark'] {
  .user-layout__user-icon {
    color: var(--grey180);
  }
}
