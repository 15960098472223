@use "./src/shared/assets/scss/variables/index.scss" as *;
.access-to-sections {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__icon {
    color: var(--grey100);
  }

  &__description {
    text-align: center;
    color: var(--grey120);
    margin: $indent-xs 0 36px;
  }

  &__switch-label {
    font-size: $md-text;
    font-weight: 500;
  }
}
