@use "./src/shared/assets/scss/variables/index.scss" as *;
.calendar {
  display: initial;

  &__dates {
    min-height: 192px;
    margin-bottom: $indent-xs;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    display: flow-root;
    width: 100%;
  }
}
