@use "./src/shared/assets/scss/variables/index.scss" as *;
.work-group {
  display: flex;
  justify-content: space-between;
  grid-gap: $indent-sm;

  &__left-container {
    flex-basis: 65%;
    min-width: 320px;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    grid-gap: $indent-sm;
  }

  &__right-container {
    flex-basis: 35%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    grid-gap: $indent-sm;
  }

  &__loader {
    height: 100%;
  }

  @media screen and (max-width: 1700px) {
    &__left-container {
      flex-basis: 60%;
    }

    &__right-container {
      flex-basis: 40%;
    }
  }

  @media screen and (max-width: 1500px) {
    &__left-container {
      flex-basis: 55%;
    }

    &__right-container {
      flex-basis: 45%;
    }
  }

  @media screen and (max-width: 1200px) {
    display: block;

    &__left-container {
      margin-bottom: $indent-sm;
    }
  }

  @media screen and (max-width: 768px) {
    min-height: auto;

    &__left-container {
      border-radius: 0;
      height: auto;
      min-height: 0;
      grid-gap: 0;
      min-width: 0;
    }

    &__right-container {
      height: auto;
    }
  }
}
