@use "./src/shared/assets/scss/variables/index.scss" as *;
.select {
  display: flex;
  position: relative;
  background-color: var(--common2);
  color: var(--grey200);
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  border-radius: $border-radius-sm;
  user-select: none;

  &_xs {
    height: 32px;
    min-width: 140px;
  }

  &_s {
    height: 40px;
  }

  &_m {
    height: 48px;
  }

  &_l {
    height: 56px;
  }

  &_is-multi {
    height: auto;

    &-search {
      min-width: 200px;
    }
  }

  &__container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 100%;
    flex: 1 1 100%;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    background: none;
    padding: 0 $indent-xxxl 0 $indent-sm;
    border: 1px solid var(--grey30);
    border-radius: $border-radius-sm;
    cursor: pointer;
    transition: border-color 0.3s;
    outline: none;

    &:hover:not(&_open):not(&_disabled) {
      border-color: var(--grey100);
    }

    &:focus:not(&_disabled) {
      border-color: var(--blue120);
      outline: none;
    }

    $this: &;

    &_open {
      border-color: var(--blue120);
    }

    &_with-arrow {
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 14px;
        display: block;
        width: 9px;
        height: 2px;
        transition: all 0.2s ease-out;
        background-color: var(--grey50);
        transform: translate(-2px, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(3px, -50%) rotate(-45deg);
      }
    }

    &_with-arrow#{$this}_open {
      &::before {
        transform: translate(-2px, -50%) rotate(-45deg);
      }

      &::after {
        transform: translate(3px, -50%) rotate(45deg);
      }
    }

    &_disabled {
      background: var(--grey10);
      border-color: var(--grey10);
      color: var(--grey130);
      cursor: not-allowed;

      .select__value {
        color: var(--grey130);
      }
    }

    &-icons {
      display: flex;
      margin-right: -20px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &_is-multi {
      position: static;
      height: auto;

      &_xs {
        min-height: 32px;
      }

      &_s {
        min-height: 40px;
      }

      &_m {
        min-height: 48px;
      }

      &_l {
        min-height: 56px;
      }
    }
  }

  &__input {
    display: flex;
    width: 100%;
    padding: 0;
    border: none;
    color: var(--grey200);
    box-sizing: content-box;

    &_disabled {
      cursor: not-allowed;
    }

    &_filled {
      flex: 1 0 100%;
      color: var(--text-primary);
    }

    &_is-multi {
      width: 100%;

      &-search {
        width: 40%;
      }
    }
  }

  &__placeholder {
    color: var(--grey100);
  }

  &__value {
    &_with-padding {
      position: absolute;

      &_xs {
        top: 6px;
      }

      &_s {
        top: 9px;
      }

      &_m {
        top: 22px;
      }

      &_l {
        top: 27px;
      }
    }
  }

  &__value,
  &__input {
    color: var(--grey200);
    cursor: inherit;
  }

  &__placeholder,
  &__value,
  &__input {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: initial;

    &_xs {
      font-size: $sm-text;
      line-height: 18px;
    }

    &_s {
      font-size: $sm-text;
      line-height: 18px;
    }

    &_m {
      font-size: $sm-text;
      line-height: 18px;
    }

    &_l {
      font-size: $md-text;
      line-height: 20px;
    }
  }

  &__reset-icon {
    position: absolute;
    right: $indent-xxl;
    color: var(--grey50);
    width: 20px;
    height: 20px;
    cursor: inherit;
  }

  &__search-icon {
    color: var(--grey50);
    width: 20px;
    height: 20px;
    cursor: inherit;
  }

  &__clear-icon {
    outline: none;

    &_multi-search {
      color: var(--grey50);
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;

    &-inner {
      width: 30px;
      height: 30px;
    }
  }

  &__label {
    position: absolute;
    color: var(--grey100);
    font-style: normal;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
    white-space: nowrap;
    max-width: calc(100% - 50px);
    text-overflow: ellipsis;
    overflow: hidden;

    &_xs {
      visibility: hidden;
    }

    &_s {
      visibility: hidden;
    }

    &_m {
      font-size: $xs-text;
      line-height: 15px;
      top: 5px;
    }

    &_l {
      font-size: $sm-text;
      line-height: 18px;
      top: 7px;
    }

    &_no-option {
      &_xs {
        visibility: visible;
        line-height: 18px;
        top: 6px;
        font-size: $sm-text;
      }

      &_s {
        visibility: visible;
        line-height: 18px;
        top: 9px;
        font-size: $sm-text;
      }

      &_m {
        line-height: 18px;
        top: 13px;
        font-size: $sm-text;
      }

      &_l {
        line-height: 20px;
        top: 18px;
        font-size: $md-text;
      }
    }

    &_hidden {
      display: none;
    }
  }

  &__multi {
    &-container {
      &_not-chip {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: calc(100% - 44px);
        white-space: nowrap;
        position: absolute;

        &_xs {
          top: 6px;
          font-size: $sm-text;
          line-height: 18px;
        }

        &_s {
          top: 9px;
          font-size: $sm-text;
          line-height: 18px;
        }

        &_m {
          top: 22px;
          font-size: $sm-text;
          line-height: 18px;
        }

        &_l {
          top: 27px;
          font-size: $md-text;
          line-height: 20px;
        }
      }

      &_clearable {
        max-width: calc(100% - 66px);
      }

      &_search {
        display: flex;
      }

      &_chip {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: $indent-xs;
        margin: $indent-xxs 0;
        font-size: $sm-text;
      }
    }
  }

  &__checkbox {
    margin-right: $indent-md;

    &-container {
      display: flex;
      align-items: center;
    }

    &-text {
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 5px);
      white-space: nowrap;
    }
  }

  &__option {
    &_no-result:hover {
      background-color: transparent;
      cursor: default;
    }

    &-add {
      &-entity {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        color: var(--grey100);
        text-transform: uppercase;
        font-size: $xs-text;
      }

      &-icon {
        color: var(--blue120);
        margin-right: 14px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__dropdown {
      display: none;
    }

    &__container {
      &_with-arrow {
        &::before {
          transform: translate(3px, -2.5px) rotate(45deg);
        }

        &::after {
          transform: translate(3px, 2.5px) rotate(-45deg);
        }
      }
    }
  }
}
