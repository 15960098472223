@use "./src/shared/assets/scss/variables/index.scss" as *;
.news-detail {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_loading {
    justify-content: center;
  }

  &__icon {
    margin-top: $indent-xl;
    color: var(--grey90);
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    color: var(--grey200);
    margin-top: $indent-xxxl;
    text-align: center;
  }

  &__data-empty {
    margin: 0 auto;
  }

  &__time {
    white-space: nowrap;
    margin-left: $indent-xs;
    font-size: $xs-text;

    &-icon {
      width: 12px;
    }

    &-wrapper {
      margin-top: $indent-xs;
      display: flex;
      align-items: center;
      color: var(--grey90);
    }
  }

  &__text {
    margin-top: 64px;
    width: 100%;
    overflow: auto;
    flex-basis: 100%;
  }

  @media screen and (max-width: 1300px) {
    padding: $indent-lg;
    align-items: flex-start;

    &__icon {
      min-height: 40px;
      height: 40px;
      min-width: 40px;
      margin-top: 0;
    }

    &__title {
      margin-top: 0;
      font-size: $lg-text;
      text-align: start;
    }

    &_loading {
      align-items: center;
    }

    &__header {
      flex-direction: row;
      align-items: center;
      grid-gap: $indent-xxxl;
    }

    &__info-wrapper {
      align-items: flex-start;
    }

    &__text {
      margin-top: $indent-xl;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    background-color: var(--common2);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: $z-index-drawer;
    overflow: hidden;

    &__mobile {
      &-header {
        position: relative;
        width: 100%;
        min-height: 55px;
        max-height: 55px;
        border-bottom: 1px solid var(--grey20);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--grey200);
      }

      &-content {
        padding: $indent-xl $indent-lg;
        width: 100%;
        min-height: 100px;
        flex-basis: 100%;
        overflow-y: auto;
      }
    }

    &__back-button {
      position: absolute;
      left: 12px;
      line-height: 0;

      & svg {
        height: 16px;
        width: 16px;
        color: var(--grey120);
      }
    }

    &__header {
      flex-direction: column;
      grid-gap: $indent-xl;
    }

    &__icon {
      min-height: 60px;
      height: 60px;
      min-width: 60px;
    }

    &__info-wrapper {
      align-items: center;
    }

    &__title {
      text-align: center;
    }
  }
}
