@use "./src/shared/assets/scss/variables/index.scss" as *;
.trigger-modal {
  height: 100vh;
  width: 100%;

  &__container {
    background-color: var(--common2);
    box-shadow: var(--box-shadow-lvl-2);
    border-radius: $border-radius 0 0 $border-radius;
    height: 100%;
    width: 588px;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 $indent-xl;
    min-height: 64px;
    max-height: 64px;
    background-color: var(--grey10);
    border-radius: $border-radius $border-radius 0 0;
    border-bottom: 1px solid var(--grey20);

    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: $lg-text;
      color: var(--grey200);
    }

    &-close {
      &-button {
        height: 24px;

        &-mobile {
          display: none;
        }
      }

      &-icon {
        color: var(--grey100);
      }
    }
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    grid-gap: $indent-md;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    &__container {
      width: 100%;
      border-radius: 0;
    }

    &__header {
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 0 $indent-md;
      min-height: 52px;
      max-height: 52px;
      border-radius: 0;
      background-color: var(--common2);

      &-title {
        font-size: $md-text;
        margin-right: 60px;
      }

      &-close {
        &-icon {
          color: var(--grey120);
        }

        &-button {
          display: none;

          &-mobile {
            display: block;
            height: 16px;
            margin-right: $indent-lg;
          }
        }
      }
    }

    &__mobile-add-button {
      position: fixed;
      right: 26px;
      bottom: 38px;

      &_hidden {
        display: none;
      }
    }
  }
}
