@use "./src/shared/assets/scss/variables/index.scss" as *;
.notifications-block {
  box-shadow: var(--box-shadow-lvl-2);
  border-radius: 0 $border-radius $border-radius 0;
  background-color: var(--common2);
  height: 100vh;
  display: flex;
  flex-direction: column;

  $this: &;

  &__header {
    padding: 40px $indent-xxl;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__close-button {
    color: var(--grey100);
    margin-bottom: 10px;
    line-height: 0;
    width: fit-content;

    & svg {
      height: 20px;
      width: 20px;
    }
  }

  &__sub-header {
    display: flex;
    align-items: center;
    grid-gap: $indent-sm;
    width: 100%;

    & svg {
      flex-shrink: 0;
    }

    &-title {
      color: var(--grey200);
    }
  }

  &__tooltip {
    max-width: 500px;
    word-wrap: break-word;
  }

  &__user-wrapper {
    background-color: var(--grey10);
    padding: 40px $indent-xl $indent-xxl $indent-xl;
    position: relative;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 100%;
  }

  &__empty-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 312px);
    color: var(--grey20);

    #{$this}__button-wrapper {
      width: 100%;
      margin-top: 20px;
    }

    & svg {
      flex-shrink: 0;
    }

    &-text {
      white-space: nowrap;
      color: var(--grey120);
      text-align: center;
      margin-top: 50px;
    }
  }

  &__initial-user {
    position: absolute;
    top: -$indent-lg;
  }

  &__user {
    &-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: $indent-xs;
    }

    &-name {
      white-space: nowrap;
      color: var(--grey200);
    }

    &-organization {
      background: var(--cyan20);
      color: var(--cyan120);
      padding: $indent-xxs $indent-sm;
      margin-top: $indent-xs;
      margin-bottom: $indent-xs;
      border-radius: $border-radius-xs;

      &_client {
        background-color: var(--purple20);
        color: var(--purple120);
      }
    }

    &-buttons {
      display: flex;
    }

    &-button {
      min-width: 40px;
      height: 40px;
      color: var(--blue120);
      margin-right: $indent-xs;
      background-color: var(--common2);
      border-radius: $border-radius-sm;

      &-icon {
        width: $indent-md;
        color: var(--blue120);
      }

      &:not(:last-child) {
        margin-right: $indent-xs;
      }
    }

    &-info {
      &-wrapper {
        display: flex;
        flex-direction: column;
      }

      &-block {
        padding: $indent-md $indent-sm;
        background-color: var(--common2);

        &:not(:last-child) {
          border-bottom: 1px solid var(--grey20);
        }
      }
    }
  }

  &__icon-wrapper {
    background-color: var(--common2);
  }

  &__block-info {
    font-size: $sm-text;

    &-icon {
      min-width: $indent-xxxl;
      height: $indent-xxxl;
      color: var(--grey100);
    }

    & svg {
      flex-shrink: 0;
    }
  }

  &__notifications {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 312px);
  }

  &__notification {
    padding-bottom: 21px;
    border-bottom: 1px solid var(--grey20);

    &:hover {
      cursor: pointer;
    }

    &-title {
      max-width: 272px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-wrapper {
      padding: $indent-sm $indent-xl 0 $indent-xl;
      transition: background-color $transition;

      &:hover {
        background-color: var(--blue10);
      }
    }
  }

  &__button {
    width: 100%;

    &-wrapper {
      padding: $indent-sm $indent-xl $indent-xxxl;
    }
  }

  @media screen and (max-width: 378px) {
    &__user-name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

[data-theme='light-contrast'] {
  .notifications-block {
    &__user-organization {
      color: var(--cyan180);

      &_client {
        color: var(--purple180);
      }
    }
  }
}
