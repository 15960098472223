@use "./src/shared/assets/scss/variables/index.scss" as *;
.info-block {
  position: relative;
  display: flex;
  align-items: center;

  &__custom-button {
    margin-right: $indent-xxxl;
  }

  &__icon-wrapper {
    background-color: var(--grey10);
    border-radius: $border-radius-sm;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__info-wrapper {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
  }

  &__title-block {
    display: flex;
    align-items: center;
  }

  &__notification-icon {
    width: 12px;
    height: 12px;
    color: var(--grey90);
    margin-left: $indent-xxs;
  }

  &__title {
    color: var(--grey100);
    white-space: nowrap;
  }

  &__info {
    color: var(--grey200);
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 768px) {
    &__icon-wrapper {
      min-width: 32px;
      min-height: 32px;
      height: auto;
    }
  }
}
